define("flytrendy/router", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('sitemap-txt', {
      path: 'sitemap.txt'
    }); // Required by ember-cli-prerender

    this.route('sitemap-xml', {
      path: 'sitemap.xml'
    }); // Optional

    this.route('splash', {
      path: '/'
    });
    this.route('login');
    this.route('influencer');
    this.route('brand');
    this.route('aboutus');
    this.route('faq');
    this.route('newcampaign');
    this.route('draftcampaign', {
      path: '/draftcampaign'
    });
    this.route('editcampaign', {
      path: '/editcampaign'
    });
    this.route('mycampaign', {
      path: '/mycampaign'
    });
    this.route('mycampaignoverview', {
      path: '/mycampaignoverview'
    });
    this.route('inbox');
    this.route('privacypolicy');
    this.route('termsandconditions', {
      path: '/termsandconditions'
    });
    this.route('contact');
    this.route('pricing');
    this.route('resetpassword');
    this.route('myprofile');
    this.route('changepassword', {
      path: '/changepassword'
    });
    this.route('activate');
    this.route('add-new-users'); //STG

    this.route('developer');
    this.route('404');
    this.route('clients'); //ABU 3RD PARTY FEEBACK

    this.route('services/feedback', {
      path: '/services/feedback/:id/:email'
    });
    this.route('instagram-upgrade');
  });
  var _default = Router;
  _exports.default = _default;
});