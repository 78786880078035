define("flytrendy/components/stats-count", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    users: 0,
    instagram: 0,
    facebook: 0,
    twitter: 0,

    willRender() {
      this._super(...arguments);

      var that = this;
      var endpoint = ENV.APP.API_HOST; // GET influencers total

      _jquery.default.ajax({
        type: "GET",
        data: {},
        url: encodeURI(endpoint + "/staff/influencerstotal"),
        dataType: "text",
        beforeSend: function (xhr) {
          xhr.setRequestHeader("Content-Type", "application/json");
        },
        success: function (res) {
          res = JSON.parse(res);
          that.set('users', res['getInfluencersTotalStaff']['total']);
        },
        error: function (jqXHR, exception) {
          that.set('users', 12234);
        }
      }); // GET influencers total


      _jquery.default.ajax({
        type: "GET",
        data: {},
        url: encodeURI(endpoint + "/influencertotalfollowers"),
        dataType: "text",
        beforeSend: function (xhr) {
          xhr.setRequestHeader("Content-Type", "application/json");
        },
        success: function (res) {
          res = JSON.parse(res);
          that.set('instagram', res['influencerTotalFollowers']['instagram']);
          that.set('twitter', res['influencerTotalFollowers']['twitter']);
          that.set('facebook', res['influencerTotalFollowers']['facebook']);
        },
        error: function (jqXHR, exception) {
          that.set('instagram', 36331620);
          that.set('twitter', 697518);
          that.set('facebook', 3892325);
        }
      });
    },

    didInsertElement() {
      (0, _jquery.default)('.counting').each(function () {
        var $this = (0, _jquery.default)(this),
            countTo = $this.attr('data-count');
        (0, _jquery.default)({
          countNum: $this.text()
        }).animate({
          countNum: countTo
        }, {
          duration: 5000,
          easing: 'linear',
          step: function () {
            $this.text(commaSeparateNumber(Math.floor(this.countNum)));
          },
          complete: function () {
            $this.text(commaSeparateNumber(this.countNum));
          }
        });
      });

      function commaSeparateNumber(val) {
        while (/(\d+)(\d{3})/.test(val.toString())) {
          val = val.toString().replace(/(\d+)(\d{3})/, '$1' + '.' + '$2');
        }

        return val;
      }
    }

  });

  _exports.default = _default;
});