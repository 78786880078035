define("flytrendy/models/influencer", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _model.default.extend({
    user: _emberData.default.attr(),
    email: _emberData.default.attr(),
    phone: _emberData.default.attr(),
    gender: _emberData.default.attr(),
    home_country: _emberData.default.attr(),
    dob: _emberData.default.attr(),
    shipping_address: _emberData.default.attr(),
    interests: _emberData.default.attr(),
    favourites: _emberData.default.attr(),
    instagram: _emberData.default.attr(),
    twitter: _emberData.default.attr(),
    facebook: _emberData.default.attr(),
    agent_name: _emberData.default.attr(),
    agent_phone: _emberData.default.attr(),
    agent_email: _emberData.default.attr(),
    stripe_token: _emberData.default.attr(),
    instagram_engagement: _emberData.default.attr(),
    facebook_engagement: _emberData.default.attr(),
    twitter_engagement: _emberData.default.attr(),
    created_datetime: _emberData.default.attr(),
    updated_date: _emberData.default.attr()
  });

  _exports.default = _default;
});