define("flytrendy/helpers/equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (options) {
    if (options[0] == options[1]) {
      return true;
    }

    return false;
  });

  _exports.default = _default;
});