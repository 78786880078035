define("flytrendy/routes/myprofile", ["exports", "flytrendy/models/objects/user", "flytrendy/models/objects/brand", "flytrendy/helpers/t"], function (_exports, _user, _brand, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: _t.default.compute('title_my_profile'),
    session: Ember.inject.service('session'),
    metaTags: [{
      tagName: _t.default.compute('seo_description'),
      tagContent: _t.default.compute('title_my_profile')
    }, {
      tagName: _t.default.compute('seo_keywords'),
      tagContent: _t.default.compute('seo_content')
    }],
    init: function () {
      this._super(...arguments);
    },

    beforeModel() {
      if (!this.get('session.isAuthenticated')) {
        this.transitionTo('splash');
      }
    },

    model(params) {
      return Ember.RSVP.hash({
        user: this.store.query('user', {}),
        brand: this.store.query('brand', {})
      });
    },

    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      var user = {},
          brand = {};

      if (model.user && model.user.content && model.user.content[0]) {
        user = model.user.get('firstObject');
      }

      if (model.brand && model.brand.content && model.brand.content[0]) {
        brand = model.brand.get('firstObject');
      }

      controller.set('user', user);
      controller.set('brand', brand);
      controller.set('invoiceNotification', user.invoice);
      controller.set('agencyAccount', brand.is_agency);
      controller.send('onLoading');
    }
  });

  _exports.default = _default;
});