define("flytrendy/models/objects/brand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    user: '',
    username: '',
    company: '',
    phone: '',
    mobile: '',
    street_address: '',
    location_city: '',
    location_state: '',
    location_postcode: '',
    location_country: '',
    origin: '',
    stripe_token: '',
    created_datetime: '',
    funds: '',
    brand_currency: '',
    iva: '',
    allow_iva: true,
    init: function () {
      if (this.get('origin') && this.get('origin').toLowerCase() == 'switzerland') {
        this.set('allow_iva', false);
      }
    },
    getSomeThing: Ember.computed(function () {})
  });

  _exports.default = _default;
});