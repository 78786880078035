define("flytrendy/routes/splash", ["exports", "jquery", "ember-simple-auth/mixins/application-route-mixin", "flytrendy/helpers/t", "flytrendy/config/environment"], function (_exports, _jquery, _applicationRouteMixin, _t, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    title: _t.default.compute('title_splash'),
    session: Ember.inject.service('session'),
    metaTags: [{
      tagName: _t.default.compute('seo_description'),
      tagContent: _t.default.compute('title_splash')
    }, {
      tagName: _t.default.compute('seo_keywords'),
      tagContent: _t.default.compute('seo_content')
    }],

    /*title: function(params) {
          return LOCALE.compute("title_splash");
      },*/
    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      var that = this;
      var endpoint = _environment.default.APP.API_HOST; // GET influencers total

      _jquery.default.ajax({
        type: 'GET',
        data: {},
        url: encodeURI(endpoint + '/staff/influencerstotal'),
        dataType: 'text',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Content-Type', 'application/json');
        },
        success: function (res) {
          res = JSON.parse(res);
          controller.set('users', res['getInfluencersTotalStaff']['total'] + 4000);
        },
        error: function (jqXHR, exception) {
          controller.set('users', 12801);
        }
      }); // GET influencers total


      _jquery.default.ajax({
        type: 'GET',
        data: {},
        url: encodeURI(endpoint + '/influencertotalfollowers'),
        dataType: 'text',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Content-Type', 'application/json');
        },
        success: function (res) {
          res = JSON.parse(res);
          controller.set('instagram_followers', res.influencerTotalFollowers.instagram + 4000);
          controller.set('twitter_followers', res.influencerTotalFollowers.twitter + 4000);
          controller.set('facebook_followers', res.influencerTotalFollowers.facebook + 4000);

          if (res) {
            controller.send('showStats');
          }
        },
        error: function (jqXHR, exception) {
          controller.set('instagram_followers', 36331620);
          controller.set('twitter_followers', 697518);
          controller.set('facebook_followers', 3892325);
        }
      });
    }
  });

  _exports.default = _default;
});