define("flytrendy/components/ivy-tabs-tab", ["exports", "ivy-tabs/components/ivy-tabs-tab"], function (_exports, _ivyTabsTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _ivyTabsTab.default;
    }
  });
});