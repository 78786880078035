define("flytrendy/controllers/login", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    init: function () {
      this._super(...arguments);

      if (this.get('session.isAuthenticated')) {
        this.transitionToRoute('mycampaign');
      }
    },
    abbreviate_number: function (num, fixed) {
      if (num === null) {
        return 0;
      }

      num = parseFloat(num).toFixed(2);
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    actions: {
      onLoginSuccess() {
        let {
          identification,
          password
        } = this.getProperties('identification', 'password');
        var user_token = this.get('session.data.authenticated.access_token'),
            that = this;

        if (user_token) {
          this.get('session').set('data.username', identification);
          this.get('session').set('data.password', password);
          that.store.query('user', {}).then(function (data) {
            if (data.content.length > 0) {
              var _user = data.get('firstObject');

              that.get('session').set('data.user_id', _user.id);
              that.get('session').set('data.is_additional_brand_user', _user.is_additional_brand_user);

              if (_user.is_additional_brand_user && _user.is_additional_brand_user > 0) {
                that.get('session').set('data.is_sub_user', true);
              } else {
                that.get('session').set('data.is_sub_user', false);
              }

              if (_user.is_first_login && _user.is_additional_brand_user) {
                that.get('applicationController').send('showUpdatePasswordForm');
              }
            }
          });
          that.store.query('brand', {}).then(function (data) {
            if (data.content.length > 0) {
              var obj = data.get('firstObject');
              that.get('session').set('data.brand_id', obj.id);
              that.get('session').set('data.brand_user', obj.user);
              that.get('session').set('data.brand_username', obj.username);
              that.get('session').set('data.brand_company', obj.company);
              that.get('session').set('data.brand_origin', obj.origin);
              that.get('session').set('data.brand_symbol', obj.brand_currency.symbol);
              that.get('session').set('data.brand_code', obj.brand_currency.code);
              that.get('session').set('data.brand_name', obj.brand_currency.name);
              that.get('session').set('data.funds', that.abbreviate_number(obj.funds), 1);
              that.get('session').set('data.brand_funds', obj.funds);
            }
          });
          var redirect_id = this.get('applicationController.redirect_id');
          var redirect_post = this.get('applicationController.redirect_post');
          var redirect = this.get('applicationController.redirect');

          if (redirect) {
            if (redirect == 'mycampaignoverview') {
              if (redirect_id) {
                var params = {
                  queryParams: {}
                };
                params.queryParams['id'] = redirect_id;

                if (redirect_post) {
                  params.queryParams['post'] = redirect_post;
                }

                this.transitionToRoute('mycampaignoverview', params);
              } else {
                this.transitionToRoute('mycampaign');
              }
            }
          } else {
            this.transitionToRoute('mycampaign');
          }

          that.get('applicationController').send('hideLoader');
        } else {
          that.get('applicationController').send('hideLoader');
          alert('Enter a valid username/password.');
        }
      },

      authenticate() {
        let {
          identification,
          password
        } = this.getProperties('identification', 'password');
        var that = this;

        if (identification && password) {
          that.get('applicationController').send('showLoader');
          this.get('session').authenticate('authenticator:oauth2', identification, password).then(result => {
            that.send('onLoginSuccess');
          }, err => {
            that.send('onLoginSuccess');
          });
        } else {
          that.get('applicationController').send('hideLoader');
          alert('username/password not provided');
        }
      }

    }
  });

  _exports.default = _default;
});