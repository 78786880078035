define("flytrendy/routes/editcampaign", ["exports", "flytrendy/models/objects/newcampaign", "flytrendy/helpers/t"], function (_exports, _newcampaign, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: _t.default.compute('title_edit_campaign'),
    session: Ember.inject.service('session'),
    metaTags: [{
      tagName: _t.default.compute('seo_description'),
      tagContent: _t.default.compute('title_edit_campaign')
    }, {
      tagName: _t.default.compute('seo_keywords'),
      tagContent: _t.default.compute('seo_content')
    }],

    beforeModel() {
      if (!this.get('session.isAuthenticated')) {
        var controller = this.controllerFor('newcampaign');
        controller.set('autoRedirect', true);
        this.transitionTo('splash');
      }
    },

    actions: {
      willTransition(transition) {
        var controller = this.controllerFor('editcampaign');

        if (!controller.get('autoRedirect')) {
          var confirm_msg = _t.default.compute('popup_leave_page_confirm');

          if (!confirm(confirm_msg)) {
            transition.abort();
          }
        }
      }

    },

    model(params) {
      return Ember.RSVP.hash({
        myCampaign: this.store.findRecord('campaign', params.id)
      });
    },

    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      var obj = null,
          campObj = null;
      obj = model.myCampaign;
      campObj = _newcampaign.default.create(obj);
      campObj.setData(obj); //campObj.setHeroImage(this);

      campObj.setMoodImages(this, function () {});
      controller.set('campaign', campObj);
      console.log(campObj);
      controller.send('onLoading');
    }
  });

  _exports.default = _default;
});