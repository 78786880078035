define("flytrendy/routes/inviteinfluencer", ["exports", "flytrendy/models/objects/campaign", "flytrendy/helpers/t"], function (_exports, _campaign, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: _t.default.compute('title_invite_influencer'),
    session: Ember.inject.service('session'),
    metaTags: [{
      tagName: _t.default.compute('seo_description'),
      tagContent: _t.default.compute('title_invite_influencer')
    }, {
      tagName: _t.default.compute('seo_keywords'),
      tagContent: _t.default.compute('seo_content')
    }],

    beforeModel() {
      if (!this.get('session.isAuthenticated')) {
        this.transitionTo('splash');
      }
    },

    model(params) {
      return Ember.RSVP.hash({
        myCampaign: this.store.query('campaign', {})
      });
    },

    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      var len = model.myCampaign.content.length;
      var obj = null,
          campaignList = [],
          campObj = null;

      for (var i = 0; i < len; i++) {
        obj = model.myCampaign.objectAt(i);
        campObj = _campaign.default.create(obj.data);
        campObj.set('data_id', obj.id);
        campObj.setHeroImage(this);
        campaignList.push(campObj);
      }

      controller.set('mycampaigns', campaignList);
      controller.send('onLoading');
    }
  });

  _exports.default = _default;
});