define("flytrendy/routes/login", ["exports", "jquery", "ember-simple-auth/mixins/application-route-mixin", "flytrendy/helpers/t"], function (_exports, _jquery, _applicationRouteMixin, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    title: _t.default.compute('title_login'),
    session: Ember.inject.service('session'),
    activate: function () {
      (0, _jquery.default)('body').toggleClass('hide-navbar');
    },
    deactivate: function () {
      (0, _jquery.default)('body').toggleClass('hide-navbar');
    },
    metaTags: [{
      tagName: _t.default.compute('seo_description'),
      tagContent: _t.default.compute('title_login')
    }, {
      tagName: _t.default.compute('seo_keywords'),
      tagContent: _t.default.compute('seo_content')
    }],
    setupController: function (controller, model) {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});