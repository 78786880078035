define("flytrendy/controllers/newcampaign", ["exports", "jquery", "flytrendy/models/objects/newcampaign", "flytrendy/models/objects/countries", "flytrendy/config/environment", "flytrendy/utils/prevent-default", "flytrendy/helpers/t"], function (_exports, _jquery, _newcampaign, _countries, _environment, _preventDefault, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    campaignBuilder: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    active_tab: 1,
    brief_tabl: false,
    campaign_tabl: true,
    influencers_tabl: false,
    review_tabl: false,
    submit_tabl: false,
    title: '',
    src: '',
    campaign: null,
    countries: null,
    observationDialog: null,
    loginDialog: null,
    mood_gallery: [],
    compressor: null,
    autoRedirect: false,
    instaShow: false,
    // campaign wallet
    campaign_budget: 0,
    campaign_wallet_type_id: null,
    campaign_spent: 0,
    campaignWalletType: null,
    wallet_types: [{
      name: 'START',
      value: 'start'
    }, {
      name: 'SMART',
      value: 'smart'
    }, {
      name: 'POWER',
      value: 'power'
    }, {
      name: 'PRODUCT START',
      value: 'product_start'
    }, {
      name: 'PRODUCT BOOST',
      value: 'product_boost'
    }, {
      name: 'BOOST',
      value: 'boost'
    }, {
      name: 'ONSITE URBAN',
      value: 'onsite_urban'
    }, {
      name: 'ONSITE LOCAL',
      value: 'onsite_local'
    }],
    agencyAccount: false,
    // system defaults
    dos: _environment.default.PLATFORM.dos,
    donts: _environment.default.PLATFORM.donts,
    platform: _environment.default.APP.platform_name,
    brand_name_new_campaign: _environment.default.PLATFORM.brand_name_new_campaign,
    tt_1: _t.default.compute('nc_tt_1'),
    tt_2: _t.default.compute('nc_tt_2'),
    tt_3: _t.default.compute('nc_tt_3'),
    tt_4: _t.default.compute('nc_tt_4'),
    tt_5: _t.default.compute('nc_tt_5'),
    tt_6: _t.default.compute('nc_tt_6'),
    tt_7: _t.default.compute('nc_tt_7'),
    tt_8: _t.default.compute('nc_tt_8'),
    tt_9: _t.default.compute('nc_tt_9'),
    tt_10: _t.default.compute('nc_tt_10'),
    tt_11: _t.default.compute('nc_tt_11'),
    ad_text: _environment.default.PLATFORM.ad_text,
    isCroppedHeroImageActived: false,
    isCroppedLogoImageActived: false,
    is_loggingin: false,
    init: function () {
      this._super(...arguments);

      this.active_tab = 1;
      this.set('campaign', _newcampaign.default.create({}));
      this.set('countries', _countries.default.create({}));
      this.set('autoRedirect', false);
    },
    deleteLocalData: function () {
      var campaignlist = JSON.parse(localStorage.getItem('campaignlist'));
      var company_name = this.get('campaign.udid');
      delete campaignlist[company_name];
      localStorage.setItem('campaignlist', JSON.stringify(campaignlist));
    },
    generateUDID: function () {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      }

      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    },
    abbreviate_number: function (num, fixed) {
      if (num === null) {
        return null;
      }

      if (num === 0) {
        return '0';
      }

      fixed = !fixed || fixed < 0 ? 0 : fixed;
      var b = num.toPrecision(2).split('e'),
          k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3),
          c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed),
          d = c < 0 ? c : Math.abs(c),
          e = d + ['', 'K', 'M', 'B', 'T'][k];
      return e;
    },
    warnUnsavedChanges: event => {
      (0, _preventDefault.default)(event);
    },
    warnUnsavedForm: event => {
      //The function with unique name needs to be called from a template form
      (0, _preventDefault.default)(event);
    },
    actions: {
      onLoading() {
        this.active_tab = 1;
        this.send('setActiveTab');
        this.compressor = new ImageCompressor();
        this.set('campaign', _newcampaign.default.create({}));
        this.set('campaign.udid', this.generateUDID());
        this.set('autoRedirect', false);
        this.set('isCroppedHeroImageActived', false);
        this.set('isCroppedLogoImageActived', false);
        var that = this;
        setTimeout(function () {
          if (that.get('session.isAuthenticated') && that.brand_name_new_campaign) {
            that.set('campaign.brand_name', that.get('session.data.brand_company'));
          }
        }, 500);
      },

      onGenderChange(gender) {
        if (gender == 1) {
          (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont .male').toggleClass('selected');
        }

        if (gender == 2) {
          (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont .female').toggleClass('selected');
        }

        if (gender == 3) {
          (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont button').removeClass('selected');
          (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont .male').addClass('selected');
          (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont .female').addClass('selected');
        }

        if (gender == 4) {
          (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont button').removeClass('selected');
        }

        var m = (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont .male').hasClass('selected');
        var f = (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont .female').hasClass('selected');

        if (m && f) {
          this.set('campaign.gender', 'all');
        } else if (m && !f) {
          this.set('campaign.gender', 'male');
        } else if (!m && f) {
          this.set('campaign.gender', 'female');
        } else {
          this.set('campaign.gender', '');
        }
      },

      onPublishChange(pub) {
        (0, _jquery.default)('.briefcompaign .submit-cont button').removeClass('selected');

        if (pub == 1) {
          (0, _jquery.default)('.briefcompaign .submit-cont .now').addClass('selected');
          this.set('campaign.publish_status', 'now');
          (0, _jquery.default)('.briefcompaign .submit-cont .later-date').hide();
        } else {
          (0, _jquery.default)('.briefcompaign .submit-cont .later').addClass('selected');
          this.set('campaign.publish_status', 'later');
          (0, _jquery.default)('.briefcompaign .submit-cont .later-date').show();
        }
      },

      onAccountChange(pub) {
        if (pub == 1) {
          (0, _jquery.default)('.briefcompaign .briefcompaign-cont .content .data-cont .cc-cont .is_agency').addClass('selected');
          (0, _jquery.default)('.briefcompaign .briefcompaign-cont .content .data-cont .cc-cont .not_agency').removeClass('selected');
          this.set('agencyAccount', false);
        } else {
          (0, _jquery.default)('.briefcompaign .briefcompaign-cont .content .data-cont .cc-cont .not_agency').addClass('selected');
          (0, _jquery.default)('.briefcompaign .briefcompaign-cont .content .data-cont .cc-cont .is_agency').removeClass('selected');
          this.set('agencyAccount', true);
        }
      },

      next(event) {
        console.log('event', event);
        this.send('saveTempData', this.active_tab, true, event);
      },

      previous(event) {
        this.send('saveTempData', this.active_tab, false, event);
      },

      saveTempData(tab, validate, event) {
        // tab is current step
        // this function is called prior to next/prev tab to save data.
        (0, _jquery.default)('html,body').scrollTop(0);
        event.stopPropagation();
        event.preventDefault();

        if (tab == 1) {
          if (validate) {
            var ok = true;
            (0, _jquery.default)('#brand_comp_name').parent().removeClass('info-required');

            if (!(0, _jquery.default)('#brand_comp_name').val()) {
              (0, _jquery.default)('#brand_comp_name').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#pro_comp_name').parent().removeClass('info-required');

            if (!(0, _jquery.default)('#pro_comp_name').val()) {
              (0, _jquery.default)('#pro_comp_name').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#pro_desc').parent().removeClass('info-required');

            if (!(0, _jquery.default)('#pro_desc').val()) {
              (0, _jquery.default)('#pro_desc').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#campaign_link').parent().removeClass('info-required');

            if (!(0, _jquery.default)('#campaign_link').val()) {
              (0, _jquery.default)('#campaign_link').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#where_purchase').parent().removeClass('info-required');

            if (!(0, _jquery.default)('#where_purchase').val()) {
              (0, _jquery.default)('#where_purchase').parent().addClass('info-required');
              ok = false;
            }

            const budget = (0, _jquery.default)('#campaign_budget');

            if (budget.length) {
              (0, _jquery.default)('#campaign_budget').parent().removeClass('info-required');

              if (!(0, _jquery.default)('#campaign_budget').val()) {
                (0, _jquery.default)('#campaign_budget').parent().addClass('info-required');
                ok = false;
              }
            }

            (0, _jquery.default)('.hero-image-cont').removeClass('info-required');

            if (!this.get('campaign.hero_image') || this.get('isCroppedHeroImageActived')) {
              (0, _jquery.default)('.hero-image-cont').addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('.logo-image-cont').removeClass('info-required');

            if (!this.get('campaign.logo') || this.get('isCroppedLogoImageActived')) {
              (0, _jquery.default)('.logo-image-cont').addClass('info-required');
              ok = false;
            }

            if (!ok) return;
          }

          const CAMPAIGN_WALLET_TYPE = (0, _jquery.default)('#campaign_budget').val() || '';
          this.set('campaign.brand_name', (0, _jquery.default)('#brand_comp_name').val());
          this.set('campaign.campaign_name', (0, _jquery.default)('#pro_comp_name').val());
          this.set('campaign.product_description', (0, _jquery.default)('#pro_desc').val());
          this.set('campaign.link', (0, _jquery.default)('#campaign_link').val());
          this.set('campaign.where_to_purchase', (0, _jquery.default)('#where_purchase').val());
          this.set('campaign_wallet_type_id', parseInt(CAMPAIGN_WALLET_TYPE));
          console.log('campaign_wallet_type_id', this.get('campaign_wallet_type_id'));
          dataLayer.push({
            event: 'asyncAction',
            gaHitType: 'event',
            event_category: 'new_campaign',
            event_action: 'campagna_creata_step_1_campagna'
          });
        } else if (tab == 2) {
          var instagram_tags = '',
              twitter_tags = '',
              facebook_tags = '';
          const dos = [],
                donts = [];

          if (validate) {
            var ok = true;
            (0, _jquery.default)('#content_love').parent().removeClass('info-required');

            if (!(0, _jquery.default)('#content_love').val()) {
              (0, _jquery.default)('#content_love').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#call_action').parent().removeClass('info-required');

            if (!(0, _jquery.default)('#call_action').val()) {
              (0, _jquery.default)('#call_action').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#pre_tags_instagram').parent().removeClass('info-required');

            if (this.get('campaign.instagram') && !(0, _jquery.default)('#pre_tags_instagram').val()) {
              (0, _jquery.default)('#pre_tags_instagram').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#pre_tags_twitter').parent().removeClass('info-required');

            if (this.get('campaign.twitter') && !(0, _jquery.default)('#pre_tags_twitter').val()) {
              (0, _jquery.default)('#pre_tags_twitter').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#pre_tags_facebook').parent().removeClass('info-required');

            if (this.get('campaign.facebook') && !(0, _jquery.default)('#pre_tags_facebook').val()) {
              (0, _jquery.default)('#pre_tags_facebook').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('.platform-cont').removeClass('info-required');

            if (!this.get('campaign.facebook') && !this.get('campaign.instagram') && !this.get('campaign.facebook') && !this.get('campaign.instagramstory') && !this.get('campaign.twitter')) {
              (0, _jquery.default)('.platform-cont').addClass('info-required');
              ok = false;
            }

            if (!ok) return;
          }

          this.set('campaign.content_we_love', (0, _jquery.default)('#content_love').val());
          this.set('campaign.call_to_action', (0, _jquery.default)('#call_action').val());
          const insta_tags = (0, _jquery.default)('#pre_tags_instagram').val() || '';

          if (this.get('campaign.instagram')) {
            instagram_tags = insta_tags;
            this.set('campaign.instagram_post', true);
          }

          if (this.get('campaign.instagramstory')) {
            instagram_tags = insta_tags;
            this.set('campaign.instagram_story', true);
          }

          if (this.get('campaign.twitter')) {
            twitter_tags = (0, _jquery.default)('#pre_tags_twitter').val();
          }

          if (this.get('campaign.facebook')) {
            facebook_tags = (0, _jquery.default)('#pre_tags_facebook').val();
          }

          this.set('campaign.instagram_tags', instagram_tags);
          this.set('campaign.twitter_tags', twitter_tags);
          this.set('campaign.facebook_tags', facebook_tags);

          if (instagram_tags) {
            this.set('campaign.instagram_tags_list', instagram_tags.split(' '));
          }

          this.set('campaign.twitter_tags_list', twitter_tags.split(' '));
          this.set('campaign.facebook_tags_list', facebook_tags.split(' '));
          (0, _jquery.default)('#dos_list li').each(function () {
            dos.push((0, _jquery.default)((0, _jquery.default)(this)[0].firstChild).text());
          });
          (0, _jquery.default)('#donts_list li').each(function () {
            donts.push((0, _jquery.default)((0, _jquery.default)(this)[0].firstChild).text());
          });
          this.set('campaign.dos', dos);
          this.set('campaign.donts', donts);
          dataLayer.push({
            event: 'asyncAction',
            gaHitType: 'event',
            event_category: 'new_campaign',
            event_action: 'campagna_creata_step_2_brief'
          });
        } else if (tab == 3) {
          var regions = [],
              list = [];
          (0, _jquery.default)('.briefcompaign .influencers-cont .demographic-cont .region-cont span').each(function (index) {
            if ((0, _jquery.default)(this).hasClass('selected')) {
              regions.push(index);
            }
          });
          this.set('campaign.target_regions', regions);
          (0, _jquery.default)('.briefcompaign .influencers-cont .demographic-cont .interest div').each(function (index) {
            if ((0, _jquery.default)(this).hasClass('selected')) {
              list.push((0, _jquery.default)(this).text());
            }
          });
          this.set('campaign.interests', list);

          if (validate) {
            var ok = true;
            (0, _jquery.default)('.region-list-cont').removeClass('info-required');

            if (this.get('campaign.target_regions').length == 0) {
              (0, _jquery.default)('.region-list-cont').addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('.interest-list-cont').removeClass('info-required');

            if (this.get('campaign.interests').length == 0) {
              (0, _jquery.default)('.interest-list-cont').addClass('info-required');
              ok = false;
            }

            var g = this.get('campaign.gender');
            (0, _jquery.default)('.gender-cont').parent().removeClass('info-required');

            if (!g || g == '') {
              (0, _jquery.default)('.gender-cont').parent().addClass('info-required');
              ok = false;
            }

            if (!ok) return;
          }

          console.log('campagna_creata_step_3_influencers');
          dataLayer.push({
            event: 'asyncAction',
            gaHitType: 'event',
            event_category: 'new_campaign',
            event_action: 'campagna_creata_step_3_influencers'
          });
        } else if (tab == 4) {
          // review tab
          console.log('campagna_creata_step_4_revisione');
          dataLayer.push({
            event: 'asyncAction',
            gaHitType: 'event',
            event_category: 'new_campaign',
            event_action: 'campagna_creata_step_4_revisione'
          });
        } else if (tab == 5) {
          //submit tab
          this.set('campaign.first_name', (0, _jquery.default)('#firstname').val());
          this.set('campaign.last_name', (0, _jquery.default)('#lastname').val());

          if (!this.get('session.isAuthenticated')) {
            this.set('campaign.campaign_name', (0, _jquery.default)('#companyname').val());
          }

          this.set('campaign.email', (0, _jquery.default)('#email').val());
          this.set('campaign.ph', (0, _jquery.default)('#phone').val());
          this.set('campaign.mob', (0, _jquery.default)('#mobile').val());
          this.set('campaign.pass', (0, _jquery.default)('#password').val());
          this.set('campaign.c_pass', (0, _jquery.default)('#passwordconfirm').val());
          this.set('campaign.billing_company', (0, _jquery.default)('#billing_company').val());
          this.set('campaign.address', (0, _jquery.default)('#streetaddress').val());
          this.set('campaign.city', (0, _jquery.default)('#city').val());
          this.set('campaign.state', (0, _jquery.default)('#state').val());
          this.set('campaign.postcode', (0, _jquery.default)('#postcode').val());
          this.set('campaign.country', (0, _jquery.default)('#country').val());
          this.set('campaign.iva', (0, _jquery.default)('#iva').val());
          this.set('campaign.pec_univoco', (0, _jquery.default)('#pec_univoco').val());
          this.set('campaign.response_time', '2 hours');
          this.set('campaign.publish_date', (0, _jquery.default)('#publishdate').val());
          this.set('campaign.until_date', (0, _jquery.default)('#publishuntil').val());
          this.set('campaign.agreement', (0, _jquery.default)('#brandchecked').prop('checked'));
          this.set('campaign.agreement_tc', (0, _jquery.default)('#brandchecked_tc').prop('checked'));
          console.log('campagna_creata_step_5_invia');
          dataLayer.push({
            event: 'asyncAction',
            gaHitType: 'event',
            event_category: 'new_campaign',
            event_action: 'campagna_creata_step_5_invia'
          });
        }

        if (validate) {
          this.active_tab++;
          this.send('setActiveTab');
        } else {
          this.active_tab--;
          this.send('setActiveTab');
        }
      },

      togglePlatform(op) {
        if (op == 1) {
          (0, _jquery.default)('.briefcompaign .platform-cont .instagram').toggleClass('instagram-selected');
          this.set('campaign.instagram', false);
          this.set('campaign.instagramstory', false);
          this.set('instaShow', false);

          if ((0, _jquery.default)('.briefcompaign .platform-cont .instagram').hasClass('instagram-selected')) {
            this.set('instaShow', true);
          }
        } else if (op == 2) {
          (0, _jquery.default)('.briefcompaign .platform-cont .twitter').toggleClass('twitter-selected');
          this.set('campaign.twitter', false);

          if ((0, _jquery.default)('.briefcompaign .platform-cont .twitter').hasClass('twitter-selected')) {
            this.set('campaign.twitter', true);
          }
        } else if (op == 3) {
          (0, _jquery.default)('.briefcompaign .platform-cont .facebook').toggleClass('facebook-selected');
          this.set('campaign.facebook', false);

          if ((0, _jquery.default)('.briefcompaign .platform-cont .facebook').hasClass('facebook-selected')) {
            this.set('campaign.facebook', true);
          }
        }

        (0, _jquery.default)('.platform-cont').removeClass('info-required');
      },

      toggleInsta(insta) {
        // alert('toggling insta type; campaign.instagram = ' + this.get("campaign.instagram") + ' AND campaign.instagramstory = ' + this.get("campaign.instagramstory"));
        if (insta == 1) {
          this.toggleProperty('campaign.instagram');
          this.toggleProperty('campaign.instagram_post');
        } else if (insta == 2) {
          this.toggleProperty('campaign.instagramstory');
          this.toggleProperty('campaign.instagram_story');
        }
      },

      onSaveCroppedHeroImage() {
        (0, _jquery.default)('.hero-image-cont .processing').show();
        var that = this;
        var cropped = (0, _jquery.default)('#hero_image_cropped').cropper('getCroppedCanvas');
        setTimeout(function () {
          that.set('campaign.hero_image', cropped.toDataURL());
          (0, _jquery.default)('#new_hero_image_cont').show();
          (0, _jquery.default)('#crop_hero_image_cont').hide();
          (0, _jquery.default)('#crop_hero_image_subcont').text('');
          fetch(cropped.toDataURL('image/png')).then(res => res.blob()).then(blob => {
            const file = new File([blob], 'heroimage.png');
            that.compressor.compress(blob, {
              quality: 0.6,
              maxWidth: 450
            }).then(results => {
              const reader = new FileReader();
              var base64DataURL;

              reader.onload = e => {
                base64DataURL = e.target.result;
                that.set('campaign.hero_image', base64DataURL);
                (0, _jquery.default)('.hero-image-cont').removeClass('info-required');
                (0, _jquery.default)('.hero-image-cont .processing').hide();
              };

              reader.readAsDataURL(results);
            });
          });
          that.set('isCroppedHeroImageActived', false);
        }, 1000);
      },

      onSaveCroppedLogoImage() {
        (0, _jquery.default)('.logo-image-cont .processing').show();
        var that = this;
        var cropped = (0, _jquery.default)('#logo_image_cropped').cropper('getCroppedCanvas');
        setTimeout(function () {
          (0, _jquery.default)('.logo-image-cont .processing').css({
            height: '300px',
            'margin-top': '-300px'
          });
          that.set('campaign.logo', cropped.toDataURL());
          (0, _jquery.default)('#new_logo_image_cont').show();
          (0, _jquery.default)('#crop_logo_image_cont').hide();
          (0, _jquery.default)('#crop_logo_image_subcont').text('');
          fetch(cropped.toDataURL('image/png')).then(res => res.blob()).then(blob => {
            const file = new File([blob], 'logo.png');
            that.compressor.compress(blob, {
              quality: 0.6,
              maxWidth: 450
            }).then(results => {
              const reader = new FileReader();
              var base64DataURL;

              reader.onload = e => {
                base64DataURL = e.target.result;
                that.set('campaign.logo', base64DataURL);
                (0, _jquery.default)('.logo-image-cont').removeClass('info-required');
                (0, _jquery.default)('.logo-image-cont .processing').hide();
              };

              reader.readAsDataURL(results);
            });
          });
          that.set('isCroppedLogoImageActived', false);
        }, 1000);
      },

      setActiveTab() {
        this.set('brief_tabl', false);
        this.set('campaign_tabl', false);
        this.set('influencers_tabl', false);
        this.set('review_tabl', false);
        this.set('submit_tabl', false);
        (0, _jquery.default)('.briefcompaign .option-list .col-md-2').removeClass('selected');
        var that = this;
        const cred = this.get('session.data.authenticated.token_type') + ' ' + this.get('session.data.authenticated.access_token');

        _jquery.default.ajax({
          type: 'GET',
          url: _environment.default.APP.API_HOST + `/campaign_wallet_types?platform_name=${this.platform}`,
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', cred);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (response) {
            const {
              campaignWalletType
            } = response;
            that.set('campaignWalletType', campaignWalletType);
          },
          error: function (jqXHR, exception) {}
        });

        switch (this.active_tab) {
          case 1:
            this.set('campaign_tabl', true);
            (0, _jquery.default)('.campaign-tab').addClass('selected');
            this.set('title', _t.default.compute('nc_title_1'));
            var that = this;
            setTimeout(function () {
              (0, _jquery.default)('.hero-image-cont .processing').hide();
              var heroImage = document.getElementById('hero_input_1');
              heroImage.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.hero-image-cont .processing').show();
                that.compressor.compress(file, {
                  quality: 0.8
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    that.set('campaign.hero_image', base64DataURL);
                    (0, _jquery.default)('#new_hero_image_cont').hide();
                    (0, _jquery.default)('#crop_hero_image_cont').show();
                    (0, _jquery.default)('#crop_hero_image_subcont').text('');
                    (0, _jquery.default)('#crop_hero_image_subcont').append('<div><img id="hero_image_cropped" style="max-width: 100%;" src="' + base64DataURL + '"></div>');
                    that.set('isCroppedHeroImageActived', true);
                    (0, _jquery.default)('.hero-image-cont .processing').hide();
                    (0, _jquery.default)('#hero_image_cropped').cropper({
                      aspectRatio: 16 / 9,
                      zoomable: false,
                      crop: function (e) {}
                    });
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              (0, _jquery.default)('.logo-image-cont .processing').hide();
              var logo = document.getElementById('hero_input_2');
              logo.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.logo-image-cont .processing').css({
                  height: '300px',
                  'margin-top': '-300px'
                });
                (0, _jquery.default)('.logo-image-cont .processing').show();
                that.compressor.compress(file, {
                  quality: 0.8
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    that.set('campaign.logo', base64DataURL);
                    (0, _jquery.default)('#crop_logo_image_subcont').text('');
                    var img = new Image();

                    img.onload = function () {
                      (0, _jquery.default)('#new_logo_image_cont').hide();
                      (0, _jquery.default)('#crop_logo_image_cont').show();
                      var w = this.width,
                          h = this.height;
                      var size = w;

                      if (w > h) {
                        size = w;
                      } else if (h > w) {
                        size = h;
                      }

                      size = size + 'px';
                      (0, _jquery.default)('#crop_logo_image_subcont').append('<div class="logo-crop-main-cont"><img id="logo_image_cropped" style="max-width: 100%;" src="' + base64DataURL + '"></div>');
                      that.set('isCroppedLogoImageActived', true);
                      (0, _jquery.default)('.logo-image-cont .processing').css({
                        height: '564px',
                        'margin-top': '-564px'
                      });
                      (0, _jquery.default)('.logo-image-cont .processing').hide();
                      (0, _jquery.default)('#logo_image_cropped').cropper({
                        aspectRatio: 10 / 10,
                        zoomable: false,
                        crop: function (e) {}
                      });
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
            }, 100);
            break;

          case 2:
            this.set('brief_tabl', true);
            (0, _jquery.default)('.brief-tab').addClass('selected');
            this.set('title', _t.default.compute('nc_title_2'));
            var that = this;
            setTimeout(function () {
              (0, _jquery.default)('.mood-board .mood-processing').hide();
              var mood1 = document.getElementById('file_input_1');
              mood1.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.mood-board .mood-processing').show();
                that.compressor.compress(file, {
                  quality: 0.5,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)('.mood-board .mood-processing').hide();
                    that.set('campaign.file_input_1_label', base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set('campaign.file_input_1_label_w', this.width);
                      that.set('campaign.file_input_1_label_h', this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood2 = document.getElementById('file_input_2');
              mood2.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.mood-board .mood-processing').show();
                that.compressor.compress(file, {
                  quality: 0.5,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)('.mood-board .mood-processing').hide();
                    that.set('campaign.file_input_2_label', base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set('campaign.file_input_2_label_w', this.width);
                      that.set('campaign.file_input_2_label_h', this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood3 = document.getElementById('file_input_3');
              mood3.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.mood-board .mood-processing').show();
                that.compressor.compress(file, {
                  quality: 0.5,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)('.mood-board .mood-processing').hide();
                    that.set('campaign.file_input_3_label', base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set('campaign.file_input_3_label_w', this.width);
                      that.set('campaign.file_input_3_label_h', this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood4 = document.getElementById('file_input_4');
              mood4.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.mood-board .mood-processing').show();
                that.compressor.compress(file, {
                  quality: 0.5,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)('.mood-board .mood-processing').hide();
                    that.set('campaign.file_input_4_label', base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set('campaign.file_input_4_label_w', this.width);
                      that.set('campaign.file_input_4_label_h', this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood5 = document.getElementById('file_input_5');
              mood5.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.mood-board .mood-processing').show();
                that.compressor.compress(file, {
                  quality: 0.5,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)('.mood-board .mood-processing').hide();
                    that.set('campaign.file_input_5_label', base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set('campaign.file_input_5_label_w', this.width);
                      that.set('campaign.file_input_5_label_h', this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood6 = document.getElementById('file_input_6');
              mood6.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.mood-board .mood-processing').show();
                that.compressor.compress(file, {
                  quality: 0.5,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)('.mood-board .mood-processing').hide();
                    that.set('campaign.file_input_6_label', base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set('campaign.file_input_6_label_w', this.width);
                      that.set('campaign.file_input_6_label_h', this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
            }, 100);
            break;

          case 3:
            this.set('influencers_tabl', true);
            (0, _jquery.default)('.influencers-tab').addClass('selected');
            this.set('title', _t.default.compute('nc_title_3'));
            var that = this;
            setTimeout(function () {
              var ageMinMax = [16, 60];

              if (that.get('campaign.age_min')) {
                ageMinMax[0] = that.get('campaign.age_min');
              }

              if (that.get('campaign.age_max')) {
                ageMinMax[1] = that.get('campaign.age_max');
              }

              that.set('campaign.age_min', ageMinMax[0]);
              that.set('campaign.age_max', ageMinMax[1]);

              if (that.get('campaign.gender') == 'female') {
                that.send('onGenderChange', 2);
              } else if (that.get('campaign.gender') == 'male') {
                that.send('onGenderChange', 1);
              } else if (that.get('campaign.gender') == 'all') {
                that.send('onGenderChange', 3);
              } else {
                that.send('onGenderChange', 4);
              }

              var slider = document.getElementById('age-slider');
              noUiSlider.create(slider, {
                start: ageMinMax,
                connect: true,
                limit: 100,
                step: 1,
                tooltips: true,
                range: {
                  min: 0,
                  max: 100
                },
                behaviour: 'tap-drag',
                format: {
                  to: function (value) {
                    return value;
                  },
                  from: function (value) {
                    return value;
                  }
                }
              });
              slider.noUiSlider.on('change', function (val) {
                that.set('campaign.age_min', val[0]);
                that.set('campaign.age_max', val[1]);
              });
              (0, _jquery.default)('.briefcompaign .influencers-cont .demographic-cont .region-cont span').click(function (e) {
                (0, _jquery.default)(this).toggleClass('selected');
              });
              var regions = that.get('campaign.target_regions');

              if (regions && regions.length > 0) {
                for (var i = 0; i < regions.length; i++) {
                  (0, _jquery.default)((0, _jquery.default)('.briefcompaign .influencers-cont .demographic-cont .region-cont span').eq(regions[i])).addClass('selected');
                }
              }

              (0, _jquery.default)('.briefcompaign .influencers-cont .demographic-cont .interest div').click(function (e) {
                (0, _jquery.default)(this).toggleClass('selected');
              });
              var interests = that.get('campaign.interests');

              if (interests && interests.length > 0) {
                for (var i = 0; i < interests.length; i++) {
                  (0, _jquery.default)('.briefcompaign .influencers-cont .demographic-cont .interest div').filter(function () {
                    return (0, _jquery.default)(this).text() == interests[i];
                  }).addClass('selected');
                }
              }
            }, 100);
            break;

          case 4:
            this.set('review_tabl', true);
            (0, _jquery.default)('.review-tab').addClass('selected');
            this.set('title', _t.default.compute('nc_title_4'));
            var interests = this.get('campaign.interests');
            var list = '';

            if (interests && interests.length > 0) {
              for (var i = 0; i < interests.length; i++) {
                list += interests[i] + ', ';
              }
            }

            var regions = this.get('campaign.target_regions'),
                temp_string = '';

            if (regions && regions.length > 0) {
              for (var i = 0; i < regions.length; i++) {
                temp_string += this.get('countries.countries').get(regions[i]).country_name + ', ';
              }
            }

            this.set('campaign.interests_string', list);
            this.set('campaign.target_regions_string', temp_string);
            var gallery = [];

            if (this.get('campaign.file_input_1_label')) {
              gallery.push({
                src: this.get('campaign.file_input_1_label'),
                w: this.get('campaign.file_input_1_label_w'),
                h: this.get('campaign.file_input_1_label_h')
              });
            }

            if (this.get('campaign.file_input_2_label')) {
              gallery.push({
                src: this.get('campaign.file_input_2_label'),
                w: this.get('campaign.file_input_2_label_w'),
                h: this.get('campaign.file_input_2_label_h')
              });
            }

            if (this.get('campaign.file_input_3_label')) {
              gallery.push({
                src: this.get('campaign.file_input_3_label'),
                w: this.get('campaign.file_input_3_label_w'),
                h: this.get('campaign.file_input_3_label_h')
              });
            }

            if (this.get('campaign.file_input_4_label')) {
              gallery.push({
                src: this.get('campaign.file_input_4_label'),
                w: this.get('campaign.file_input_4_label_w'),
                h: this.get('campaign.file_input_4_label_h')
              });
            }

            if (this.get('campaign.file_input_5_label')) {
              gallery.push({
                src: this.get('campaign.file_input_5_label'),
                w: this.get('campaign.file_input_5_label_w'),
                h: this.get('campaign.file_input_5_label_h')
              });
            }

            if (this.get('campaign.file_input_6_label')) {
              gallery.push({
                src: this.get('campaign.file_input_6_label'),
                w: this.get('campaign.file_input_6_label_w'),
                h: this.get('campaign.file_input_6_label_h')
              });
            }

            this.set('mood_gallery', gallery);
            break;

          case 5:
            this.set('submit_tabl', true);
            (0, _jquery.default)('.submit-tab').addClass('selected');
            this.set('title', _t.default.compute('nc_title_5'));
            var that = this;
            setTimeout(function () {
              if (that.get('campaign.publish_status') == 'later') {
                that.send('onPublishChange', 2);
              } else {
                that.send('onPublishChange', 1);
              }

              (0, _jquery.default)('#brandchecked').change(function (e) {
                that.set('campaign.agreement', (0, _jquery.default)(e.target).is(':checked'));
              });
              (0, _jquery.default)('#brandchecked_tc').change(function (e) {
                that.set('campaign.agreement_tc', (0, _jquery.default)(e.target).is(':checked'));
              });
              (0, _jquery.default)('#publishdate').datetimepicker({
                format: 'd/m/y',
                timepicker: false,
                scrollInput: false,
                scrollMonth: false,
                minDate: '0'
              });
              (0, _jquery.default)('#publishdate').change(function () {
                var min_d = (0, _jquery.default)('#publishdate').val();
                min_d = moment(min_d, 'DD/MM/YY').format('YYYY/MM/DD');
                (0, _jquery.default)('#publishuntil').datetimepicker({
                  format: 'd/m/y',
                  timepicker: false,
                  scrollInput: false,
                  scrollMonth: false,
                  minDate: min_d
                });
              });
              (0, _jquery.default)('#publishuntil').datetimepicker({
                format: 'd/m/y',
                timepicker: false,
                scrollInput: false,
                scrollMonth: false,
                minDate: '0'
              });
              (0, _jquery.default)('#country').val(that.get('campaign.country'));
            }, 100);
            break;
        }
      },

      onCountryChange(e) {// var c = jQuery("#country").val().toLowerCase();
        // if(c == "switzerland"){
        //     jQuery("#iva").prop('disabled', true);
        //     jQuery("#iva").addClass("disable-iva");
        // }else{
        //     jQuery("#iva").prop('disabled', false);
        //     jQuery("#iva").removeClass("disable-iva");
        // }
      },

      submit(op) {
        this.get('session').set('data.paynow', false);
        var that = this,
            ok = true;

        if (this.get('session.isAuthenticated')) {
          (0, _jquery.default)('#publishdate').parent().removeClass('info-required');

          if (this.get('campaign.publish') == 'later') {
            if (!(0, _jquery.default)('#publishdate').val()) {
              (0, _jquery.default)('#publishdate').parent().addClass('info-required');
              ok = false;
            }
          }

          (0, _jquery.default)('#publishuntil').parent().removeClass('info-required');

          if (!(0, _jquery.default)('#publishuntil').val()) {
            (0, _jquery.default)('#publishuntil').parent().addClass('info-required');
            ok = false;
          }

          if (!ok) {
            return;
          }

          this.get('applicationController').send('showLoader');
          this.send('createNewCampaign', that.get('session.data.username'), that.get('session.data.brand_company'), function (campaign_id) {
            if (campaign_id) {
              that.get('session').set('data.paynow', true);
              that.set('autoRedirect', true);
              that.get('applicationController').send('submitAnimationDone', function () {
                setTimeout(function () {
                  that.get('applicationController').send('hideLoader');
                  that.transitionToRoute('mycampaign'); // console.log("*** GTM: campagna_creata");

                  dataLayer.push({
                    event: 'asyncAction',
                    gaHitType: 'event',
                    event_category: 'new_campaign',
                    event_action: 'campagna_creata'
                  });
                }, 1000);
              });
              that.deleteLocalData();
            } else {
              that.get('applicationController').send('hideLoader');
            }
          });
        } else {
          (0, _jquery.default)('#email').parent().removeClass('info-required'); // VALIDATE EMAIL ADDRESS, needed for API!

          function validateEmail(email) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
          }

          var my_email = (0, _jquery.default)('#email').val();

          if (validateEmail(my_email)) {
            if (!my_email) {
              (0, _jquery.default)('#email').parent().addClass('info-required');
              ok = false;
            }
          } else {
            (0, _jquery.default)('#email').parent().addClass('info-required');
            ok = false;
          } // end validate email


          (0, _jquery.default)('#password').parent().removeClass('info-required');

          if (!(0, _jquery.default)('#password').val()) {
            (0, _jquery.default)('#password').parent().addClass('info-required');
            ok = false;
          }

          (0, _jquery.default)('#passwordconfirm').parent().removeClass('info-required');

          if (!(0, _jquery.default)('#passwordconfirm').val()) {
            (0, _jquery.default)('#passwordconfirm').parent().addClass('info-required');
            ok = false;
          }

          (0, _jquery.default)('#firstname').parent().removeClass('info-required');

          if (!(0, _jquery.default)('#firstname').val()) {
            (0, _jquery.default)('#firstname').parent().addClass('info-required');
            ok = false;
          }

          (0, _jquery.default)('#lastname').parent().removeClass('info-required');

          if (!(0, _jquery.default)('#lastname').val()) {
            (0, _jquery.default)('#lastname').parent().addClass('info-required');
            ok = false;
          }

          (0, _jquery.default)('#phone').parent().removeClass('info-required');

          if (!(0, _jquery.default)('#phone').val()) {
            (0, _jquery.default)('#phone').parent().addClass('info-required');
            ok = false;
          }

          (0, _jquery.default)('#mobile').parent().removeClass('info-required');

          if (!(0, _jquery.default)('#mobile').val()) {
            (0, _jquery.default)('#mobile').parent().addClass('info-required');
            ok = false;
          }

          (0, _jquery.default)('#billing_company').parent().removeClass('info-required');

          if (!(0, _jquery.default)('#billing_company').val()) {
            (0, _jquery.default)('#billing_company').parent().addClass('info-required');
            ok = false;
          }

          (0, _jquery.default)('#streetaddress').parent().removeClass('info-required');

          if (!(0, _jquery.default)('#streetaddress').val()) {
            (0, _jquery.default)('#streetaddress').parent().addClass('info-required');
            ok = false;
          }

          (0, _jquery.default)('#city').parent().removeClass('info-required');

          if (!(0, _jquery.default)('#city').val()) {
            (0, _jquery.default)('#city').parent().addClass('info-required');
            ok = false;
          }

          (0, _jquery.default)('#state').parent().removeClass('info-required');

          if (!(0, _jquery.default)('#state').val()) {
            (0, _jquery.default)('#state').parent().addClass('info-required');
            ok = false;
          }

          (0, _jquery.default)('#postcode').parent().removeClass('info-required'); // VALIDATE POSTCODE, int req in API

          var my_postcode = (0, _jquery.default)('#postcode').val();

          if (_jquery.default.isNumeric(my_postcode)) {
            if (!(0, _jquery.default)('#postcode').val()) {
              (0, _jquery.default)('#postcode').parent().addClass('info-required');
              ok = false;
            }
          } else {
            (0, _jquery.default)('#postcode').parent().addClass('info-required');
            ok = false;
          } // end POSTCODE val


          (0, _jquery.default)('#iva').parent().removeClass('info-required');
          (0, _jquery.default)('#pec_univoco').parent().removeClass('info-required');
          var c = (0, _jquery.default)('#country').val() ? (0, _jquery.default)('#country').val().toLowerCase().toLowerCase() : ''; // if(c != "switzerland"){
          //     if(!jQuery("#iva").val() || jQuery("#iva").val().length != 11){
          //         jQuery("#iva").parent().addClass("info-required");
          //         ok = false;
          //     }
          // }

          if (!(0, _jquery.default)('#iva').val()) {
            (0, _jquery.default)('#iva').parent().addClass('info-required');
            ok = false;
          }

          if (!(0, _jquery.default)('#pec_univoco').val()) {
            (0, _jquery.default)('#pec_univoco').parent().addClass('info-required');
            ok = false;
          }

          (0, _jquery.default)('#publishdate').parent().removeClass('info-required');

          if (this.get('campaign.publish') == 'Later') {
            if (!(0, _jquery.default)('#publishdate').val()) {
              (0, _jquery.default)('#publishdate').parent().addClass('info-required');
              ok = false;
            }
          }

          (0, _jquery.default)('#publishuntil').parent().removeClass('info-required');

          if (!(0, _jquery.default)('#publishuntil').val()) {
            (0, _jquery.default)('#publishuntil').parent().addClass('info-required');
            ok = false;
          }

          if (!ok) {
            return;
          }

          this.get('applicationController').send('showLoader');
          this.send('createNewUser', function (user_id) {
            if (user_id) {
              that.send('createNewBrand', user_id, function (brand) {
                if (brand) {
                  that.send('createNewCampaign', (0, _jquery.default)('#email').val(), that.get('campaign.brand_name'), function (campaign_id) {
                    if (campaign_id) {
                      // loging in new user
                      var email = (0, _jquery.default)('#email').val(),
                          pass = (0, _jquery.default)('#password').val();
                      that.set('autoRedirect', true);
                      that.get('session').authenticate('authenticator:oauth2', email, pass).then(result => {
                        var user_token = that.get('session.data.authenticated.access_token');
                        that.get('session').set('data.username', email);
                        that.get('session').set('data.password', pass);
                        that.get('session').set('data.user_id', user_id);
                        that.get('session').set('data.brand_id', brand.id);
                        that.get('session').set('data.brand_company', brand.company);
                        that.get('session').set('data.funds', brand.funds);
                        that.get('session').set('data.paynow', true);
                        that.get('applicationController').send('submitAnimationDone', function () {
                          setTimeout(function () {
                            that.get('applicationController').send('hideLoader');
                            that.set('autoRedirect', true);
                            console.log('Redirecting to mycampaign..');
                            that.transitionToRoute('mycampaign');
                            console.log('*** GTM: campagna_creata');
                            dataLayer.push({
                              event: 'asyncAction',
                              gaHitType: 'event',
                              event_category: 'new_campaign',
                              event_action: 'campagna_creata'
                            });
                          }, 1000);
                        });
                        that.deleteLocalData();
                      }, err => {
                        var user_token = that.get('session.data.authenticated.access_token');

                        if (user_token) {
                          that.get('session').set('data.username', email);
                          that.get('session').set('data.password', pass);
                          that.get('session').set('data.user_id', user_id);
                          that.get('session').set('data.brand_id', brand.id);
                          that.get('session').set('data.brand_company', brand.company);
                          that.get('session').set('data.funds', brand.funds);
                          that.get('session').set('data.paynow', true);
                          that.get('applicationController').send('submitAnimationDone', function () {
                            setTimeout(function () {
                              that.get('applicationController').send('hideLoader');
                              that.set('autoRedirect', true);
                              console.log('Redirecting to mycampaign..1');
                              that.transitionToRoute('mycampaign');
                              console.log('*** GTM: campagna_creata');
                              dataLayer.push({
                                event: 'asyncAction',
                                gaHitType: 'event',
                                event_category: 'new_campaign',
                                event_action: 'campagna_creata'
                              });
                            }, 1000);
                          });
                          that.deleteLocalData();
                        } else {
                          that.get('applicationController').send('hideLoader');
                        }
                      });
                    } else {
                      that.get('applicationController').send('hideLoader');
                      alert(_t.default.compute('campaign_fail'));
                    }
                  });
                } else {
                  that.get('applicationController').send('hideLoader');
                }
              });
            } else {
              that.get('applicationController').send('hideLoader');
            }
          });
        }
      },

      createNewUser(cb) {
        var that = this;

        if ((0, _jquery.default)('#password').val() != (0, _jquery.default)('#passwordconfirm').val()) {
          cb();
          alert("Password doesn't match!");
          return;
        }

        var user = {
          user: {
            username: (0, _jquery.default)('#email').val(),
            password: (0, _jquery.default)('#password').val(),
            first_name: (0, _jquery.default)('#firstname').val(),
            last_name: (0, _jquery.default)('#lastname').val(),
            email: (0, _jquery.default)('#email').val(),
            is_brand: true,
            is_staff: false
          }
        };

        _jquery.default.ajax({
          type: 'POST',
          url: _environment.default.APP.API_HOST + '/users',
          data: JSON.stringify(user),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Basic bW9iaWxlQGhpdG9yaS1pbmMuY29tOmhpdG9yaTIwOSoqIQ==');
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res && res.user) {
              cb(res.user.id);
            } else {
              cb();
            }
          },
          error: function (e, exception) {
            cb();

            if (e && e.responseJSON && e.responseJSON.user) {
              var user = e.responseJSON.user;

              if (user.email[0] && user.email[0] == 'user with this email address already exists.') {
                // alert("Looks like a user with this email already exists, try logging-in!");
                // that.set("autoRedirect", true);
                // that.transitionToRoute('login');
                (0, _jquery.default)('#login-popup-email').val((0, _jquery.default)('#email').val());
                that.send('onLoginDialog');
              } else {
                alert(user);
              }
            } else {
              alert(_t.default.compute('campaign_fail'));
            }
          }
        });
      },

      createNewBrand(user_id, cb) {
        var origin = '',
            country = (0, _jquery.default)('#country').val(),
            iva = (0, _jquery.default)('#iva').val(),
            pec_univoco = (0, _jquery.default)('#pec_univoco').val();

        if (country.toLowerCase() == 'switzerland') {
          country = 'Italy';
          origin = (0, _jquery.default)('#country').val(); // iva = "0";
        }

        var brand = {
          brand: {
            user: user_id,
            company: this.get('campaign.brand_name'),
            phone: (0, _jquery.default)('#phone').val(),
            mobile: (0, _jquery.default)('#mobile').val(),
            cc_email: (0, _jquery.default)('#ccemail').val(),
            billing_company: (0, _jquery.default)('#billing_company').val(),
            street_address: (0, _jquery.default)('#streetaddress').val(),
            location_city: (0, _jquery.default)('#city').val(),
            location_state: (0, _jquery.default)('#state').val(),
            location_postcode: (0, _jquery.default)('#postcode').val(),
            location_country: country,
            origin: origin,
            stripe_token: 'TEST_TOKEN_WEB',
            iva: iva,
            pec_univoco: pec_univoco,
            is_agency: this.get('agencyAccount'),
            platform_name: this.platform
          }
        };

        _jquery.default.ajax({
          type: 'POST',
          url: _environment.default.APP.API_HOST + '/brands',
          data: JSON.stringify(brand),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Basic bW9iaWxlQGhpdG9yaS1pbmMuY29tOmhpdG9yaTIwOSoqIQ==');
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            console.log('brands here');

            if (res && res.brand && res.brand.id) {
              cb(res.brand);
            } else {
              cb();
            }

            console.log('test call brand new camp');
          },
          error: function (e, exception) {
            console.log('brands here');
            console.log('test call brand new camp');
            cb();
            alert(_t.default.compute('campaign_fail'));
          }
        });
      },

      createNewCampaign(user_id, brand_id, cb) {
        let user = user_id;
        var publish_date = moment().format('YYYY-MM-DDThh:mm:ss');
        var open_until_date = (0, _jquery.default)('#publishuntil').val();
        var mood_board = [];
        var response_time = '2 hours';
        var that = this;

        if (this.get('campaign.publish_status') == 'later') {
          publish_date = (0, _jquery.default)('#publishdate').val();
          publish_date = moment(publish_date, 'DD/MM/YY hh:mm').format('YYYY-MM-DDThh:mm:ss');
        }

        if (this.get('campaign.file_input_1_label')) {
          mood_board.push(this.get('campaign.file_input_1_label'));
        }

        if (this.get('campaign.file_input_2_label')) {
          mood_board.push(this.get('campaign.file_input_2_label'));
        }

        if (this.get('campaign.file_input_3_label')) {
          mood_board.push(this.get('campaign.file_input_3_label'));
        }

        if (this.get('campaign.file_input_4_label')) {
          mood_board.push(this.get('campaign.file_input_4_label'));
        }

        if (this.get('campaign.file_input_5_label')) {
          mood_board.push(this.get('campaign.file_input_5_label'));
        }

        if (this.get('campaign.file_input_6_label')) {
          mood_board.push(this.get('campaign.file_input_6_label'));
        }

        var instagram = this.get('ad_text') + ' ' + this.get('campaign.instagram_tags');
        var twitter = this.get('ad_text') + ' ' + this.get('campaign.twitter_tags');
        var facebook = this.get('ad_text') + ' ' + this.get('campaign.facebook_tags'); // var tags = {"instagram": instagram, "twitter": twitter, "facebook": facebook};

        var tags = {
          instagram: instagram.trim(),
          twitter: twitter.trim(),
          facebook: facebook.trim()
        };
        var sub_user = null;

        if (this.get('session.data.is_sub_user')) {
          sub_user = this.get('session.data.user_id');
          user = this.get('session.data.brand_username');
        }

        const actual_dos = this.campaignBuilder.makeUniqueArray(this.get('campaign.dos'), this.dos);
        const actual_donts = this.campaignBuilder.makeUniqueArray(this.get('campaign.donts'), this.donts);
        const brandId = this.get('session.data.brand_id');
        const campaign = {
          campaign: {
            user: user,
            sub_user: sub_user,
            content_love: this.get('campaign.content_we_love'),
            call_action: this.get('campaign.call_to_action'),
            pre_tags: tags,
            pub_guides: actual_dos,
            dont_dos: actual_donts,
            mood_board: [],
            platform_insta: this.get('campaign.instagram'),
            platform_insta_story: this.get('campaign.instagramstory'),
            platform_twitter: this.get('campaign.twitter'),
            platform_fb: this.get('campaign.facebook'),
            hero_image: this.get('campaign.hero_image'),
            campaign_logo: this.get('campaign.logo'),
            brand_comp_name: this.get('campaign.brand_name'),
            pro_comp_name: this.get('campaign.campaign_name'),
            pro_desc: this.get('campaign.product_description'),
            campaign_link: this.get('campaign.link'),
            where_purchase: this.get('campaign.where_to_purchase'),
            campaign_wallet: this.get('campaign_budget'),
            campaign_wallet_type_id: this.get('campaign_wallet_type_id'),
            ageMin: this.get('campaign.age_min'),
            ageMax: this.get('campaign.age_max'),
            gender: this.get('campaign.gender').toLowerCase(),
            info_demographic: this.get('campaign.target_regions_string'),
            interests: this.get('campaign.interests'),
            post_submissions: 0,
            posts_accepted: 0,
            response_time: response_time,
            // "response_time_date": "",
            publish_brief_app: publish_date,
            open_until_date: moment(open_until_date, 'DD/MM/YY hh:mm').format('YYYY-MM-DDThh:mm:ss'),
            created_datetime: moment().format('YYYY-MM-DDThh:mm:ss'),
            updated_date: moment().format('YYYY-MM-DDThh:mm:ss')
          }
        };
        var cred = '';

        if (this.get('session.isAuthenticated')) {
          cred = this.get('session.data.authenticated.token_type') + ' ' + this.get('session.data.authenticated.access_token');

          if (brandId) {
            campaign.campaign['brand_id'] = parseInt(brandId, 10);
          }
        } else {
          var email = (0, _jquery.default)('#email').val();
          var pass = (0, _jquery.default)('#password').val();
          cred = 'Basic ' + btoa(email + ':' + pass);
        }

        _jquery.default.ajax({
          type: 'POST',
          url: _environment.default.APP.API_HOST + '/campaigns',
          data: JSON.stringify(campaign),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', cred);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res && res.campaign) {
              if (res.campaign.id) {
                that.send('pushMoodImages', res.campaign.id, mood_board, 0, cred, function () {
                  cb(res.campaign.id);
                });
              } else {
                cb();
              }
            } else {
              cb();
            }
          },
          error: function (e, exception) {
            cb();
          }
        });
      },

      pushMoodImages(campaign_id, mood_board, index, cred, cb) {
        if (index < mood_board.length) {
          var data = {
            campaign_id: campaign_id,
            mood_image: mood_board[index]
          };
          var that = this;

          _jquery.default.ajax({
            type: 'POST',
            url: _environment.default.APP.API_HOST + '/addcampaignmoodimage',
            data: data,
            beforeSend: function (xhr) {
              xhr.setRequestHeader('Authorization', cred);
              xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            },
            success: function (res) {
              that.send('pushMoodImages', campaign_id, mood_board, index + 1, cred, cb);
            },
            error: function (e, exception) {
              that.send('pushMoodImages', campaign_id, mood_board, index + 1, cred, cb);
            }
          });
        } else {
          cb();
        }
      },

      removeUploadedImage(f, e) {
        if (f == 1) {
          this.set('campaign.hero_image', '');
          (0, _jquery.default)('#hero_input_1').val('');
          this.set('isCroppedHeroImageActived', false);
        } else if (f == 2) {
          this.set('campaign.logo', '');
          this.set('isCroppedLogoImageActived', false);
          (0, _jquery.default)('#hero_input_2').val('');
        }

        e.preventDefault();
        e.stopPropagation();
      },

      removeUploadedMood(f, e) {
        var key = 'campaign.file_input_' + f + '_label';
        this.set(key, '');
        (0, _jquery.default)('#file_input_' + f).val('');
        e.preventDefault();
        e.stopPropagation();
      },

      addGuideline(flag) {
        if (flag == 1) {
          //dos
          var dos = (0, _jquery.default)('#pub_guides').val();

          if (dos) {
            (0, _jquery.default)('#dos_list').prepend('<li><p>' + dos + " </p><span onclick='jQuery(this).parent().remove()'>X</span></li>");
            (0, _jquery.default)('#pub_guides').val('');
          }
        } else if (flag == 2) {
          //don'ts
          var donts = (0, _jquery.default)('#dont_dos').val();

          if (donts) {
            (0, _jquery.default)('#donts_list').prepend('<li><p>' + donts + " </p><span onclick='jQuery(this).parent().remove()'>X</span></li>");
            (0, _jquery.default)('#dont_dos').val('');
          }
        }
      },

      onVideoClick() {
        var dialog = (0, _jquery.default)('#newcamp-vid-dialog').modal({
          showClose: true,
          fadeDuration: 300,
          escapeClose: false,
          clickClose: false
        });

        if (this.observationDialog) {
          (0, _jquery.default)(this.observationDialog[0]).remove();
        }

        if (localStorage.getItem('lang') == 'en') {
          this.set('src', 'http://flytrendy.com/videos/brand_eng.mp4');
        } else {
          this.set('src', 'http://flytrendy.com/videos/brands.mp4');
        }

        var that = this;
        setTimeout(function () {
          (0, _jquery.default)('.close-modal ').click(function () {
            that.set('src', '');
          });

          if (that.get('applicationController.isMobileView')) {
            (0, _jquery.default)('#newcamp-vid-dialog .vjs-big-play-button').click();
            (0, _jquery.default)('#newcamp-vid-dialog .vjs-fullscreen-control').click();
          }
        }, 1000);
        this.observationDialog = dialog;
        return false;
      },

      onLoginDialog() {
        var dialog = (0, _jquery.default)('#login-dialog').modal({
          showClose: true,
          fadeDuration: 300,
          escapeClose: false,
          clickClose: false
        });
        var h = (0, _jquery.default)(window).height();
        var newH = (h - 309) / 3;
        (0, _jquery.default)('#login-dialog').css({
          'margin-top': newH
        });

        if (this.loginDialog) {
          (0, _jquery.default)(this.loginDialog[0]).remove();
        }

        setTimeout(function () {
          (0, _jquery.default)('.close-modal ').click(function () {});
        }, 1000);
        this.loginDialog = dialog;
        return false;
      },

      onLogin(e) {
        var identification = (0, _jquery.default)('#login-popup-email').val(),
            password = (0, _jquery.default)('#login-popup-password').val();
        var that = this;

        if (identification && password) {
          this.set('is_loggingin', true);
          this.get('session').authenticate('authenticator:oauth2', identification, password).then(result => {
            that.get('session').set('data.username', identification);
            that.get('session').set('data.password', password);
            that.store.query('user', {}).then(function (data) {
              if (data.content.length > 0) {
                var id = data.get('firstObject').id;
                that.get('session').set('data.user_id', id);
              }
            });
            that.store.query('brand', {}).then(function (data) {
              if (data.content.length > 0) {
                var obj = data.get('firstObject');
                that.get('session').set('data.brand_id', obj.id);
                that.get('session').set('data.brand_company', obj.company);
                that.get('session').set('data.brand_origin', obj.origin);
                that.get('session').set('data.brand_symbol', obj.brand_currency.symbol);
                that.get('session').set('data.brand_code', obj.brand_currency.code);
                that.get('session').set('data.brand_name', obj.brand_currency.name);
                that.get('session').set('data.funds', that.abbreviate_number(obj.funds), 1);
                that.get('session').set('data.brand_funds', obj.funds);
                that.set('campaign.brand_name', obj.company);
              }
            });
            (0, _jquery.default)('#login-dialog .close-modal').click();
            this.set('is_loggingin', false);
          }, err => {
            var user_token = this.get('session.data.authenticated.access_token');

            if (user_token) {
              that.get('session').set('data.username', identification);
              that.get('session').set('data.password', password);
              that.store.query('user', {}).then(function (data) {
                if (data.content.length > 0) {
                  var id = data.get('firstObject').id;
                  that.get('session').set('data.user_id', id);
                }
              });
              that.store.query('brand', {}).then(function (data) {
                if (data.content.length > 0) {
                  var obj = data.get('firstObject');
                  that.get('session').set('data.brand_id', obj.id);
                  that.get('session').set('data.brand_company', obj.company);
                  that.get('session').set('data.brand_origin', obj.origin);
                  that.get('session').set('data.brand_symbol', obj.brand_currency.symbol);
                  that.get('session').set('data.brand_code', obj.brand_currency.code);
                  that.get('session').set('data.brand_name', obj.brand_currency.name);
                  that.get('session').set('data.funds', that.abbreviate_number(obj.funds), 1);
                  that.get('session').set('data.brand_funds', obj.funds);
                  that.set('campaign.brand_name', obj.company);
                }
              });
              (0, _jquery.default)('#login-dialog .close-modal').click();
              this.set('is_loggingin', false);
            } else {
              this.set('is_loggingin', false);
              alert('Enter a valid username/password.');
            }
          });
        } else {
          this.set('is_loggingin', false);
          alert('username/password not provided');
        }
      }

    }
  });

  _exports.default = _default;
});