define("flytrendy/controllers/add-new-users", ["exports", "flytrendy/config/environment", "jquery", "flytrendy/helpers/t"], function (_exports, _environment, _jquery, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    init: function () {
      this._super(...arguments);
    },
    actions: {
      onAddUser() {
        let {
          first_name,
          last_name,
          email
        } = this.getProperties('first_name', 'last_name', 'email');
        var that = this;

        if (!first_name || !last_name || !email) {
          alert("All fields are required.");
          return false;
        }

        var password = Math.random().toString(36).substring(6);
        var user = {
          user: {
            username: email,
            password: password,
            first_name: first_name,
            last_name: last_name,
            email: email,
            is_additional_brand_user: this.get('session.data.brand_id'),
            is_brand: false,
            is_staff: false
          }
        };
        that.get('applicationController').send('showLoader');

        _jquery.default.ajax({
          type: 'POST',
          url: _environment.default.APP.API_HOST + '/users',
          data: JSON.stringify(user),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Basic bW9iaWxlQGhpdG9yaS1pbmMuY29tOmhpdG9yaTIwOSoqIQ==');
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res && res.user && res.user.id) {
              var notify = {
                "loginNotification": {
                  "email": email,
                  "passcode": password
                }
              };

              _jquery.default.ajax({
                type: 'POST',
                url: _environment.default.APP.API_HOST + '/iol/subuser/notification',
                data: JSON.stringify(notify),
                beforeSend: function (xhr) {
                  xhr.setRequestHeader('Authorization', 'Basic bW9iaWxlQGhpdG9yaS1pbmMuY29tOmhpdG9yaTIwOSoqIQ==');
                  xhr.setRequestHeader('Content-Type', 'application/json');
                },
                success: function (res) {
                  that.get('applicationController').send('hideLoader');
                  that.setProperties({
                    'first_name': '',
                    'last_name': '',
                    'email': ''
                  });
                  alert("Il Media Consultant è stato invitato");
                },
                error: function (e, exception) {
                  that.get('applicationController').send('hideLoader');
                  alert(_t.default.compute('campaign_fail'));
                }
              });
            } else {
              that.get('applicationController').send('hideLoader');
              alert(_t.default.compute('campaign_fail'));
            }
          },
          error: function (e, exception) {
            that.get('applicationController').send('hideLoader');

            if (e && e.responseJSON && e.responseJSON.user) {
              var user = e.responseJSON.user;

              if (user.email[0] && user.email[0] == 'user with this email address already exists.') {
                alert('User with this email address already exists.');
              } else {
                alert(user);
              }
            } else {
              alert(_t.default.compute('campaign_fail'));
            }
          }
        });

        return false;
      }

    }
  });

  _exports.default = _default;
});