define("flytrendy/services/campaign-builder", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    dos: _environment.default.PLATFORM.dos,
    donts: _environment.default.PLATFORM.donts,

    makeUniqueArray(items, addItems) {
      if (!items) {
        return;
      }

      let resultArray = items.concat(addItems);
      return resultArray = [...new Set([...items, ...addItems])];
    },

    excludeItemsFromArray(items, itemsExclude) {
      return items && items.filter(x => !itemsExclude.filter(y => y === x).length);
    }

  });

  _exports.default = _default;
});