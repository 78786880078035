define("flytrendy/controllers/mycampaignoverview", ["exports", "jquery", "flytrendy/config/environment", "flytrendy/helpers/t"], function (_exports, _jquery, _environment, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    notify: Ember.inject.service('notify'),
    queryParams: ['id', 'post'],
    edit_post_price: false,
    contact_brand_form: false,
    loading_contact_brand_form: false,
    data: [],
    audienceAuthenticityData: [],
    analyticsData: [],
    audienceQuality: 0,
    insightsReach: 0,
    insightsImpressions: 0,
    potentialReach: 0,
    mediaSpend: 0,
    posts: [],
    filteredPosts: [],
    postsByIds: {},
    contactBrandPosts: [],
    lang: 'en',
    id: null,
    campaign_id: 0,
    post: null,
    postClickId: null,
    observationDialog: null,
    purchasePostObject: null,
    selectedPostObject: null,
    filterByInstagram: true,
    filterByInstagramStory: true,
    filterByTwitter: true,
    filterByFacebook: true,
    filterByApproved: false,
    filterByRejected: false,
    buyContent: {},
    current_spend: '',
    potential_reach: 0,
    total_likes: '..',
    total_comments: '..',
    total_shares: '..',
    filterPending: true,
    filterApproved: true,
    filterDeclined: true,
    filterSubmitted: true,
    totalPending: 0,
    totalApproved: 0,
    totalSubmitted: 0,
    totalDeclined: 0,
    totalPosts: '..',
    totalApprovedPublished: '..',
    actionChange: false,
    actionApprove: false,
    actionDecline: false,
    actionLoading: false,
    displayActionCont: true,
    buy_serviceFee: 0,
    buy_subtotal: 0,
    buy_ivaFee: 0,
    buy_total: 0,
    postToApprove_object: null,
    sendingReport: false,
    totalApproved_updated: false,
    totalPending_updated: false,
    totalSubmitted_updated: false,
    totalDeclined_updated: false,
    third_party_selection_ebabled: false,
    total_third_party_posts_cost: 0.0,
    platform_campaign_wallet: _environment.default.PLATFORM.campaign.campaign_wallet,
    is_agency: _environment.default.APP.is_agency,
    iva_charges: _environment.default.PLATFORM.iva,
    campaign_wallet: 0,
    campaign_budget: 0,
    campaign_spent: 0,
    init: function () {
      this._super(...arguments);

      var that = this;
      setTimeout(function () {
        that.buyContent['status'] = false;

        if (that.post) {
          that.send('onPostBuyContentShow');
        }
      }, 500);
      this.set('lang', localStorage.getItem('lang'));
      this.send('refreshOnStatusChange');
      console.log('vvvvvv', this.get('brand'));
    },
    abbreviate_number: function (num, fixed) {
      if (num === null) {
        return 0;
      }

      if (fixed == 0) {
        num = parseInt(num);
      } else if (fixed == 2) {
        num = parseInt(num);
      } else {
        num = parseFloat(num).toFixed(2);
      }

      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    },
    actions: {
      onLoading() {
        setTimeout(function () {
          (0, _jquery.default)('.lazy').Lazy({
            appendScroll: (0, _jquery.default)('.post-cont')
          });
        }, 3000);
        this.set('sendingReport', false);
      },

      onEditPostPrice() {
        this.set('edit_post_price', true);
      },

      onCancelEditPostPrice() {
        this.set('edit_post_price', false);
        var selectedPost = this.get('selectedPostObject');
        selectedPost.setPostPrice();
      },

      onSaveEditPostPrice() {
        var that = this,
            newPrice = (0, _jquery.default)('#new_post_price').val().replace(/[^0-9]/g, '');
        var cred = this.get('session.data.authenticated.token_type') + ' ' + this.get('session.data.authenticated.access_token');
        var selectedPost = this.get('selectedPostObject');

        if (!newPrice) {
          return;
        } //NOTE:: this is updating actual price of the post


        var data = {
          campaignPost: {
            post_price: parseFloat(newPrice)
          }
        };

        _jquery.default.ajax({
          type: 'PATCH',
          url: _environment.default.APP.API_HOST + '/campaignposts/' + selectedPost.get('data_id'),
          data: JSON.stringify(data),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', cred);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            selectedPost.set('post_price', newPrice);
            that.set('edit_post_price', false);
          },
          error: function (jqXHR, exception) {
            that.send('onCancelEditPostPrice');
          }
        });
      },

      onViewReportClick() {
        this.set('sendingReport', true);
        var that = this; // var userauth = "Basic "+btoa(this.get("session.data.username") + ":" + this.get("session.data.password"));

        var cred = this.get('session.data.authenticated.token_type') + ' ' + this.get('session.data.authenticated.access_token');

        _jquery.default.ajax({
          type: 'GET',
          url: _environment.default.APP.API_HOST + '/campaignsummary?security_code=lkswmv67wezcllmrrgw6&campaign_id=' + this.id,
          // url: ENV.APP.API_HOST + "/campaignreport?id="+this.id,
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', cred);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res && res.toLowerCase() == 'success') {
              that.set('sendingReport', false);
              var currentLang = that.lang;

              if (currentLang == 'it') {
                that.get('notify').alert({
                  html: '<div>Rapporto inviato a ' + that.get('session.data.username') + '</div>'
                });
              } else {
                that.get('notify').alert({
                  html: '<div>Report sent at ' + that.get('session.data.username') + '</div>'
                });
              }
            } else {
              that.set('sendingReport', false);
              var currentLang = that.lang;

              if (currentLang == 'it') {
                that.get('notify').warning({
                  html: '<div>Richiesta fallita!</div>'
                });
              } else {
                that.get('notify').warning({
                  html: '<div>Request failed!</div>'
                });
              }
            }
          },
          error: function (jqXHR, exception) {
            that.set('sendingReport', false);
            var currentLang = that.lang;

            if (currentLang == 'it') {
              that.get('notify').warning({
                html: '<div>Richiesta fallita!</div>'
              });
            } else {
              that.get('notify').warning({
                html: '<div>Request failed!</div>'
              });
            }
          }
        });
      },

      closePostModal() {
        (0, _jquery.default)('#overview-post-dialog .close-modal').trigger('click');
      },

      onPostApprovedClicked(id, hasFeedback, e) {
        var post = this.postsByIds[id],
            that = this,
            lastStatus = post.get('status');

        if (lastStatus == 'approved' || lastStatus == 'published') {
          return;
        }

        post.setStatus('approved');
        var data = 'new_status=' + post.get('status') + '&campaign_post_id=' + id;
        var post_price = post.get('grand_total');
        this.set('actionLoading', true);
        this.send('updatePostStatusOnApi', data, function (ok, msg) {
          (0, _jquery.default)('#overview-post-dialog .close-modal').click();
          that.send('onActionSelection');
          that.set('actionLoading', false);
          var insufficient_funds_msg = 'Fondi non sufficienti. Paga con carta di credito o Paypal';

          if (ok) {
            that.store.query('brand', {}).then(function (data) {
              if (data.content.length > 0) {
                var obj = data.get('firstObject');
                that.get('session').set('data.brand_id', obj.id);
                that.get('session').set('data.brand_company', obj.company);
                that.get('session').set('data.brand_funds', obj.funds);
                that.get('session').set('data.funds', that.abbreviate_number(obj.funds, 1));
              }
            }, post_price);
            var currentLang = that.lang;

            if (currentLang == 'it') {
              that.get('notify').alert({
                html: '<div>Hai approvato questo contenuto con successo!</div>'
              });
              var insufficient_funds_msg = 'Fondi non sufficienti. Paga con carta di credito o Paypal';
            } else {
              that.get('notify').alert({
                html: '<div>Post approved successfully!</div>'
              });
              var insufficient_funds_msg = 'Insufficient funds!';
            }
          } else {
            post.setStatus(lastStatus);
            that.get('notify').warning({
              html: '<div>' + insufficient_funds_msg + '</div>'
            });

            if (msg.toLowerCase() == 'insufficient funds!') {
              that.get('applicationController').send('onInsufficientWalletClick', post.grand_total, function (ok) {
                if (ok) {
                  that.send('approvePostAfterTopup', id);
                }
              });
            }
          }
        }, post_price);

        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }
      },

      approvePostAfterTopup(id) {
        this.set('displayActionCont', false);
        this.set('actionApprove', true);
        this.set('actionLoading', true);
        this.set('selectedPostObject', this.postsByIds[id]);
        this.set('postClickId', id);
        var dialog = (0, _jquery.default)('#overview-post-dialog').modal({
          showClose: true,
          fadeDuration: 300,
          escapeClose: false,
          clickClose: false
        });
        var postImage = this.get('selectedPostObject.post_image');
        var videoUrl = this.get('selectedPostObject.video_url');

        if (this.get('selectedPostObject.is_video')) {
          setTimeout(function () {
            (0, _jquery.default)('#postcontent').html('<video controls poster="' + postImage + '"><source src="' + videoUrl + '" type="video/mp4"></video>');
          }, 500);
        } else {
          setTimeout(function () {
            (0, _jquery.default)('#postcontent').html('<img class="hero-image" src="' + postImage + '" />');
          }, 1000);
        }

        if (this.observationDialog) {
          (0, _jquery.default)(this.observationDialog[0]).remove();
        }

        this.observationDialog = dialog;
        var that = this;
        setTimeout(function () {
          (0, _jquery.default)('.close-modal ').click(function () {
            (0, _jquery.default)('#postcontent').text('');
          });
        }, 1000);
        var that = this;
        setTimeout(function () {
          that.send('onPostApprovedClicked', id, true);
        }, 1000);
      },

      onPostChangeClicked(id, hasFeedback, e) {
        var post = this.postsByIds[id],
            that = this,
            lastStatus = post.get('status');

        if (lastStatus == 'approved' || lastStatus == 'published') {
          return;
        }

        post.setStatus(lastStatus);
        var data = 'new_status=' + post.get('status') + '&campaign_post_id=' + id;

        if (hasFeedback) {
          var feedback = (0, _jquery.default)('#post_brand_feedback').val();

          if (feedback.length == 0) {
            var currentLang = that.lang;

            if (currentLang == 'it') {
              that.get('notify').alert({
                html: '<div>Si prega di fornire il vostro feedback a influencer!</div>'
              });
            } else {
              that.get('notify').alert({
                html: '<div>Please provide your feedback to influencer!</div>'
              });
            }

            return;
          }

          this.set('selectedPostObject.brand_feedback', feedback);
          data += '&feedback=' + this.get('selectedPostObject.brand_feedback');
        }

        this.set('actionLoading', true);
        this.send('updatePostStatusOnApi', data, function (ok, msg) {
          if (ok) {
            (0, _jquery.default)('#overview-post-dialog .close-modal').click();
            var currentLang = that.lang;

            if (currentLang == 'it') {
              that.get('notify').info({
                html: '<div>Hai dato un feedback con successo!</div>'
              });
            } else {
              that.get('notify').info({
                html: '<div>Feedback sent successfully!</div>'
              });
            } // that.get('notify').info({html: '<div>Feedback sent successfully!</div>'});

          } else {
            post.setStatus(lastStatus);
            that.get('notify').warning({
              html: '<div>' + msg + '</div>'
            });
          }

          that.send('onActionSelection');
          that.set('actionLoading', false);
        });
        e.preventDefault();
        e.stopPropagation();
      },

      onPostRejectedClicked(id, hasFeedback, e) {
        var post = this.postsByIds[id],
            that = this,
            lastStatus = post.get('status');

        if (lastStatus == 'approved' || lastStatus == 'declined' || lastStatus == 'published') {
          return;
        }

        var decline_reason = (0, _jquery.default)('#post_decline_reason').val();

        if (decline_reason !== '') {
          post.setStatus('declined');
          var data = 'new_status=' + post.get('status') + '&campaign_post_id=' + id + '&feedback=' + decline_reason;
          this.set('actionLoading', true);
          this.send('updatePostStatusOnApi', data, function (ok, msg) {
            if (ok) {
              (0, _jquery.default)('#overview-post-dialog .close-modal').click();
            } else {
              post.setStatus(lastStatus);
              that.get('notify').warning({
                html: '<div>' + msg + '</div>'
              });
            }

            var currentLang = that.lang;

            if (currentLang == 'it') {
              that.get('notify').warning({
                html: '<div>Hai declinato questo contenuto con successo!</div>'
              });
            } else {
              that.get('notify').warning({
                html: '<div>Post rejected successfully!</div>'
              });
            }

            that.send('onActionSelection');
            that.set('actionLoading', false);
          });
          e.preventDefault();
          e.stopPropagation();
        } else {
          var currentLang = that.lang;

          if (currentLang == 'it') {
            that.get('notify').warning({
              html: '<div>Seleziona un motivo di rifiuto dal menu a discesa!</div>'
            });
          } else {
            that.get('notify').warning({
              html: '<div>Please select a decline reason from the drop-down!</div>'
            });
          }
        }
      },

      onActionSelection(fl) {
        this.set('actionLoading', false);
        const platform_campaign_wallet = this.get('platform_campaign_wallet');
        const total_post = this.get('selectedPostObject.grand_total');
        const post_price = parseFloat(total_post);
        let user_funds = this.get('session').get('data.funds') || 0;
        user_funds = user_funds == 0 ? user_funds : parseFloat(user_funds.replace(/,/g, '')); // get campaign budget and current spend

        let campaign_budget = this.get('campaign_budget');
        let this_campaign_spent = this.get('campaign_spent');
        this_campaign_spent = this_campaign_spent == 0 ? this_campaign_spent : parseFloat(this_campaign_spent.replace(/'/g, ''));
        campaign_budget = campaign_budget == 0 ? campaign_budget : parseFloat(campaign_budget.replace(/'/g, ''));

        if (fl == 1) {
          if (post_price > user_funds) {
            this.get('notify').warning({
              html: '<div>Il saldo del wallet non è sufficiente.</div>'
            });
            this.send('closePostModal');
            return;
          }

          if (this_campaign_spent + post_price > campaign_budget && platform_campaign_wallet) {
            this.get('notify').warning({
              html: '<div>Budget della Campagna non è sufficiente.</div>'
            });
            this.send('closePostModal');
            return;
          }

          if (campaign_budget > this_campaign_spent && platform_campaign_wallet || this_campaign_spent == 0) {
            this.set('actionApprove', true);
            this.set('actionChange', false);
            this.set('actionDecline', false);
          }

          if (user_funds - post_price >= 0 && !platform_campaign_wallet) {
            this.set('actionApprove', true);
            this.set('actionChange', false);
            this.set('actionDecline', false);
          }
        } else if (fl == 2) {
          this.set('actionApprove', false);
          this.set('actionChange', true);
          this.set('actionDecline', false);
        } else if (fl == 3) {
          this.set('actionApprove', false);
          this.set('actionChange', false);
          this.set('actionDecline', true);
        } else {
          this.set('actionApprove', false);
          this.set('actionChange', false);
          this.set('actionDecline', false);
          this.set('displayActionCont', true);
          return;
        }

        this.set('displayActionCont', false);
      },

      onActionResponseCanel() {
        this.set('actionDecline', false);
        this.set('actionApprove', false);
        this.set('actionChange', false);
        this.set('displayActionCont', true);
      },

      updatePostStatusOnApi(data, cb, post_price) {
        var that = this;
        var cred = this.get('session.data.authenticated.token_type') + ' ' + this.get('session.data.authenticated.access_token');
        const params = new URLSearchParams(data);
        let feedback = params.get('feedback');
        let updatedString = data.split('&');
        let dataTosend = {};

        if (feedback) {
          dataTosend['feedback'] = feedback;
          updatedString.pop();
        }

        const paramsToSend = updatedString.join('&');

        _jquery.default.ajax({
          type: 'POST',
          url: _environment.default.APP.API_HOST + '/campaignpostupdatestatus?' + paramsToSend,
          data: JSON.stringify(dataTosend),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', cred);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res.campaignPostUpdateStatus && res.campaignPostUpdateStatus.success) {
              cb(true);
            } else {
              if (res.campaignPostUpdateStatus.comments) cb(false, res.campaignPostUpdateStatus.comments);
            } // *****************************************************************
            // for campaign specific wallet – used for ItaliaOnline only currently
            // update campaign_spent on post approval


            const platform_campaign_wallet = that.get('platform_campaign_wallet');

            if (platform_campaign_wallet) {
              let this_campaign_spent = that.get('campaign_spent');
              this_campaign_spent = this_campaign_spent == 0 ? this_campaign_spent : parseFloat(this_campaign_spent.replace(/'/g, ''));
              const this_post_price = parseFloat(post_price.replace(/,/g, ''));
              const newCampaignSpent = parseFloat(this_campaign_spent) + parseFloat(this_post_price);
              const latestSpend = Math.round((newCampaignSpent + Number.EPSILON) * 100) / 100;
              const data = {
                campaign: {
                  campaign_spent: latestSpend
                }
              };

              _jquery.default.ajax({
                type: 'PATCH',
                url: _environment.default.APP.API_HOST + '/campaigns/' + that.get('campaign_id'),
                data: JSON.stringify(data),
                beforeSend: function (xhr) {
                  xhr.setRequestHeader('Authorization', cred);
                  xhr.setRequestHeader('Content-Type', 'application/json');
                },
                success: function (res) {
                  that.set('current_spend', that.abbreviate_number(latestSpend, 1));
                },
                error: function (jqXHR, exception) {
                  that.get('notify').alert({
                    html: '<div>Il portafoglio della campagna non &egrave; sufficiente.</div>'
                  });
                }
              });
            } // END campaign specific wallet – used for IOL only currently
            // *****************************************************************

            /*alert('res.campaignPostUpdateStatus: ' + JSON.stringify(res.campaignPostUpdateStatus));
                                      if(res.campaignPostUpdateStatus === 'accepted' && res.campaignPostUpdateStatus.success){
                                       alert('update price here? $' + JSON.stringify(res));
                                     }*/
            // that.send("refreshOnStatusChange");

          },
          error: function (jqXHR, exception) {
            var currentLang = that.lang;

            if (currentLang == 'it') {
              cb(false, 'Impossibile connettersi al server, riprova più tardi!');
            } else {
              cb(false, "Can't connect to the server, please try again later!");
            }
          }
        });
      },

      onPlatformFilterClick(platform) {
        if (platform == 1) {
          (0, _jquery.default)('.mycampaignoverview .platform-filter .instagram').toggleClass('instagram_selected');
          this.filterByInstagram = false;

          if ((0, _jquery.default)('.mycampaignoverview .platform-filter .instagram').hasClass('instagram_selected')) {
            this.filterByInstagram = true;
          }
        } else if (platform == 2) {
          (0, _jquery.default)('.mycampaignoverview .platform-filter .instagramstory').toggleClass('instagramstory_selected');
          this.filterByInstagramStory = false;

          if ((0, _jquery.default)('.mycampaignoverview .platform-filter .instagramstory').hasClass('instagramstory_selected')) {
            this.filterByInstagramStory = true;
          }
        } else if (platform == 3) {
          (0, _jquery.default)('.mycampaignoverview .platform-filter .twitter').toggleClass('twitter_selected');
          this.filterByTwitter = false;

          if ((0, _jquery.default)('.mycampaignoverview .platform-filter .twitter').hasClass('twitter_selected')) {
            this.filterByTwitter = true;
          }
        } else if (platform == 4) {
          (0, _jquery.default)('.mycampaignoverview .platform-filter .facebook').toggleClass('facebook_selected');
          this.filterByFacebook = false;

          if ((0, _jquery.default)('.mycampaignoverview .platform-filter .facebook').hasClass('facebook_selected')) {
            this.filterByFacebook = true;
          }
        }

        this.send('filterByPlatform');
      },

      onStatusFilterClick(status) {
        if (status == 1) {
          (0, _jquery.default)('.mycampaignoverview .status-filter .green').toggleClass('green_selected');
          this.filterByApproved = false;

          if ((0, _jquery.default)('.mycampaignoverview .status-filter .green').hasClass('green_selected')) {
            this.filterByApproved = true;
          }
        } else if (status == 2) {
          (0, _jquery.default)('.mycampaignoverview .status-filter .red').toggleClass('red_selected');
          this.filterByRejected = false;

          if ((0, _jquery.default)('.mycampaignoverview .status-filter .red').hasClass('red_selected')) {
            this.filterByRejected = true;
          }
        }

        this.send('filterByPlatform');
      },

      filterByStatusClicked(flag) {
        if (flag == 1) {
          (0, _jquery.default)('#pending').toggleClass('selected');
          this.set('totalPending_updated', false);

          if (!this.get('filterPending')) {
            this.set('filterPending', true);
          } else {
            this.set('filterPending', false);
          }
        } else if (flag == 2) {
          (0, _jquery.default)('#approved').toggleClass('selected');
          this.set('totalApproved_updated', false);

          if (!this.get('filterApproved')) {
            this.set('filterApproved', true);
          } else {
            this.set('filterApproved', false);
          }
        } else if (flag == 3) {
          (0, _jquery.default)('#declined').toggleClass('selected');
          this.set('totalDeclined_updated', false);

          if (!this.get('filterDeclined')) {
            this.set('filterDeclined', true);
          } else {
            this.set('filterDeclined', false);
          }
        } else if (flag == 4) {
          (0, _jquery.default)('#published').toggleClass('selected');
          this.set('totalSubmitted_updated', false);

          if (!this.get('filterSubmitted')) {
            this.set('filterSubmitted', true);
          } else {
            this.set('filterSubmitted', false);
          }
        }

        this.send('filterByPlatform');
      },

      filterByPlatform() {
        var instagram = '',
            instagramstory = '',
            twitter = '',
            facebook = '',
            filteredData = [],
            obj = null;
        if (this.filterByInstagram) instagram = 'instagram';
        if (this.filterByInstagramStory) instagramstory = 'instagramstory';
        if (this.filterByTwitter) twitter = 'twitter';
        if (this.filterByFacebook) facebook = 'facebook';

        for (var i = 0; i < this.get('posts').length; i++) {
          obj = this.get('posts')[i];

          if (obj.post_social_type == instagram || obj.post_social_type == instagramstory || obj.post_social_type == twitter || obj.post_social_type == facebook) {
            if (this.get('filterPending')) {
              if (obj.status == 'pending') {
                filteredData.push(this.get('posts')[i]);
              }
            }

            if (this.get('filterDeclined')) {
              if (obj.status == 'declined') {
                filteredData.push(this.get('posts')[i]);
              }
            }

            if (this.get('filterApproved')) {
              if (obj.status == 'approved') {
                filteredData.push(this.get('posts')[i]);
              }
            }

            if (this.get('filterSubmitted')) {
              if (obj.status == 'published') {
                filteredData.push(this.get('posts')[i]);
              }
            }
          }
        }

        this.set('filteredPosts', filteredData);
        this.send('sortPosts', null, true);
        setTimeout(function () {
          (0, _jquery.default)('.lazy').Lazy({
            appendScroll: (0, _jquery.default)('.post-cont')
          });
        }, 1000);
      },

      onSortChange(e) {
        this.send('sortPosts', null, true);
      },

      sortPosts(sortBy, resetScroll) {
        if (!sortBy) {
          sortBy = (0, _jquery.default)('#post_list_sort_by option:selected').val();
        }

        if (sortBy) {
          // TODO: change in the future, workaround to fix Uncaught TypeError
          sortBy = sortBy.toLowerCase();
        }

        var list = this.get('filteredPosts'),
            sortedList = [];

        if (sortBy == 1) {
          for (var i = 0; i < list.length; i++) {
            for (var j = 0; j < list.length; j++) {
              if (list[i].updated_date > list[j].updated_date) {
                var tempObject = list[i];
                list[i] = list[j];
                list[j] = tempObject;
              }
            }
          }
        } else if (sortBy == 2) {
          for (var i = 0; i < list.length; i++) {
            for (var j = 0; j < list.length; j++) {
              if (parseFloat(list[i].influecer_total_price) > parseFloat(list[j].influecer_total_price)) {
                var tempObject = list[i];
                list[i] = list[j];
                list[j] = tempObject;
              }
            }
          }
        } else if (sortBy == 3) {
          for (var i = 0; i < list.length; i++) {
            for (var j = 0; j < list.length; j++) {
              if (parseInt(list[i].social_account_info.followers) > parseInt(list[j].social_account_info.followers)) {
                var tempObject = list[i];
                list[i] = list[j];
                list[j] = tempObject;
              }
            }
          }
        } else if (sortBy == 4) {
          for (var i = 0; i < list.length; i++) {
            for (var j = 0; j < list.length; j++) {
              if (parseInt(list[i].engagement_rate) > parseInt(list[j].engagement_rate)) {
                var tempObject = list[i];
                list[i] = list[j];
                list[j] = tempObject;
              }
            }
          }
        }

        var updatedList = [];

        if ((0, _jquery.default)('.mycampaignoverview .sort-by span').hasClass('up')) {
          for (var i = list.length - 1; i >= 0; i--) {
            updatedList.push(list[i]);
          }
        } else {
          for (var i = 0; i < list.length; i++) {
            updatedList.push(list[i]);
          }
        }

        this.set('filteredPosts', updatedList);
        setTimeout(function () {
          (0, _jquery.default)('.lazy').Lazy({
            appendScroll: (0, _jquery.default)('.post-cont')
          });
        }, 1000);

        if (resetScroll) {
          document.getElementById('campaign_overview_post_cont').scrollTop = 0;
        }
      },

      sortOrderClick() {
        if ((0, _jquery.default)('.mycampaignoverview .sort-by span').hasClass('up')) {
          (0, _jquery.default)('.mycampaignoverview .sort-by span').removeClass('up');
        } else {
          (0, _jquery.default)('.mycampaignoverview .sort-by span').addClass('up');
        }

        var list = this.get('filteredPosts'),
            reversedList = [];

        for (var i = list.length - 1; i >= 0; i--) {
          reversedList.push(list[i]);
        }

        this.set('filteredPosts', reversedList);
        setTimeout(function () {
          (0, _jquery.default)('.lazy').Lazy({
            appendScroll: (0, _jquery.default)('.post-cont')
          });
        }, 1000);
        document.getElementById('campaign_overview_post_cont').scrollTop = 0;
      },

      onAudienceAuthenticityClick(id, getUserFollowers, engagement_rate, influencer_interests, influencer_region_country, social_account_id, name, profilePicture, post_object) {
        (0, _jquery.default)('.loading-overlay').show();
        var that = this;
        var social_id = social_account_id; // var social_id = '17841402268170125';

        var cred = that.get('session.data.authenticated.token_type') + ' ' + that.get('session.data.authenticated.access_token');

        _jquery.default.ajax({
          type: 'GET',
          url: _environment.default.APP.API_HOST + '/insights?post_id=' + post_object.id,
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', cred);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res) {
              (0, _jquery.default)('.loading-overlay').hide();
              var audience_quality_score = parseInt(res.insightsViewSet.result.audience_quality[0]);

              var _followers = parseInt(post_object.social_account_info.followers ? post_object.social_account_info.followers : 0);

              audience_quality_score = audience_quality_score / _followers;
              audience_quality_score = parseInt(audience_quality_score * 100);
              that.set('audienceQuality', audience_quality_score);
              that.set('insightsReach', res.insightsViewSet.result.reach_impressions[0]);
              that.set('insightsImpressions', res.insightsViewSet.result.reach_impressions[1]);
              that.send('showInsights', res, getUserFollowers, engagement_rate, influencer_interests, influencer_region_country, social_account_id, name, profilePicture, post_object);
            }
          },
          error: function (jqXHR, exception) {
            (0, _jquery.default)('.loading-overlay').hide();
            var currentLang = that.lang;

            if (currentLang == 'it') {
              that.get('notify').alert({
                html: 'Approfondimenti non ancora disponibili per questo profilo, ricontrolla!</div>'
              });
            } else {
              that.get('notify').alert({
                html: '<div>Insights not available for this profile yet, check back!</div>'
              });
            }
          }
        });
      },

      showInsights(res, getUserFollowers, engagement_rate, influencer_interests, influencer_region_country, social_account_id, name, profilePicture, post_object) {
        // console.log('insights_data: ', JSON.stringify(res));
        var insights_data = res; // then show pop-up

        var dialog = (0, _jquery.default)('#audience-authenticity-popup').modal({
          showClose: true,
          fadeDuration: 300,
          escapeClose: false,
          clickClose: false
        });
        var stub_likes_comments = 500000;
        var stub_followers = 500000;
        var likes_comments_ratio = stub_likes_comments / stub_followers;

        if (likes_comments_ratio >= 1) {
          //high
          (0, _jquery.default)('.likes-comments-dots .dot').addClass('high');
          (0, _jquery.default)('.likes-comments-dots .dot').removeClass('low med');
          (0, _jquery.default)('.likes-comments-dots .label').html('High Likes/Comments (great spread, we recommend this account!)');
        } else if (likes_comments_ratio > 0.5) {
          //medium
          (0, _jquery.default)('.likes-comments-dots .dot').addClass('med');
          (0, _jquery.default)('.likes-comments-dots .dot').removeClass('low high');
          (0, _jquery.default)('.likes-comments-dots .label').html('Medium Likes/Comments (good/ok spread, we recommend this account!)');
        } else {
          // low
          (0, _jquery.default)('.likes-comments-dots .dot').addClass('low');
          (0, _jquery.default)('.likes-comments-dots .dot').removeClass('high med');
          (0, _jquery.default)('.likes-comments-dots .label').html('Low Likes/Comments (average spread, account ok but you may find more suitable Influencers on our platform!)');
        } // google.charts.load('current', { packages: ['corechart'] });


        google.charts.load('current', {
          packages: ['gauge']
        });
        google.charts.setOnLoadCallback(drawChart);

        function drawChart() {
          var audience_quality_score = parseInt(insights_data.insightsViewSet.result.audience_quality[0]);

          var _followers = parseInt(post_object.social_account_info.followers ? post_object.social_account_info.followers : 0);

          audience_quality_score = audience_quality_score / _followers;
          audience_quality_score = parseInt(audience_quality_score * 100);
          var data = google.visualization.arrayToDataTable([['Effort', 'Amount given'], ['', audience_quality_score]]);
          var options = {
            width: 300,
            height: 300,
            redFrom: 90,
            redTo: 100,
            yellowFrom: 75,
            yellowTo: 90,
            minorTicks: 5,
            legend: 'none'
          };
          var chart = new google.visualization.Gauge(document.getElementById('piechart'));
          chart.draw(data, options);
        }

        google.charts.load('current', {
          packages: ['geochart'],
          // Note: you will need to get a mapsApiKey for your project.
          // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
          mapsApiKey: 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY'
        });
        google.charts.setOnLoadCallback(drawRegionsMap);

        function drawRegionsMap() {
          var _audience_country = insights_data.insightsViewSet.result.all_countries;

          _audience_country.unshift(['Country', 'Popularity']);

          var data = google.visualization.arrayToDataTable(_audience_country); // var data = google.visualization.arrayToDataTable(insights_data.insightsViewSet.audience_city);

          var options = {
            colors: ['#00688B'],
            legend: 'none'
          };
          var chart2 = new google.visualization.GeoChart(document.getElementById('regions_div'));
          chart2.draw(data, options);
        }

        google.charts.load('current', {
          packages: ['corechart']
        });
        google.charts.setOnLoadCallback(drawChartLine);

        function drawChartLine() {
          var data = google.visualization.arrayToDataTable([['Year', 'Followers'], ['Dec 2019', 1000], ['Jan 2020', 1170], ['Feb 2020', 660]]);
          var options = {
            width: 500,
            height: 400,
            fontName: 'Roboto',
            titleTextStyle: {
              color: '#00688B',
              fontSize: 16,
              bold: false
            },
            title: 'Followers',
            curveType: 'function',
            legend: {
              position: 'bottom'
            },
            chartArea: {
              left: 40,
              width: '80%',
              height: '80%'
            },
            colors: ['#ff5a00']
          };
          var elem = document.getElementById('curve_chart_followers');
          var chart3 = new google.visualization.LineChart(elem);
          chart3.draw(data, options);
        }

        google.charts.setOnLoadCallback(drawChartLine3);

        function drawChartLine3() {
          var data = google.visualization.arrayToDataTable([['Year', 'Followers', {
            role: 'annotation'
          }], ['Dec 2019', 10211, 10211], ['Jan 2020', 5423, 5423], ['Feb 2020', 16681, 16681]]);
          var options = {
            width: 500,
            height: 400,
            fontName: 'Roboto',
            titleTextStyle: {
              color: '#757575',
              fontSize: 16,
              bold: false
            },
            title: ' ',
            curveType: 'function',
            legend: {
              position: 'bottom'
            },
            chartArea: {
              left: 40,
              width: '80%',
              height: '80%'
            },
            colors: ['#00688B']
          };
          var chart4 = new google.visualization.LineChart(document.getElementById('curve_chart_followers_2'));
          chart4.draw(data, options);
        }

        google.charts.load('current', {
          packages: ['corechart']
        });
        google.charts.setOnLoadCallback(drawChartLine2);

        function drawChartLine2() {
          var data = google.visualization.arrayToDataTable([['Year', 'Following', {
            role: 'annotation'
          }], ['Dec 2019', 1012, 1012], ['Jan 2020', 1173, 1173], ['Feb 2020', 674, 674]]);
          var options = {
            width: 500,
            height: 400,
            fontName: 'Roboto',
            titleTextStyle: {
              color: '#757575',
              fontSize: 16,
              bold: false
            },
            title: ' ',
            curveType: 'function',
            legend: {
              position: 'bottom'
            },
            chartArea: {
              left: 40,
              width: '80%',
              height: '80%'
            },
            colors: ['#ff5a00']
          };
          var chart3 = new google.visualization.LineChart(document.getElementById('curve_chart_following'));
          chart3.draw(data, options);
        }

        google.charts.load('current', {
          packages: ['bar']
        });
        google.charts.setOnLoadCallback(drawBarChartGender);

        function drawBarChartGender() {
          var data = google.visualization.arrayToDataTable([['Influencer Gender Distribution', '', {
            role: 'style'
          }], ['Male', '21%', 'color: #00688B'], ['Female', '79%', 'color: pink']]);
          var options = {
            width: 500,
            height: 400,
            colors: ['#00688B'],
            legend: {
              position: 'none'
            },
            chart: {
              title: ' ',
              subtitle: ' '
            },
            bar: {
              groupWidth: '90%'
            }
          };
          var chart = new google.charts.Bar(document.getElementById('gender_bar_chart'));
          chart.draw(data, google.charts.Bar.convertOptions(options));
        }

        google.charts.setOnLoadCallback(drawStackedAge);

        function drawStackedAge() {
          var data = google.visualization.arrayToDataTable(insights_data.insightsViewSet.result.audience_gender_age);
          var options = {
            width: 1000,
            height: 400,
            colors: ['#00688B'],
            legend: {
              position: 'none'
            },
            chart: {
              title: ' ',
              subtitle: ' '
            },
            bar: {
              groupWidth: '90%'
            }
          };
          var chart = new google.charts.Bar(document.getElementById('age_graph'));
          chart.draw(data, google.charts.Bar.convertOptions(options));
        }

        google.charts.setOnLoadCallback(drawEthChartGender);

        function drawEthChartGender() {
          var data = google.visualization.arrayToDataTable(insights_data.insightsViewSet.result.audience_country);
          var options = {
            width: 1000,
            height: 400,
            colors: ['#ff5a00'],
            legend: {
              position: 'none'
            },
            chart: {
              title: ' ',
              subtitle: ' '
            },
            vAxis: {
              title: '',
              textPosition: 'none'
            },
            bar: {
              groupWidth: '90%'
            }
          };
          var chart = new google.charts.Bar(document.getElementById('ethenticity_bar_chart'));
          chart.draw(data, google.charts.Bar.convertOptions(options));
        }

        google.charts.setOnLoadCallback(drawLanguageChartGender);

        function drawLanguageChartGender() {
          var data = google.visualization.arrayToDataTable(insights_data.result.audience_city);
          var options = {
            width: 1000,
            height: 400,
            colors: ['#00688B'],
            legend: {
              position: 'none'
            },
            chart: {
              title: ' ',
              subtitle: ' '
            },
            vAxis: {
              title: '',
              textPosition: 'none'
            },
            bar: {
              groupWidth: '90%'
            }
          };
          var chart = new google.charts.Bar(document.getElementById('language_bar_chart'));
          chart.draw(data, google.charts.Bar.convertOptions(options));
        }

        google.charts.load('current', {
          packages: ['corechart', 'bar']
        });
        google.charts.setOnLoadCallback(drawStacked);

        function drawStacked() {
          var data = google.visualization.arrayToDataTable([['', '', {
            role: 'annotation'
          }], ['Lifestyle', 123, 'Lifestyle'], ['Beauty', 234, 'Beauty'], ['Travel', 312, 'Travel']]);
          var options = {
            width: 1000,
            height: 400,
            title: '',
            chartArea: {
              width: '90%'
            },
            legend: {
              position: 'none'
            },
            isStacked: true,
            hAxis: {
              title: '',
              minValue: 0,
              titleTextStyle: {
                italic: false
              }
            },
            vAxis: {
              title: '',
              textPosition: 'none'
            },
            colors: ['#00688B']
          };
          var chart = new google.visualization.BarChart(document.getElementById('audience_interest'));
          chart.draw(data, options);
        }

        google.charts.setOnLoadCallback(avaerage_likes);

        function avaerage_likes() {
          var reach_count = parseInt(insights_data.insightsViewSet.result.reach_impressions[0]);
          var impressions_count = parseInt(insights_data.insightsViewSet.result.reach_impressions[1]); // Reach and Impressions
          // Number of times the influencers media has been seen or uniquely viewed in last 28 days.

          var data = google.visualization.arrayToDataTable([['', '', {
            role: 'annotation'
          }], ['Reach', reach_count, 'Reach'], ['Impressions', impressions_count, 'Impressions']]);
          var options = {
            width: 1100,
            height: 400,
            title: '',
            chartArea: {
              width: '90%'
            },
            legend: {
              position: 'none'
            },
            isStacked: true,
            hAxis: {
              title: '',
              minValue: 0,
              titleTextStyle: {
                italic: false
              }
            },
            vAxis: {
              title: '',
              textPosition: 'none'
            },
            colors: ['#00688B']
          };
          var chart = new google.visualization.BarChart(document.getElementById('reach_impressions'));
          chart.draw(data, options);
        } // influencer details


        this.set('audienceAuthenticityData.name', name);
        this.set('audienceAuthenticityData.profile', profilePicture);
        this.set('audienceAuthenticityData.getUserFollowers', getUserFollowers);
        this.set('audienceAuthenticityData.engagement_rate', engagement_rate);
        this.set('audienceAuthenticityData.influencer_interests', influencer_interests);
        this.set('audienceAuthenticityData.influencer_region_country', influencer_region_country);

        if (this.observationDialog) {
          (0, _jquery.default)(this.observationDialog[0]).remove();
        }

        this.observationDialog = dialog;
        this.send('audienceAuthenticityGraphs');
      },

      audienceAuthenticityGraphs() {
        var analytics_data = this.get('audienceAuthenticityData'); // Load Google Charts

        google.charts.load('visualization', '1', {
          packages: ['corechart']
        });
        google.charts.setOnLoadCallback(drawAuthenticityChart);

        function drawAuthenticityChart() {
          setTimeout(function () {
            drawAudienceChart();
          }, 500);
        } // Audience Authenticity


        function drawAudienceChart() {
          var data = google.visualization.arrayToDataTable([['Audience', '%'], ['Authenticity', 93], ['', 7]]);
          var options = {
            title: '',
            pieHole: 0.6,
            pieSliceBorderColor: 'none',
            colors: ['#067ab5', '#eaeaea'],
            legend: {
              position: 'none'
            },
            pieSliceText: 'none',
            tooltip: {
              trigger: 'none'
            }
          };
          var chart = new google.visualization.PieChart(document.getElementById('authenticity-chart'));
          chart.draw(data, options);
        } // end drawAuthenticityChart

      },

      onAnalyticsClick() {
        var dialog = (0, _jquery.default)('#analytics-popup').modal({
          showClose: true,
          fadeDuration: 300,
          escapeClose: false,
          clickClose: false
        });

        if (this.observationDialog) {
          (0, _jquery.default)(this.observationDialog[0]).remove();
        }

        this.observationDialog = dialog;
        this.send('analyticsGraphs');
      },

      analyticsGraphs() {
        var analytics_data = this.get('analyticsData'); // Load Google Charts

        google.charts.load('current', {
          packages: ['bar']
        });
        google.charts.setOnLoadCallback(drawCharts);

        function drawCharts() {
          setTimeout(function () {
            drawReachChart();
            drawPostsChart();
            drawLikesCommentsChart();
          }, 500);
        } // Reach


        function drawReachChart() {
          var potential_reach_total = analytics_data['campaignAnalytics']['potential_reach_total'];

          function abbreviate_number(num, fixed) {
            if (num === null) {
              return 0;
            }

            if (fixed == 0) {
              num = parseInt(num);
            } else if (fixed == 2) {
              num = parseInt(num);
            } else {
              num = parseFloat(num).toFixed(2);
            }

            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
          }

          var data = google.visualization.arrayToDataTable([[_t.default.compute('analytics_posts_status'), _t.default.compute('analytics_number_of_posts')], [_t.default.compute('analytics_approved_reach'), analytics_data['campaignAnalytics']['potential_reach_approved']], [_t.default.compute('analytics_pending_reach'), analytics_data['campaignAnalytics']['potential_reach_pending']]]);
          var statsPattern = '0';
          var formatNumber = new google.visualization.NumberFormat({
            pattern: statsPattern
          });
          formatNumber.format(data, 0);
          var options = {
            width: 500,
            height: 400,
            colors: ['#ff5a00'],
            legend: {
              position: 'none'
            },
            tooltip: 'none',
            chart: {
              title: _t.default.compute('analytics_reach') + ': ' + abbreviate_number(potential_reach_total, 2),
              subtitle: _t.default.compute('analytics_title')
            },
            bar: {
              groupWidth: '90%'
            }
          };
          var chart = new google.charts.Bar(document.getElementById('analytics_reach'));
          chart.draw(data, google.charts.Bar.convertOptions(options));
        } // Number of Posts


        function drawPostsChart() {
          var data = google.visualization.arrayToDataTable([[_t.default.compute('analytics_posts_status'), _t.default.compute('analytics_number_of_posts')], [_t.default.compute('analytics_recieved_posts'), analytics_data['campaignAnalytics']['number_posts_received']], [_t.default.compute('analytics_approved_posts'), analytics_data['campaignAnalytics']['number_posts_accepted']]]);
          var options = {
            width: 500,
            height: 400,
            colors: ['#00688B'],
            legend: {
              position: 'none'
            },
            chart: {
              title: _t.default.compute('analytics_posts'),
              subtitle: _t.default.compute('analytics_title')
            },
            bar: {
              groupWidth: '90%'
            }
          };
          var chart = new google.charts.Bar(document.getElementById('analytics_posts'));
          chart.draw(data, google.charts.Bar.convertOptions(options));
        } // Number of Likes


        function drawLikesCommentsChart() {
          var data = google.visualization.arrayToDataTable([[_t.default.compute('analytics_posts_status'), _t.default.compute('analytics_number_of_posts')], [_t.default.compute('analytics_likes'), analytics_data['campaignAnalytics']['number_likes']], [_t.default.compute('analytics_comments'), analytics_data['campaignAnalytics']['number_comments']]]);
          var options = {
            width: 1100,
            height: 400,
            colors: ['#ff5a00'],
            legend: {
              position: 'none'
            },
            chart: {
              title: _t.default.compute('analytics_likes') + ' & ' + _t.default.compute('analytics_comments'),
              subtitle: _t.default.compute('analytics_title')
            },
            axes: {
              x: {}
            },
            bar: {
              groupWidth: '90%'
            }
          };
          var chart = new google.charts.Bar(document.getElementById('number_likes_comments'));
          chart.draw(data, google.charts.Bar.convertOptions(options));
        }
      },

      onPostClick(id, e) {
        var that = this;
        this.set('selectedPostObject', this.postsByIds[id]);
        this.set('postClickId', id);
        this.set('edit_post_price', false);
        var dialog = (0, _jquery.default)('#overview-post-dialog').modal({
          showClose: true,
          fadeDuration: 300,
          escapeClose: false,
          clickClose: false
        });
        var postImage = this.get('selectedPostObject.post_image');
        var videoUrl = this.get('selectedPostObject.video_url');

        if (videoUrl && !videoUrl.includes('https')) {
          videoUrl = 'https://' + videoUrl;
        }

        if (!that.get('selectedPostObject.seen')) {
          that.send('updatePostSeenFlag', id, function (ok) {
            if (ok) {
              that.set('selectedPostObject.seen', true); // that.send("refreshOnStatusChange");
            }
          });
        }

        if (this.get('selectedPostObject.is_video')) {
          setTimeout(function () {
            (0, _jquery.default)('#postcontent').html('<video autoplay controls poster="' + postImage + '"><source src="' + videoUrl + '" type="video/mp4"></video>');
          }, 500);
        } else {
          setTimeout(function () {
            (0, _jquery.default)('#postcontent').html('<img class="hero-image" src="' + postImage + '" />');
          }, 500);
        }

        if (this.observationDialog) {
          (0, _jquery.default)(this.observationDialog[0]).remove();
        }

        this.observationDialog = dialog;
        setTimeout(function () {
          that.send('onActionSelection');
        }, 200);
        setTimeout(function () {
          (0, _jquery.default)('.close-modal ').click(function () {
            (0, _jquery.default)('#postcontent').text('');
            that.send('onCancelEditPostPrice');
          });
        }, 1000);
        return false;
      },

      updatePostSeenFlag(id, cb) {
        // var userauth = "Basic "+btoa(this.get("session.data.username") + ":" + this.get("session.data.password"));
        var cred = this.get('session.data.authenticated.token_type') + ' ' + this.get('session.data.authenticated.access_token');
        var d = {
          campaignPost: {
            seen: true
          }
        };

        _jquery.default.ajax({
          type: 'PATCH',
          url: _environment.default.APP.API_HOST + '/see/campaignpost/' + id + '/',
          data: JSON.stringify(d),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', cred);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            cb(true);
          },
          error: function (jqXHR, exception) {
            cb(false);
          }
        });
      },

      onPostBuyContentShow() {
        (0, _jquery.default)('#buying-price').val('');
        (0, _jquery.default)('#buy-content-btn').removeClass('loading');
        (0, _jquery.default)('#buy-content-btn').removeClass('finish-loading');
        this.set('purchasePostObject', this.postsByIds[this.post]);
        var dialog = (0, _jquery.default)('#buy-post-dialog').modal({
          showClose: true,
          fadeDuration: 300,
          escapeClose: false,
          clickClose: false
        });
        var that = this;

        if (this.postBuyDialog) {
          (0, _jquery.default)(this.postBuyDialog[0]).remove();
        }

        this.postBuyDialog = dialog;
        setTimeout(function () {
          (0, _jquery.default)('#buying-price').focus();
          that.send('buyContentChangeAmount');
        }, 500);
        setTimeout(function () {
          (0, _jquery.default)('.close-modal ').click(function () {
            that.set('purchasePostObject', {});
          });
        }, 1000);
        return false;
      },

      buyPostContent() {
        (0, _jquery.default)('#buying-message').text('');
        var that = this; // Allow brand to offer multiple times
        // if(this.buyContent["status"] == true)
        //     return;

        if (!(0, _jquery.default)('#buying-price').val() || parseFloat((0, _jquery.default)('#buying-price').val()) < 250) {
          var currentLang = that.lang;

          if (currentLang == 'it') {
            (0, _jquery.default)('#buying-message').text('È richiesto un importo minimo di 250 €!');
          } else {
            (0, _jquery.default)('#buying-message').text('A minimum amount of 250€ required!');
          }

          return;
        }

        var available_funds = this.get('session.data.brand_funds');
        var buy_amount = this.get('buy_total');
        var offer_amount = (0, _jquery.default)('#buying-price').val();

        if (buy_amount > available_funds) {
          (0, _jquery.default)('#buy-post-dialog .close-modal').click();
          this.get('applicationController').send('onInsufficientWalletClick', parseFloat(buy_amount).toFixed(2), function (ok) {
            if (ok) {
              that.send('onPostBuyContentShow');
              setTimeout(function () {
                (0, _jquery.default)('#buying-price').val(offer_amount);
                that.send('buyContentChangeAmount');
                that.send('buyPostContent');
              }, 1000);
            }
          });
          return;
        }

        (0, _jquery.default)('#buy-content-btn').removeClass('loading');
        (0, _jquery.default)('#buy-content-btn').removeClass('finish-loading');
        (0, _jquery.default)('#buy-content-btn').addClass('loading');
        var post = this.postsByIds[this.post];
        var data = 'offer=' + (0, _jquery.default)('#buying-price').val() + '&campaign_post_id=' + this.post; // var userauth = "Basic "+btoa(this.get("session.data.username") + ":" + this.get("session.data.password"));

        var cred = this.get('session.data.authenticated.token_type') + ' ' + this.get('session.data.authenticated.access_token');

        _jquery.default.ajax({
          type: 'POST',
          url: _environment.default.APP.API_HOST + '/updatecontentrights?' + data,
          data: {},
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', cred);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            (0, _jquery.default)('#buy-post-dialog .close-modal').click();
            that.get('notify').alert({
              html: '<div>Offer submitted successfully!</div>'
            });
            setTimeout(function () {
              (0, _jquery.default)('#buy-content-btn').removeClass('loading');
              (0, _jquery.default)('#buy-content-btn').addClass('finish-loading');
              that.buyContent['status'] = true;
            }, 1000);
            that.store.query('brand', {}).then(function (data) {
              if (data.content.length > 0) {
                var obj = data.get('firstObject');
                that.get('session').set('data.brand_id', obj.id);
                that.get('session').set('data.brand_company', obj.company);
                that.get('session').set('data.brand_funds', obj.funds);
                that.get('session').set('data.funds', that.abbreviate_number(obj.funds, 1));
              }
            });
          },
          error: function (jqXHR, exception) {
            that.get('notify').warning({
              html: '<div>Offer request failed!</div>'
            });
            (0, _jquery.default)('#buy-content-btn').removeClass('loading');
            (0, _jquery.default)('#buy-content-btn').removeClass('finish-loading');
            that.buyContent['status'] = false;
          }
        });
      },

      refreshOnStatusChange() {
        var obj = null,
            campaignList = [],
            postList = [];
        var postsByIds = {},
            campObj = null,
            postObj = null,
            currentSpend = 0,
            reach = 0;
        var p = 0,
            s = 0,
            d = 0,
            a = 0;
        var posts = this.get('posts');
        this.set('totalPending_updated', false);
        this.set('totalApproved_updated', false);
        this.set('totalSubmitted_updated', false);
        this.set('totalDeclined_updated', false);

        for (var i = 0; i < posts.length; i++) {
          obj = posts[i];

          if (obj.status == 'approved') {
            a++;

            if (!obj.seen) {
              this.set('totalApproved_updated', true);
            }
          }

          if (obj.status == 'published') {
            s++;

            if (!obj.seen) {
              this.set('totalSubmitted_updated', true);
            }
          }

          if (obj.status == 'pending') {
            p++;

            if (!obj.seen) {
              this.set('totalPending_updated', true);
            }
          }

          if (obj.status == 'declined') {
            d++;

            if (!obj.seen) {
              this.set('totalDeclined_updated', true);
            }
          }

          if (obj.status == 'approved' || obj.status == 'published' || obj.status == 'pending') {
            currentSpend = currentSpend + parseInt(obj.post_price);
            reach = reach + obj.social_account_info.followers;
          }

          if (obj.status == 'approved') {
            currentSpend = currentSpend + parseInt(obj.post_price); // reach = reach + obj.social_account_info.followers;
          }
        }

        var n_tp = this.abbreviate_number(p, 0);
        var n_ta = this.abbreviate_number(a, 0);
        var n_ts = this.abbreviate_number(s, 0);
        var n_td = this.abbreviate_number(d, 0);
        this.set('totalPending', n_tp);
        this.set('totalApproved', n_ta);
        this.set('totalSubmitted', n_ts);
        this.set('totalDeclined', n_td);
        this.set('current_spend', this.abbreviate_number(currentSpend, 1));
        this.set('current_spent', this.abbreviate_number(currentSpend, 1));
        this.set('potential_reach', this.abbreviate_number(reach, 2));
        this.set('data.posts_accepted', this.abbreviate_number(a, 0));
      },

      buyContentChangeAmount() {
        const iva_charges = this.get('iva_charges');
        var v = (0, _jquery.default)('#buying-price').val().replace(/[^0-9]/g, '');
        (0, _jquery.default)('#buying-price').val(v);

        if (!v) {
          v = 0;
        }

        var amount = parseFloat(v);
        var sf = amount * 0.35;
        var st = sf + amount;
        var ivaf = amount * iva_charges;
        var t = amount + ivaf;
        this.set('buy_serviceFee', sf.toFixed(2));
        this.set('buy_subtotal', st.toFixed(2));
        this.set('buy_ivaFee', ivaf.toFixed(2));
        this.set('buy_total', t.toFixed(2));
      },

      onPurchaseContentClick(id, e) {
        e.preventDefault();
        e.stopPropagation();
        this.set('post', id);
        this.send('onPostBuyContentShow');
      },

      onDeclineReasonChange(e) {
        var reason = e.target.value;

        if (reason.length < 10) {
          reason = '';
        }

        (0, _jquery.default)('#post_decline_reason').val(reason);
      },

      onShowContactBrand() {
        this.set('contact_brand_form', true);
      },

      onHideContactBrand() {
        this.set('contact_brand_form', false);
      },

      onCloseContactBrand(keepData) {
        (0, _jquery.default)('.contact-brand-container').hide();

        if (!keepData) {
          this.set('total_third_party_posts_cost', 0.0);
          var contactBrandPosts = this.get('contactBrandPosts');

          for (var i = 0; i < contactBrandPosts.length; i++) {
            this.postsByIds[contactBrandPosts[i].get('data_id')].set('third_party_selection_ebabled', false);
          }

          this.set('contactBrandPosts', []);
          (0, _jquery.default)('.contact-brand-email').val('');
        }
      },

      onViewContactBrand() {
        (0, _jquery.default)('.contact-brand-container').show();
      },

      onChangePostPrice(post, val) {
        var third_party_post_price = parseFloat(val),
            that = this;
        var third_party_ft_fee = third_party_post_price;
        var third_party_iva_fee = 0;
        var third_party_post_price_total = third_party_ft_fee + third_party_iva_fee;
        post.set('third_party_post_price', third_party_post_price.toFixed(2));
        post.set('third_party_post_price_total', third_party_post_price.toFixed(2));
        setTimeout(function () {
          that.send('updateTotalPostsCost');
        }, 1000);
      },

      onSelectPost(id, toggleVal) {
        var post_obj = this.postsByIds[id],
            that = this;
        post_obj.set('third_party_selection_ebabled', toggleVal);
        var contactBrandPosts = this.get('contactBrandPosts');

        if (toggleVal) {
          contactBrandPosts.pushObject(post_obj);
        } else {
          contactBrandPosts.popObject(post_obj);
        }

        this.set('contactBrandPosts', contactBrandPosts);
        setTimeout(function () {
          that.send('updateTotalPostsCost');
        }, 1000);
      },

      updateTotalPostsCost() {
        var contactBrandPosts = this.get('contactBrandPosts');
        var total_third_party_posts_cost = 0;

        for (var i = 0; i < contactBrandPosts.length; i++) {
          total_third_party_posts_cost += parseFloat(contactBrandPosts[i].get('third_party_post_price_total'));
        }

        this.set('total_third_party_posts_cost', total_third_party_posts_cost.toFixed(2));
      },

      onContactBrand() {
        const show_price = (0, _jquery.default)('#send_post_price').is(':checked'),
              that = this;
        const emails = (0, _jquery.default)('.contact-brand-email').get().map(el => el.value).filter(el => el != '');
        const cred = this.get('session.data.authenticated.token_type') + ' ' + this.get('session.data.authenticated.access_token');
        const url = _environment.default.APP.API_HOST + '/brandclients';
        const postsData = [];
        const contactBrandPosts = this.get('contactBrandPosts');

        if (contactBrandPosts.length == 0) {
          return;
        }

        that.set('loading_contact_brand_form', true);

        for (var i = 0; i < contactBrandPosts.length; i++) {
          postsData.push({
            id: parseInt(contactBrandPosts[i].get('data_id')),
            price: parseFloat(contactBrandPosts[i].get('third_party_post_price'))
          });
        }

        var data = {
          brandClients: {
            campaign: this.get('id'),
            brand: this.get('session.data.brand_id'),
            posts: postsData,
            client_emails: emails,
            show_price: show_price,
            is_agency: this.is_agency //TODO: TBD and refactored for other tenants

          }
        };

        _jquery.default.ajax({
          type: 'POST',
          url: url,
          data: JSON.stringify(data),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', cred);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            that.set('loading_contact_brand_form', false);
            that.get('notify').alert({
              html: '<div>I post selezionati sono stati inviati con successo!</div>'
            });
            that.send('onCloseContactBrand', false);
          },
          error: function (jqXHR, exception) {
            that.set('loading_contact_brand_form', false);
            that.get('notify').warning({
              html: '<div>Richiesta fallita!</div>'
            });
            that.send('onCloseContactBrand', true);
          }
        });
      }

    }
  });

  _exports.default = _default;
});