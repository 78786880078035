define("flytrendy/utils/prevent-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = preventDefault;

  function preventDefault(event) {
    event.preventDefault();
    event.returnValue = '';
  }
});