define("flytrendy/controllers/brand", ["exports", "flytrendy/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    observationDialog: null,
    currentProgress: 0,
    src: '',
    process: ['assets/brand/1.png', 'assets/brand/2.png', 'assets/brand/3.png', 'assets/brand/4.png', 'assets/brand/5.png', 'assets/brand/6.png'],
    init: function () {
      this._super(...arguments);
    },
    actions: {
      newCampaignTop() {
        dataLayer.push({
          event: 'asyncAction',
          gaHitType: 'event',
          event_category: 'new campaign',
          event_action: 'click start for free button on top under header',
          event_label: 'brand_page'
        });
        this.transitionToRoute('newcampaign');
      },

      newCampaignBtm() {
        dataLayer.push({
          event: 'asyncAction',
          gaHitType: 'event',
          event_category: 'new campaign',
          event_action: 'click start for free button in footer',
          event_label: 'brand_page'
        });
        this.transitionToRoute('newcampaign');
      },

      onVideoClick() {
        var dialog = (0, _jquery.default)('#brand-vid-dialog').modal({
          showClose: true,
          fadeDuration: 300,
          escapeClose: false,
          clickClose: false
        });

        if (this.observationDialog) {
          (0, _jquery.default)(this.observationDialog[0]).remove();
        }

        this.observationDialog = dialog;

        if (localStorage.getItem('lang') == 'en') {
          this.set('src', 'http://flytrendy.com/videos/brand_eng.mp4');
        } else {
          this.set('src', 'http://flytrendy.com/videos/brands.mp4');
        }

        var that = this;
        setTimeout(function () {
          (0, _jquery.default)('.close-modal ').click(function () {
            that.set('src', '');
          });

          if (that.get('applicationController.isMobileView')) {
            (0, _jquery.default)('#brand-vid-dialog .vjs-big-play-button').click();
            (0, _jquery.default)('#brand-vid-dialog .vjs-fullscreen-control').click();
          }
        }, 1000);
        return false;
      },

      hover(index, e) {
        (0, _jquery.default)('#brand-process').attr('src', this.get('process')[index]);
      },

      progressPrev() {
        var index = this.get('currentProgress');
        if (index == 0) return;
        index = index - 1;
        (0, _jquery.default)('#brand-process').attr('src', this.get('process')[index]);
        this.set('currentProgress', index);
      },

      progressNext() {
        var index = this.get('currentProgress');
        if (index == 5) return;
        index = index + 1;
        (0, _jquery.default)('#brand-process').attr('src', this.get('process')[index]);
        this.set('currentProgress', index);
      }

    }
  });

  _exports.default = _default;
});