define("flytrendy/controllers/draftcampaign", ["exports", "jquery", "flytrendy/models/objects/newcampaign", "flytrendy/models/objects/countries", "flytrendy/config/environment", "flytrendy/helpers/t"], function (_exports, _jquery, _newcampaign, _countries, _environment, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    queryParams: ['id'],
    id: null,
    active_tab: 1,
    brief_tabl: true,
    campaign_tabl: false,
    influencers_tabl: false,
    review_tabl: false,
    submit_tabl: false,
    title: 'Campaign',
    campaign: null,
    countries: null,
    observationDialog: null,
    mood_gallery: [],
    compressor: null,
    autoRedirect: false,
    tt_1: _t.default.compute("nc_tt_1"),
    tt_2: _t.default.compute("nc_tt_2"),
    tt_3: _t.default.compute("nc_tt_3"),
    tt_4: _t.default.compute("nc_tt_4"),
    tt_5: _t.default.compute("nc_tt_5"),
    tt_6: _t.default.compute("nc_tt_6"),
    tt_7: _t.default.compute("nc_tt_7"),
    tt_8: _t.default.compute("nc_tt_8"),
    tt_9: _t.default.compute("nc_tt_9"),
    tt_10: _t.default.compute("nc_tt_10"),
    tt_11: _t.default.compute("nc_tt_11"),
    ad_text: _t.default.compute("ad_text"),
    isCroppedHeroImageActived: false,
    isCroppedLogoImageActived: false,
    init: function () {
      this.active_tab = 1;
      this.set("campaign", _newcampaign.default.create({}));
      this.set("countries", _countries.default.create({}));
    },
    saveDataLocal: function () {
      var campaignlist = JSON.parse(localStorage.getItem("campaignlist"));
      campaignlist[this.id] = JSON.stringify(this.get("campaign"));
      localStorage.setItem("campaignlist", JSON.stringify(campaignlist));
    },
    deleteLocalData: function () {
      var campaignlist = JSON.parse(localStorage.getItem("campaignlist"));
      delete campaignlist[this.id];
      localStorage.setItem("campaignlist", JSON.stringify(campaignlist));
    },
    actions: {
      onLoading() {
        this.active_tab = 1;
        this.send("setActiveTab"); // this.compressor = new Compress();

        this.compressor = new ImageCompressor();
        this.set("autoRedirect", false);
        var campaignlist = JSON.parse(localStorage.getItem("campaignlist"));

        if (campaignlist && campaignlist[this.id]) {
          this.set("campaign", _newcampaign.default.create(JSON.parse(campaignlist[this.id])));
        } else {
          this.set("campaign", _newcampaign.default.create({}));
        }

        var that = this;
        setTimeout(function () {
          if (that.get("session.isAuthenticated")) {
            that.set("campaign.brand_name", that.get('session.data.brand_company'));
          }
        }, 500);
      },

      onGenderChange(gender) {
        if (gender == 1) {
          (0, _jquery.default)(".briefcompaign .influencers-cont .gender-cont .male").toggleClass("selected");
        }

        if (gender == 2) {
          (0, _jquery.default)(".briefcompaign .influencers-cont .gender-cont .female").toggleClass("selected");
        }

        if (gender == 3) {
          (0, _jquery.default)(".briefcompaign .influencers-cont .gender-cont button").removeClass("selected");
          (0, _jquery.default)(".briefcompaign .influencers-cont .gender-cont .male").addClass("selected");
          (0, _jquery.default)(".briefcompaign .influencers-cont .gender-cont .female").addClass("selected");
        }

        if (gender == 4) {
          (0, _jquery.default)(".briefcompaign .influencers-cont .gender-cont button").removeClass("selected");
        }

        var m = (0, _jquery.default)(".briefcompaign .influencers-cont .gender-cont .male").hasClass("selected");
        var f = (0, _jquery.default)(".briefcompaign .influencers-cont .gender-cont .female").hasClass("selected");

        if (m && f) {
          this.set("campaign.gender", "all");
        } else if (m && !f) {
          this.set("campaign.gender", "male");
        } else if (!m && f) {
          this.set("campaign.gender", "female");
        } else {
          this.set("campaign.gender", "");
        }
      },

      onPublishChange(pub) {
        (0, _jquery.default)(".briefcompaign .submit-cont button").removeClass("selected");

        if (pub == 1) {
          (0, _jquery.default)(".briefcompaign .submit-cont .now").addClass("selected");
          this.set("campaign.publish_status", "now");
          (0, _jquery.default)(".briefcompaign .submit-cont .later-date").hide();
        } else {
          (0, _jquery.default)(".briefcompaign .submit-cont .later").addClass("selected");
          this.set("campaign.publish_status", "later");
          (0, _jquery.default)(".briefcompaign .submit-cont .later-date").show();
        }
      },

      next() {
        this.send("saveTempData", this.active_tab, true);
      },

      previous() {
        this.send("saveTempData", this.active_tab, false);
      },

      saveTempData(tab, validate) {
        // tab is current step
        // this function is called prior to next/prev tab to save data.
        (0, _jquery.default)('html,body').scrollTop(0);

        if (tab == 1) {
          if (validate) {
            var ok = true;
            (0, _jquery.default)("#brand_comp_name").parent().removeClass("info-required");

            if (!this.get("session.isAuthenticated") && !(0, _jquery.default)("#brand_comp_name").val()) {
              (0, _jquery.default)("#brand_comp_name").parent().addClass("info-required");
              ok = false;
            }

            (0, _jquery.default)("#pro_comp_name").parent().removeClass("info-required");

            if (!(0, _jquery.default)("#pro_comp_name").val()) {
              (0, _jquery.default)("#pro_comp_name").parent().addClass("info-required");
              ok = false;
            }

            (0, _jquery.default)("#pro_desc").parent().removeClass("info-required");

            if (!(0, _jquery.default)("#pro_desc").val()) {
              (0, _jquery.default)("#pro_desc").parent().addClass("info-required");
              ok = false;
            }

            (0, _jquery.default)("#campaign_link").parent().removeClass("info-required");

            if (!(0, _jquery.default)("#campaign_link").val()) {
              (0, _jquery.default)("#campaign_link").parent().addClass("info-required");
              ok = false;
            }

            (0, _jquery.default)("#where_purchase").parent().removeClass("info-required");

            if (!(0, _jquery.default)("#where_purchase").val()) {
              (0, _jquery.default)("#where_purchase").parent().addClass("info-required");
              ok = false;
            }

            (0, _jquery.default)(".hero-image-cont").removeClass("info-required");

            if (!this.get("campaign.hero_image") || this.get("isCroppedHeroImageActived")) {
              (0, _jquery.default)(".hero-image-cont").addClass("info-required");
              ok = false;
            }

            (0, _jquery.default)(".logo-image-cont").removeClass("info-required");

            if (!this.get("campaign.logo") || this.get("isCroppedLogoImageActived")) {
              (0, _jquery.default)(".logo-image-cont").addClass("info-required");
              ok = false;
            }

            if (!ok) return;
          }

          if (this.get("session.isAuthenticated")) {
            this.set("campaign.brand_name", this.get('session.data.brand_company'));
          } else {
            this.set("campaign.brand_name", (0, _jquery.default)("#brand_comp_name").val());
          }

          this.set("campaign.campaign_name", (0, _jquery.default)("#pro_comp_name").val());
          this.set("campaign.product_description", (0, _jquery.default)("#pro_desc").val());
          this.set("campaign.link", (0, _jquery.default)("#campaign_link").val());
          this.set("campaign.where_to_purchase", (0, _jquery.default)("#where_purchase").val());
        } else if (tab == 2) {
          var instagram_tags = "",
              twitter_tags = "",
              facebook_tags = "";
          var dos = [],
              donts = [];

          if (validate) {
            var ok = true;
            (0, _jquery.default)("#content_love").parent().removeClass("info-required");

            if (!(0, _jquery.default)("#content_love").val()) {
              (0, _jquery.default)("#content_love").parent().addClass("info-required");
              ok = false;
            }

            (0, _jquery.default)("#call_action").parent().removeClass("info-required");

            if (!(0, _jquery.default)("#call_action").val()) {
              (0, _jquery.default)("#call_action").parent().addClass("info-required");
              ok = false;
            }

            (0, _jquery.default)("#pre_tags_instagram").parent().removeClass("info-required");

            if (this.get("campaign.instagram") && !(0, _jquery.default)("#pre_tags_instagram").val()) {
              (0, _jquery.default)("#pre_tags_instagram").parent().addClass("info-required");
              ok = false;
            }

            (0, _jquery.default)("#pre_tags_twitter").parent().removeClass("info-required");

            if (this.get("campaign.twitter") && !(0, _jquery.default)("#pre_tags_twitter").val()) {
              (0, _jquery.default)("#pre_tags_twitter").parent().addClass("info-required");
              ok = false;
            }

            (0, _jquery.default)("#pre_tags_facebook").parent().removeClass("info-required");

            if (this.get("campaign.facebook") && !(0, _jquery.default)("#pre_tags_facebook").val()) {
              (0, _jquery.default)("#pre_tags_facebook").parent().addClass("info-required");
              ok = false;
            }

            (0, _jquery.default)(".platform-cont").removeClass("info-required");

            if (!this.get("campaign.facebook") && !this.get("campaign.instagram") && !this.get("campaign.twitter")) {
              (0, _jquery.default)(".platform-cont").addClass("info-required");
              ok = false;
            }

            if (!ok) return;
          }

          this.set("campaign.content_we_love", (0, _jquery.default)("#content_love").val());
          this.set("campaign.call_to_action", (0, _jquery.default)("#call_action").val());

          if (this.get("campaign.instagram")) {
            instagram_tags = (0, _jquery.default)("#pre_tags_instagram").val();
          }

          if (this.get("campaign.twitter")) {
            twitter_tags = (0, _jquery.default)("#pre_tags_twitter").val();
          }

          if (this.get("campaign.facebook")) {
            facebook_tags = (0, _jquery.default)("#pre_tags_facebook").val();
          }

          this.set("campaign.instagram_tags", instagram_tags);
          this.set("campaign.twitter_tags", twitter_tags);
          this.set("campaign.facebook_tags", facebook_tags);
          this.set("campaign.instagram_tags_list", instagram_tags.split(" "));
          this.set("campaign.twitter_tags_list", twitter_tags.split(" "));
          this.set("campaign.facebook_tags_list", facebook_tags.split(" "));
          (0, _jquery.default)("#dos_list li").each(function () {
            dos.push((0, _jquery.default)((0, _jquery.default)(this)[0].firstChild).text());
          });
          (0, _jquery.default)("#donts_list li").each(function () {
            donts.push((0, _jquery.default)((0, _jquery.default)(this)[0].firstChild).text());
          });
          this.set("campaign.dos", dos);
          this.set("campaign.donts", donts);
        } else if (tab == 3) {
          var regions = [],
              list = [];
          (0, _jquery.default)(".briefcompaign .influencers-cont .demographic-cont .region-cont span").each(function (index) {
            if ((0, _jquery.default)(this).hasClass("selected")) {
              regions.push(index);
            }
          });
          this.set("campaign.target_regions", regions);
          (0, _jquery.default)(".briefcompaign .influencers-cont .demographic-cont .interest div").each(function (index) {
            if ((0, _jquery.default)(this).hasClass("selected")) {
              list.push((0, _jquery.default)(this).text());
            }
          });
          this.set("campaign.interests", list);

          if (validate) {
            var ok = true;
            (0, _jquery.default)(".region-list-cont").removeClass("info-required");

            if (this.get("campaign.target_regions").length == 0) {
              (0, _jquery.default)(".region-list-cont").addClass("info-required");
              ok = false;
            }

            (0, _jquery.default)(".interest-list-cont").removeClass("info-required");

            if (this.get("campaign.interests").length == 0) {
              (0, _jquery.default)(".interest-list-cont").addClass("info-required");
              ok = false;
            }

            var g = this.get("campaign.gender");
            (0, _jquery.default)(".gender-cont").parent().removeClass("info-required");

            if (!g || g == "") {
              (0, _jquery.default)(".gender-cont").parent().addClass("info-required");
              ok = false;
            }

            if (!ok) return;
          }
        } else if (tab == 4) {// review tab
        } else if (tab == 5) {
          //submit tab
          this.set("campaign.first_name", (0, _jquery.default)("#firstname").val());
          this.set("campaign.last_name", (0, _jquery.default)("#lastname").val());

          if (!this.get("session.isAuthenticated")) {
            this.set("campaign.campaign_name", (0, _jquery.default)("#companyname").val());
          }

          this.set("campaign.email", (0, _jquery.default)("#email").val());
          this.set("campaign.ph", (0, _jquery.default)("#phone").val());
          this.set("campaign.mob", (0, _jquery.default)("#mobile").val());
          this.set("campaign.pass", (0, _jquery.default)("#password").val());
          this.set("campaign.c_pass", (0, _jquery.default)("#passwordconfirm").val());
          this.set("campaign.address", (0, _jquery.default)("#streetaddress").val());
          this.set("campaign.city", (0, _jquery.default)("#city").val());
          this.set("campaign.state", (0, _jquery.default)("#state").val());
          this.set("campaign.postcode", (0, _jquery.default)("#postcode").val());
          this.set("campaign.country", (0, _jquery.default)("#country").val());
          this.set("campaign.iva", (0, _jquery.default)("#iva").val());
          this.set("campaign.response_time", "2 hours");
          this.set("campaign.publish_date", (0, _jquery.default)("#publishdate").val());
          this.set("campaign.until_date", (0, _jquery.default)("#publishuntil").val());
          this.set("campaign.agreement", (0, _jquery.default)("#brandchecked").prop("checked"));
          this.set("campaign.agreement_tc", (0, _jquery.default)("#brandchecked_tc").prop("checked"));
        }

        if (validate) {
          this.active_tab++;
          this.send("setActiveTab");
        } else {
          this.active_tab--;
          this.send("setActiveTab");
        }

        this.saveDataLocal();
      },

      togglePlatform(op) {
        if (op == 1) {
          (0, _jquery.default)(".briefcompaign .platform-cont .instagram").toggleClass("instagram-selected");
          this.set("campaign.instagram", false);

          if ((0, _jquery.default)(".briefcompaign .platform-cont .instagram").hasClass("instagram-selected")) {
            this.set("campaign.instagram", true);
          }
        } else if (op == 2) {
          (0, _jquery.default)(".briefcompaign .platform-cont .twitter").toggleClass("twitter-selected");
          this.set("campaign.twitter", false);

          if ((0, _jquery.default)(".briefcompaign .platform-cont .twitter").hasClass("twitter-selected")) {
            this.set("campaign.twitter", true);
          }
        } else if (op == 3) {
          (0, _jquery.default)(".briefcompaign .platform-cont .facebook").toggleClass("facebook-selected");
          this.set("campaign.facebook", false);

          if ((0, _jquery.default)(".briefcompaign .platform-cont .facebook").hasClass("facebook-selected")) {
            this.set("campaign.facebook", true);
          }
        }

        (0, _jquery.default)(".platform-cont").removeClass("info-required");
      },

      // onSaveCroppedHeroImage(){
      //     var that = this;
      //     var cropped = jQuery("#hero_image_cropped").cropper('getCroppedCanvas');
      //     that.set("campaign.hero_image", cropped.toDataURL());
      //     jQuery("#new_hero_image_cont").show();
      //     jQuery("#crop_hero_image_cont").hide();
      //     jQuery("#crop_hero_image_subcont").text("");
      // },
      // onSaveCroppedLogoImage(){
      //     var that = this;
      //     var cropped = jQuery("#logo_image_cropped").cropper('getCroppedCanvas');
      //     that.set("campaign.logo", cropped.toDataURL());
      //     jQuery("#new_logo_image_cont").show();
      //     jQuery("#crop_logo_image_cont").hide();
      //     jQuery("#crop_logo_image_subcont").text("");
      // },
      onSaveCroppedHeroImage() {
        (0, _jquery.default)(".hero-image-cont .processing").show();
        var that = this;
        var cropped = (0, _jquery.default)("#hero_image_cropped").cropper('getCroppedCanvas');
        setTimeout(function () {
          that.set("campaign.hero_image", cropped.toDataURL());
          (0, _jquery.default)("#new_hero_image_cont").show();
          (0, _jquery.default)("#crop_hero_image_cont").hide();
          (0, _jquery.default)("#crop_hero_image_subcont").text("");
          fetch(cropped.toDataURL('image/png')).then(res => res.blob()).then(blob => {
            const file = new File([blob], "heroimage.png");
            that.compressor.compress(blob, {
              quality: 0.6,
              maxWidth: 450
            }).then(results => {
              const reader = new FileReader();
              var base64DataURL;

              reader.onload = e => {
                base64DataURL = e.target.result;
                that.set("campaign.hero_image", base64DataURL);
                that.set("isCroppedHeroImageActived", false);
                (0, _jquery.default)(".hero-image-cont").removeClass("info-required");
                (0, _jquery.default)(".hero-image-cont .processing").hide();
              };

              reader.readAsDataURL(results);
            });
          });
        }, 1000);
      },

      onSaveCroppedLogoImage() {
        (0, _jquery.default)(".logo-image-cont .processing").show();
        var that = this;
        var cropped = (0, _jquery.default)("#logo_image_cropped").cropper('getCroppedCanvas');
        setTimeout(function () {
          (0, _jquery.default)(".logo-image-cont .processing").css({
            "height": "300px",
            "margin-top": "-300px"
          });
          that.set("campaign.logo", cropped.toDataURL());
          (0, _jquery.default)("#new_logo_image_cont").show();
          (0, _jquery.default)("#crop_logo_image_cont").hide();
          (0, _jquery.default)("#crop_logo_image_subcont").text("");
          fetch(cropped.toDataURL('image/png')).then(res => res.blob()).then(blob => {
            const file = new File([blob], "logo.png");
            that.compressor.compress(blob, {
              quality: 0.6,
              maxWidth: 450
            }).then(results => {
              const reader = new FileReader();
              var base64DataURL;

              reader.onload = e => {
                base64DataURL = e.target.result;
                that.set("campaign.logo", base64DataURL);
                that.set("isCroppedLogoImageActived", false);
                (0, _jquery.default)(".logo-image-cont").removeClass("info-required");
                (0, _jquery.default)(".logo-image-cont .processing").hide();
              };

              reader.readAsDataURL(results);
            });
          });
        }, 1000);
      },

      setActiveTab() {
        this.set("brief_tabl", false);
        this.set("campaign_tabl", false);
        this.set("influencers_tabl", false);
        this.set("review_tabl", false);
        this.set("submit_tabl", false);
        (0, _jquery.default)(".briefcompaign .option-list .col-md-2").removeClass("selected");

        switch (this.active_tab) {
          case 1:
            this.set("campaign_tabl", true);
            (0, _jquery.default)(".campaign-tab").addClass("selected");
            this.set("title", "Create Campaign");
            var that = this;
            setTimeout(function () {
              (0, _jquery.default)(".hero-image-cont .processing").hide();
              var heroImage = document.getElementById('hero_input_1');
              heroImage.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)(".hero-image-cont .processing").show();
                that.compressor.compress(file, {
                  quality: .8
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    that.set("campaign.hero_image", base64DataURL);
                    (0, _jquery.default)("#new_hero_image_cont").hide();
                    (0, _jquery.default)("#crop_hero_image_cont").show();
                    (0, _jquery.default)("#crop_hero_image_subcont").text("");
                    (0, _jquery.default)("#crop_hero_image_subcont").append('<div><img id="hero_image_cropped" style="max-width: 100%;" src="' + base64DataURL + '"></div>');
                    that.set("isCroppedHeroImageActived", true);
                    (0, _jquery.default)(".hero-image-cont .processing").hide();
                    (0, _jquery.default)('#hero_image_cropped').cropper({
                      aspectRatio: 16 / 9,
                      zoomable: false,
                      crop: function (e) {}
                    });
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              (0, _jquery.default)(".logo-image-cont .processing").hide();
              var logo = document.getElementById('hero_input_2');
              logo.addEventListener('change', function (evt) {
                console.log("hero_input_2 change");
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)(".logo-image-cont .processing").css({
                  "height": "300px",
                  "margin-top": "-300px"
                });
                (0, _jquery.default)(".logo-image-cont .processing").show();
                that.compressor.compress(file, {
                  quality: .8
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    that.set("campaign.logo", base64DataURL);
                    (0, _jquery.default)("#crop_logo_image_subcont").text("");
                    var img = new Image();

                    img.onload = function () {
                      var w = this.width,
                          h = this.height;
                      var size = w;
                      (0, _jquery.default)("#new_logo_image_cont").hide();
                      (0, _jquery.default)("#crop_logo_image_cont").show();

                      if (w > h) {
                        size = w;
                      } else if (h > w) {
                        size = h;
                      }

                      size = size + "px";
                      (0, _jquery.default)("#crop_logo_image_subcont").append('<div class="logo-crop-main-cont"><img id="logo_image_cropped" style="max-width: 100%;" src="' + base64DataURL + '"></div>');
                      that.set("isCroppedLogoImageActived", true);
                      (0, _jquery.default)(".logo-image-cont .processing").css({
                        "height": "564px",
                        "margin-top": "-564px"
                      });
                      (0, _jquery.default)(".logo-image-cont .processing").hide();
                      (0, _jquery.default)('#logo_image_cropped').cropper({
                        aspectRatio: 10 / 10,
                        zoomable: false,
                        crop: function (e) {}
                      });
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false); // jQuery("#hero_input_1").change(function(e){
              //     var img = jQuery(e.target);
              //     var file = document.getElementById(img.attr("id")).files[0];
              //     var reader = new FileReader();
              //     reader.onloadend = function(){
              //         that.set("campaign.hero_image", reader.result);
              //     }
              //     if(file){
              //         reader.readAsDataURL(file);
              //     }
              // });
              // jQuery("#hero_input_2").change(function(e){
              //     var img = jQuery(e.target);
              //     var file = document.getElementById(img.attr("id")).files[0];
              //     var reader = new FileReader();
              //     reader.onloadend = function(){
              //         that.set("campaign.logo", reader.result);
              //     }
              //     if(file){
              //         reader.readAsDataURL(file);
              //     }
              // });
            }, 100);
            break;

          case 2:
            this.set("brief_tabl", true);
            (0, _jquery.default)(".brief-tab").addClass("selected");
            this.set("title", "Brief for Campaign");
            var that = this;
            setTimeout(function () {
              (0, _jquery.default)(".mood-board .mood-processing").hide();
              var mood1 = document.getElementById('file_input_1');
              mood1.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)(".mood-board .mood-processing").show();
                that.compressor.compress(file, {
                  quality: .2
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)(".mood-board .mood-processing").hide();
                    that.set("campaign.file_input_1_label", base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set("campaign.file_input_1_label_w", this.width);
                      that.set("campaign.file_input_1_label_h", this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood2 = document.getElementById('file_input_2');
              mood2.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)(".mood-board .mood-processing").show();
                that.compressor.compress(file, {
                  quality: 0.6,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)(".mood-board .mood-processing").hide();
                    that.set("campaign.file_input_2_label", base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set("campaign.file_input_2_label_w", this.width);
                      that.set("campaign.file_input_2_label_h", this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood3 = document.getElementById('file_input_3');
              mood3.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)(".mood-board .mood-processing").show();
                that.compressor.compress(file, {
                  quality: 0.6,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)(".mood-board .mood-processing").hide();
                    that.set("campaign.file_input_3_label", base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set("campaign.file_input_3_label_w", this.width);
                      that.set("campaign.file_input_3_label_h", this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood4 = document.getElementById('file_input_4');
              mood4.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)(".mood-board .mood-processing").show();
                that.compressor.compress(file, {
                  quality: 0.6,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)(".mood-board .mood-processing").hide();
                    that.set("campaign.file_input_4_label", base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set("campaign.file_input_4_label_w", this.width);
                      that.set("campaign.file_input_4_label_h", this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood5 = document.getElementById('file_input_5');
              mood5.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)(".mood-board .mood-processing").show();
                that.compressor.compress(file, {
                  quality: 0.6,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)(".mood-board .mood-processing").hide();
                    that.set("campaign.file_input_5_label", base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set("campaign.file_input_5_label_w", this.width);
                      that.set("campaign.file_input_5_label_h", this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood6 = document.getElementById('file_input_6');
              mood6.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)(".mood-board .mood-processing").show();
                that.compressor.compress(file, {
                  quality: 0.6,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)(".mood-board .mood-processing").hide();
                    that.set("campaign.file_input_6_label", base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set("campaign.file_input_6_label_w", this.width);
                      that.set("campaign.file_input_6_label_h", this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false); // jQuery(".briefcompaign .brief-cont .mood-board .mood input").change(function(e){
              //     var img = jQuery(e.target);
              //     var label = img.attr("id")+"_label";
              //     var file = document.getElementById(img.attr("id")).files[0];
              //     var reader = new FileReader();
              //     reader.onloadend = function(){
              //         var dataKey = "campaign."+label;
              //         that.set(dataKey, reader.result);
              //         var img = new Image();
              //         img.onload = function () {
              //             that.set(dataKey+"_w", this.width);
              //             that.set(dataKey+"_h", this.height);
              //         };
              //         img.src = reader.result;
              //     }
              //     if(file){
              //         reader.readAsDataURL(file);
              //     }
              // });
            }, 100);
            break;

          case 3:
            this.set("influencers_tabl", true);
            (0, _jquery.default)(".influencers-tab").addClass("selected");
            this.set("title", "Influencers");
            var that = this;
            setTimeout(function () {
              var ageMinMax = [18, 43];

              if (that.get("campaign.age_min")) {
                ageMinMax[0] = that.get("campaign.age_min");
              }

              if (that.get("campaign.age_max")) {
                ageMinMax[1] = that.get("campaign.age_max");
              }

              that.set("campaign.age_min", ageMinMax[0]);
              that.set("campaign.age_max", ageMinMax[1]);

              if (that.get("campaign.gender") == "female") {
                that.send("onGenderChange", 2);
              } else if (that.get("campaign.gender") == "male") {
                that.send("onGenderChange", 1);
              } else if (that.get("campaign.gender") == "all") {
                that.send("onGenderChange", 3);
              } else {
                that.send("onGenderChange", 4);
              }

              var slider = document.getElementById('age-slider');
              noUiSlider.create(slider, {
                start: ageMinMax,
                connect: true,
                limit: 100,
                step: 1,
                tooltips: true,
                range: {
                  'min': 0,
                  'max': 100
                },
                behaviour: 'tap-drag',
                format: {
                  to: function (value) {
                    return value;
                  },
                  from: function (value) {
                    return value;
                  }
                }
              });
              slider.noUiSlider.on('change', function (val) {
                that.set("campaign.age_min", val[0]);
                that.set("campaign.age_max", val[1]);
              });
              (0, _jquery.default)(".briefcompaign .influencers-cont .demographic-cont .region-cont span").click(function (e) {
                (0, _jquery.default)(this).toggleClass("selected");
              });
              var regions = that.get("campaign.target_regions");

              if (regions && regions.length > 0) {
                for (var i = 0; i < regions.length; i++) {
                  (0, _jquery.default)((0, _jquery.default)(".briefcompaign .influencers-cont .demographic-cont .region-cont span").eq(regions[i])).addClass("selected");
                }
              }

              (0, _jquery.default)(".briefcompaign .influencers-cont .demographic-cont .interest div").click(function (e) {
                (0, _jquery.default)(this).toggleClass("selected");
              });
              var interests = that.get("campaign.interests");

              if (interests && interests.length > 0) {
                for (var i = 0; i < interests.length; i++) {
                  (0, _jquery.default)(".briefcompaign .influencers-cont .demographic-cont .interest div").filter(function () {
                    return (0, _jquery.default)(this).text() == interests[i];
                  }).addClass("selected");
                }
              }
            }, 100);
            break;

          case 4:
            this.set("review_tabl", true);
            (0, _jquery.default)(".review-tab").addClass("selected");
            this.set("title", "Review Campaign");
            var interests = this.get("campaign.interests");
            var list = "";

            if (interests && interests.length > 0) {
              for (var i = 0; i < interests.length; i++) {
                list += interests[i] + ", ";
              }
            }

            var regions = this.get("campaign.target_regions"),
                temp_string = "";

            if (regions && regions.length > 0) {
              for (var i = 0; i < regions.length; i++) {
                temp_string += this.get("countries.countries").get(regions[i]).country_name + ", ";
              }
            }

            this.set("campaign.interests_string", list);
            this.set("campaign.target_regions_string", temp_string);
            var gallery = [];

            if (this.get("campaign.file_input_1_label")) {
              gallery.push({
                src: this.get("campaign.file_input_1_label"),
                w: this.get("campaign.file_input_1_label_w"),
                h: this.get("campaign.file_input_1_label_h")
              });
            }

            if (this.get("campaign.file_input_2_label")) {
              gallery.push({
                src: this.get("campaign.file_input_2_label"),
                w: this.get("campaign.file_input_2_label_w"),
                h: this.get("campaign.file_input_2_label_h")
              });
            }

            if (this.get("campaign.file_input_3_label")) {
              gallery.push({
                src: this.get("campaign.file_input_3_label"),
                w: this.get("campaign.file_input_3_label_w"),
                h: this.get("campaign.file_input_3_label_h")
              });
            }

            if (this.get("campaign.file_input_4_label")) {
              gallery.push({
                src: this.get("campaign.file_input_4_label"),
                w: this.get("campaign.file_input_4_label_w"),
                h: this.get("campaign.file_input_4_label_h")
              });
            }

            if (this.get("campaign.file_input_5_label")) {
              gallery.push({
                src: this.get("campaign.file_input_5_label"),
                w: this.get("campaign.file_input_5_label_w"),
                h: this.get("campaign.file_input_5_label_h")
              });
            }

            if (this.get("campaign.file_input_6_label")) {
              gallery.push({
                src: this.get("campaign.file_input_6_label"),
                w: this.get("campaign.file_input_6_label_w"),
                h: this.get("campaign.file_input_6_label_h")
              });
            }

            this.set("mood_gallery", gallery);
            break;

          case 5:
            this.set("submit_tabl", true);
            (0, _jquery.default)(".submit-tab").addClass("selected");
            this.set("title", "Submit Campaign");
            var that = this;
            setTimeout(function () {
              if (that.get("campaign.publish_status") == "later") {
                that.send("onPublishChange", 2);
              } else {
                that.send("onPublishChange", 1);
              } // if(that.get("campaign.response_time")){
              //     jQuery("#responsetime").val(that.get("campaign.response_time"));
              // }


              (0, _jquery.default)("#brandchecked").change(function (e) {
                that.set("campaign.agreement", (0, _jquery.default)(e.target).is(':checked'));
              });
              (0, _jquery.default)("#brandchecked_tc").change(function (e) {
                that.set("campaign.agreement_tc", (0, _jquery.default)(e.target).is(':checked'));
              }); // jQuery( "#card_expiry" ).datepicker({
              //     changeMonth: true,
              //     changeYear: true,
              //     showButtonPanel: true,
              //     dateFormat: 'mm/yy',
              //     onClose: function(dateText, inst) { 
              //         jQuery(this).datepicker('setDate', new Date(inst.selectedYear, inst.selectedMonth, 1));
              //     }
              // });

              (0, _jquery.default)('#publishdate').datetimepicker({
                format: "d/m/y",
                timepicker: false,
                scrollInput: false,
                scrollMonth: false,
                minDate: '0'
              });
              (0, _jquery.default)('#publishdate').change(function () {
                var min_d = (0, _jquery.default)('#publishdate').val();
                min_d = moment(min_d, 'DD/MM/YY').format("YYYY/MM/DD");
                (0, _jquery.default)('#publishuntil').datetimepicker({
                  format: "d/m/y",
                  timepicker: false,
                  scrollInput: false,
                  scrollMonth: false,
                  minDate: min_d
                });
              });
              (0, _jquery.default)('#publishuntil').datetimepicker({
                format: "d/m/y",
                timepicker: false,
                scrollInput: false,
                scrollMonth: false,
                minDate: '0'
              });
              (0, _jquery.default)("#country").val(that.get("campaign.country"));
            }, 100);
            break;
        }
      },

      submit(op) {
        this.get('session').set('data.paynow', false);
        var that = this,
            ok = true;

        if (this.get("session.isAuthenticated")) {
          (0, _jquery.default)("#publishdate").parent().removeClass("info-required");

          if (this.get("campaign.publish") == "later") {
            if (!(0, _jquery.default)("#publishdate").val()) {
              (0, _jquery.default)("#publishdate").parent().addClass("info-required");
              ok = false;
            }
          }

          (0, _jquery.default)("#publishuntil").parent().removeClass("info-required");

          if (!(0, _jquery.default)("#publishuntil").val()) {
            (0, _jquery.default)("#publishuntil").parent().addClass("info-required");
            ok = false;
          }

          if (!ok) {
            return;
          }

          this.get("applicationController").send("showLoader");
          this.send("createNewCampaign", that.get('session.data.username'), that.get('session.data.brand_company'), function (campaign_id) {
            if (campaign_id) {
              that.get('session').set('data.paynow', true);
              that.set("autoRedirect", true);
              that.get("applicationController").send("submitAnimationDone", function () {
                setTimeout(function () {
                  that.get("applicationController").send("hideLoader");
                  that.transitionToRoute('mycampaign');
                }, 1000);
              });
              that.deleteLocalData();
            } else {
              that.get("applicationController").send("hideLoader");
            }
          });
        } else {
          (0, _jquery.default)("#email").parent().removeClass("info-required");

          if (!(0, _jquery.default)("#email").val()) {
            (0, _jquery.default)("#email").parent().addClass("info-required");
            ok = false;
          }

          (0, _jquery.default)("#password").parent().removeClass("info-required");

          if (!(0, _jquery.default)("#password").val()) {
            (0, _jquery.default)("#password").parent().addClass("info-required");
            ok = false;
          }

          (0, _jquery.default)("#passwordconfirm").parent().removeClass("info-required");

          if (!(0, _jquery.default)("#passwordconfirm").val()) {
            (0, _jquery.default)("#passwordconfirm").parent().addClass("info-required");
            ok = false;
          }

          (0, _jquery.default)("#firstname").parent().removeClass("info-required");

          if (!(0, _jquery.default)("#firstname").val()) {
            (0, _jquery.default)("#firstname").parent().addClass("info-required");
            ok = false;
          }

          (0, _jquery.default)("#lastname").parent().removeClass("info-required");

          if (!(0, _jquery.default)("#lastname").val()) {
            (0, _jquery.default)("#lastname").parent().addClass("info-required");
            ok = false;
          }

          (0, _jquery.default)("#mobile").parent().removeClass("info-required");

          if (!(0, _jquery.default)("#mobile").val()) {
            (0, _jquery.default)("#mobile").parent().addClass("info-required");
            ok = false;
          }

          (0, _jquery.default)("#streetaddress").parent().removeClass("info-required");

          if (!(0, _jquery.default)("#streetaddress").val()) {
            (0, _jquery.default)("#streetaddress").parent().addClass("info-required");
            ok = false;
          }

          (0, _jquery.default)("#city").parent().removeClass("info-required");

          if (!(0, _jquery.default)("#city").val()) {
            (0, _jquery.default)("#city").parent().addClass("info-required");
            ok = false;
          }

          (0, _jquery.default)("#state").parent().removeClass("info-required");

          if (!(0, _jquery.default)("#state").val()) {
            (0, _jquery.default)("#state").parent().addClass("info-required");
            ok = false;
          }

          (0, _jquery.default)("#postcode").parent().removeClass("info-required");

          if (!(0, _jquery.default)("#postcode").val()) {
            (0, _jquery.default)("#postcode").parent().addClass("info-required");
            ok = false;
          }

          (0, _jquery.default)("#iva").parent().removeClass("info-required");

          if (!(0, _jquery.default)("#iva").val() || (0, _jquery.default)("#iva").val().length != 11) {
            (0, _jquery.default)("#iva").parent().addClass("info-required");
            ok = false;
          }

          (0, _jquery.default)("#publishdate").parent().removeClass("info-required");

          if (this.get("campaign.publish") == "Later") {
            if (!(0, _jquery.default)("#publishdate").val()) {
              (0, _jquery.default)("#publishdate").parent().addClass("info-required");
              ok = false;
            }
          }

          (0, _jquery.default)("#publishuntil").parent().removeClass("info-required");

          if (!(0, _jquery.default)("#publishuntil").val()) {
            (0, _jquery.default)("#publishuntil").parent().addClass("info-required");
            ok = false;
          }

          if (!ok) {
            return;
          }

          this.get("applicationController").send("showLoader");
          this.send("createNewUser", function (user_id) {
            if (user_id) {
              that.send("createNewBrand", user_id, function (brand) {
                if (brand) {
                  that.send("createNewCampaign", (0, _jquery.default)("#email").val(), that.get("campaign.brand_name"), function (campaign_id) {
                    if (campaign_id) {
                      // loging in new user
                      var email = (0, _jquery.default)("#email").val(),
                          pass = (0, _jquery.default)("#password").val();
                      that.set("autoRedirect", true);
                      that.get('session').authenticate('authenticator:oauth2', email, pass).then(result => {
                        var user_token = that.get('session.data.authenticated.access_token');
                        that.get('session').set('data.username', email);
                        that.get('session').set('data.password', pass);
                        that.get('session').set('data.user_id', user_id);
                        that.get('session').set('data.brand_id', brand.id);
                        that.get('session').set('data.brand_company', brand.company);
                        that.get('session').set('data.funds', brand.funds);
                        that.get('session').set('data.paynow', true);
                        that.get("applicationController").send("submitAnimationDone", function () {
                          setTimeout(function () {
                            that.get("applicationController").send("hideLoader");
                            that.transitionToRoute('mycampaign');
                          }, 1000);
                        });
                        that.deleteLocalData();
                      }, err => {
                        that.get("applicationController").send("hideLoader");
                      });
                    } else {
                      that.get("applicationController").send("hideLoader");
                    }
                  });
                } else {
                  that.get("applicationController").send("hideLoader");
                }
              });
            } else {
              that.get("applicationController").send("hideLoader");
            }
          });
        }
      },

      createNewUser(cb) {
        var that = this;

        if ((0, _jquery.default)("#password").val() != (0, _jquery.default)("#passwordconfirm").val()) {
          cb();
          alert("Password doesn't match!");
          return;
        }

        var user = {
          "user": {
            "username": (0, _jquery.default)("#email").val(),
            "password": (0, _jquery.default)("#password").val(),
            "first_name": (0, _jquery.default)("#firstname").val(),
            "last_name": (0, _jquery.default)("#lastname").val(),
            "email": (0, _jquery.default)("#email").val(),
            "is_brand": true,
            "is_staff": false
          }
        };
        var cred = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");

        _jquery.default.ajax({
          type: "POST",
          url: _environment.default.APP.API_HOST + "/users",
          data: JSON.stringify(user),
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", cred);
            xhr.setRequestHeader("Content-Type", "application/json");
          },
          success: function (res) {
            if (res && res.user) {
              cb(res.user.id);
            } else {
              cb();
            }
          },
          error: function (e, exception) {
            cb();

            if (e && e.responseJSON && e.responseJSON.user) {
              var user = e.responseJSON.user;

              if (user.email[0]) {
                alert(user.email[0]);
              } else {
                alert(user);
              }
            } else {
              alert(_t.default.compute("campaign_fail"));
            }
          }
        });
      },

      createNewBrand(user_id, cb) {
        var origin = "",
            country = (0, _jquery.default)("#country").val();

        if (country.toLowerCase() == "switzerland") {
          country = "Italy";
          origin = (0, _jquery.default)("#country").val();
        }

        var brand = {
          "brand": {
            "user": user_id,
            "company": this.get("campaign.brand_name"),
            "phone": (0, _jquery.default)("#phone").val(),
            "mobile": (0, _jquery.default)("#mobile").val(),
            "street_address": (0, _jquery.default)("#streetaddress").val(),
            "location_city": (0, _jquery.default)("#city").val(),
            "location_state": (0, _jquery.default)("#state").val(),
            "location_postcode": (0, _jquery.default)("#postcode").val(),
            "location_country": $country,
            "origin": origin,
            "stripe_token": "TEST_TOKEN_WEB",
            "iva": (0, _jquery.default)("#iva").val()
          }
        };

        _jquery.default.ajax({
          type: "POST",
          url: _environment.default.APP.API_HOST + "/brands",
          data: JSON.stringify(brand),
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Basic " + btoa((0, _jquery.default)("#email").val() + ":" + (0, _jquery.default)("#password").val()));
            xhr.setRequestHeader("Content-Type", "application/json");
          },
          success: function (res) {
            if (res && res.brand) {
              cb(res.brand);
            } else {
              cb();
            }
          },
          error: function (e, exception) {
            console.log('test call brand draft camp');
            cb();
            alert(_t.default.compute("campaign_fail"));
          }
        });
      },

      createNewCampaign(user_id, brand_id, cb) {
        var publish_date = moment().format('YYYY-MM-DDThh:mm:ss');
        var open_until_date = (0, _jquery.default)("#publishuntil").val();
        var mood_board = [];
        var response_time = "2 hours";
        var that = this;

        if (this.get("campaign.publish_status") == "later") {
          publish_date = (0, _jquery.default)("#publishdate").val();
          publish_date = moment(publish_date, "DD/MM/YY hh:mm").format('YYYY-MM-DDThh:mm:ss');
        }

        if (this.get("campaign.file_input_1_label")) {
          mood_board.push(this.get("campaign.file_input_1_label"));
        }

        if (this.get("campaign.file_input_2_label")) {
          mood_board.push(this.get("campaign.file_input_2_label"));
        }

        if (this.get("campaign.file_input_3_label")) {
          mood_board.push(this.get("campaign.file_input_3_label"));
        }

        if (this.get("campaign.file_input_4_label")) {
          mood_board.push(this.get("campaign.file_input_4_label"));
        }

        if (this.get("campaign.file_input_5_label")) {
          mood_board.push(this.get("campaign.file_input_5_label"));
        }

        if (this.get("campaign.file_input_6_label")) {
          mood_board.push(this.get("campaign.file_input_6_label"));
        }

        var instagram = this.get("ad_text") + " " + this.get("campaign.instagram_tags");
        var twitter = this.get("ad_text") + " " + this.get("campaign.twitter_tags");
        var facebook = this.get("ad_text") + " " + this.get("campaign.facebook_tags"); // var tags = {"instagram": instagram, "twitter": twitter, "facebook": facebook};

        var tags = {
          "instagram": instagram.trim(),
          "twitter": twitter.trim(),
          "facebook": facebook.trim()
        };
        var campaign = {
          "campaign": {
            "user": user_id,
            "content_love": this.get("campaign.content_we_love"),
            "call_action": this.get("campaign.call_to_action"),
            "pre_tags": tags,
            "pub_guides": this.get("campaign.dos"),
            "dont_dos": this.get("campaign.donts"),
            "mood_board": [],
            "platform_insta": this.get("campaign.instagram"),
            "platform_twitter": this.get("campaign.twitter"),
            "platform_fb": this.get("campaign.facebook"),
            "hero_image": this.get("campaign.hero_image"),
            "campaign_logo": this.get("campaign.logo"),
            "brand_comp_name": this.get("campaign.brand_name"),
            "pro_comp_name": this.get("campaign.campaign_name"),
            "pro_desc": this.get("campaign.product_description"),
            "campaign_link": this.get("campaign.link"),
            "where_purchase": this.get("campaign.where_to_purchase"),
            "ageMin": this.get("campaign.age_min"),
            "ageMax": this.get("campaign.age_max"),
            "gender": this.get("campaign.gender").toLowerCase(),
            "info_demographic": this.get("campaign.target_regions_string"),
            "interests": this.get("campaign.interests"),
            "post_submissions": 0,
            "posts_accepted": 0,
            "response_time": response_time,
            // "response_time_date": "",
            "publish_brief_app": publish_date,
            "open_until_date": moment(open_until_date, "DD/MM/YY hh:mm").format('YYYY-MM-DDThh:mm:ss'),
            "created_datetime": moment().format('YYYY-MM-DDThh:mm:ss'),
            "updated_date": moment().format('YYYY-MM-DDThh:mm:ss')
          }
        };
        var email = "";
        var pass = "";

        if (this.get("session.isAuthenticated")) {
          email = this.get('session.data.username');
          pass = this.get('session.data.password');
        } else {
          email = (0, _jquery.default)("#email").val();
          pass = (0, _jquery.default)("#password").val();
        }

        var cred = "Basic " + btoa(email + ":" + pass);

        _jquery.default.ajax({
          type: "POST",
          url: _environment.default.APP.API_HOST + "/campaigns",
          data: JSON.stringify(campaign),
          timeout: 60000,
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", cred);
            xhr.setRequestHeader("Content-Type", "application/json");
          },
          success: function (res) {
            if (res && res.campaign) {
              that.send('pushMoodImages', res.campaign.id, mood_board, 0, cred, function () {
                cb(res.campaign.id);
              });
            } else {
              cb();
            }
          },
          error: function (e, exception) {
            cb();
            console.log(e);
            console.log(exception);
            alert(_t.default.compute("campaign_fail"));
          }
        });
      },

      pushMoodImages(campaign_id, mood_board, index, cred, cb) {
        if (index < mood_board.length) {
          var data = {
            "campaign_id": campaign_id,
            "mood_image": mood_board[index]
          };
          var that = this;

          _jquery.default.ajax({
            type: "POST",
            url: _environment.default.APP.API_HOST + "/addcampaignmoodimage",
            data: data,
            beforeSend: function (xhr) {
              xhr.setRequestHeader("Authorization", cred);
              xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            },
            success: function (res) {
              that.send('pushMoodImages', campaign_id, mood_board, index + 1, cred, cb);
            },
            error: function (e, exception) {
              that.send('pushMoodImages', campaign_id, mood_board, index + 1, cred, cb);
            }
          });
        } else {
          cb();
        }
      },

      removeUploadedImage(f, e) {
        if (f == 1) {
          this.set("campaign.hero_image", "");
          (0, _jquery.default)("#hero_input_1").val("");
          this.set("isCroppedHeroImageActived", false);
        } else if (f == 2) {
          this.set("campaign.logo", "");
          (0, _jquery.default)("#hero_input_2").val("");
          this.set("isCroppedLogoImageActived", false);
        }

        e.preventDefault();
        e.stopPropagation();
      },

      removeUploadedMood(f, e) {
        var key = "campaign.file_input_" + f + "_label";
        this.set(key, "");
        (0, _jquery.default)("#file_input_" + f).val("");
        e.preventDefault();
        e.stopPropagation();
      },

      addGuideline(flag) {
        if (flag == 1) {
          //dos
          var dos = (0, _jquery.default)("#pub_guides").val();

          if (dos) {
            (0, _jquery.default)("#dos_list").prepend("<li><p>" + dos + " </p><span onclick='jQuery(this).parent().remove()'>X</span></li>");
            (0, _jquery.default)("#pub_guides").val("");
          }
        } else if (flag == 2) {
          //don'ts
          var donts = (0, _jquery.default)("#dont_dos").val();

          if (donts) {
            (0, _jquery.default)("#donts_list").prepend("<li><p>" + donts + " </p><span onclick='jQuery(this).parent().remove()'>X</span></li>");
            (0, _jquery.default)("#dont_dos").val("");
          }
        }
      },

      onVideoClick() {
        var dialog = (0, _jquery.default)("#newcamp-vid-dialog").modal({
          showClose: true,
          fadeDuration: 300,
          escapeClose: false,
          clickClose: false
        });

        if (this.observationDialog) {
          (0, _jquery.default)(this.observationDialog[0]).remove();
        }

        this.observationDialog = dialog;
        return false;
      }

    }
  });

  _exports.default = _default;
});