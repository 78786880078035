define("flytrendy/models/brand", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _model.default.extend({
    user: _emberData.default.attr(),
    username: _emberData.default.attr(),
    company: _emberData.default.attr(),
    phone: _emberData.default.attr(),
    mobile: _emberData.default.attr(),
    cc_email: _emberData.default.attr(),
    billing_company: _emberData.default.attr(),
    street_address: _emberData.default.attr(),
    location_city: _emberData.default.attr(),
    location_state: _emberData.default.attr(),
    location_postcode: _emberData.default.attr(),
    location_country: _emberData.default.attr(),
    origin: _emberData.default.attr(),
    stripe_token: _emberData.default.attr(),
    created_datetime: _emberData.default.attr(),
    funds: _emberData.default.attr(),
    iva: _emberData.default.attr(),
    pec_univoco: _emberData.default.attr(),
    brand_currency: _emberData.default.attr(),
    is_agency: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});