define("flytrendy/controllers/activate", ["exports", "jquery", "flytrendy/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    email: null,
    activation_token: null,
    activateText: "Checking activation token...",
    lang: "it",
    init: function () {
      this._super(...arguments);

      var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
          sParameterName = sURLVariables[i].split('=');

          if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
          }
        }
      };

      var email = getUrlParameter('email');
      this.set('email', email);
      var activation_token = getUrlParameter('activation_token');
      this.set('activation_token', activation_token);

      if (activation_token && email) {
        this.activateAccount();
      }

      this.set("lang", localStorage.getItem("lang"));
    },
    activateAccount: function () {
      var email = this.get("email");
      var activation_token = this.get("activation_token");
      var that = this;

      if (email && activation_token) {
        _jquery.default.ajax({
          type: "GET",
          url: _environment.default.APP.API_HOST + "/activate?email=" + email + "&activation_token=" + activation_token,
          dataType: "json",
          success: function (res) {
            if (res.activateAccount['success']) {
              var currentLang = that.lang; //if (currentLang == 'it') {

              that.set('activateText', "Congratulazioni, il tuo account \xE8 ora attivo. Puoi accedere alla nostra App e partecipare alle campagne."); //} else {
              //	that.set('activateText', "Congratulations your account is now active. You can now login to the FlyTrendy Influencer mobile app to participate in Campaigns.");
              //}
            } else {
              var currentLang = that.lang; //if (currentLang == 'it') {

              that.set('activateText', "Sembra che tu abbia gi\xE0 attivato il tuo account! Effettua il login nel App Influencer per partecipare alle campagne."); //} else {
              //	that.set('activateText', "It looks you you have already activated your account! Please login to the FlyTrendy Influencer mobile app to participate in Campaigns.");
              //}
            }
          },
          error: function (jqXHR, exception) {
            that.set('activateText', "An error occured please try again.");
          }
        });
      } else {
        that.set('activateText', "Activation token required!");
      }
    }
  });

  _exports.default = _default;
});