define("flytrendy/controllers/changepassword", ["exports", "jquery", "flytrendy/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    queryParams: ['ac', 'c'],
    init: function () {
      this._super(...arguments);
    },
    actions: {
      onLoading() {
        var ac = this.get('ac'),
            c = this.get('c');

        if (ac && c) {
          (0, _jquery.default)('.changepassword .login-cont').show();
        } else {
          (0, _jquery.default)('.changepassword .login-cont').hide();
        }
      },

      authenticate() {
        var ac = this.get('ac'),
            c = this.get('c');

        if (!ac || !c) {
          return;
        }

        let {
          pass,
          passc
        } = this.getProperties('pass', 'passc');
        var that = this;

        if (pass && passc) {
          if (pass == passc) {
            var data = {
              verification_code: c,
              access_code: ac,
              password: pass
            };

            _jquery.default.ajax({
              type: 'POST',
              data: data,
              url: _environment.default.APP.API_HOST + '/updatepassword',
              dataType: 'text',
              beforeSend: function (xhr) {
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
              },
              success: function (res) {
                (0, _jquery.default)('.changepassword .login-cont').hide();
                setTimeout(function () {
                  alert('Password changed successfully!');
                }, 1000);
              },
              error: function (jqXHR, exception) {
                alert('Cannot connect to the server, please try again later!');
              }
            });
          } else {
            alert('Password does not match!');
          }
        } else {
          alert('Password is required!');
        }
      }

    }
  });

  _exports.default = _default;
});