define("flytrendy/controllers/editcampaign", ["exports", "jquery", "flytrendy/models/objects/countries", "flytrendy/config/environment", "flytrendy/helpers/t"], function (_exports, _jquery, _countries, _environment, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    campaignBuilder: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    queryParams: ['id'],
    data: [],
    active_tab: 1,
    brief_tabl: true,
    campaign_tabl: false,
    influencers_tabl: false,
    review_tabl: false,
    submit_tabl: false,
    title: 'Campaign',
    campaign: null,
    countries: null,
    observationDialog: null,
    mood_gallery: [],
    compressor: null,
    autoRedirect: false,
    tt_1: _t.default.compute('nc_tt_1'),
    tt_2: _t.default.compute('nc_tt_2'),
    tt_3: _t.default.compute('nc_tt_3'),
    tt_4: _t.default.compute('nc_tt_4'),
    tt_5: _t.default.compute('nc_tt_5'),
    tt_6: _t.default.compute('nc_tt_6'),
    tt_7: _t.default.compute('nc_tt_7'),
    tt_8: _t.default.compute('nc_tt_8'),
    tt_9: _t.default.compute('nc_tt_9'),
    tt_10: _t.default.compute('nc_tt_10'),
    tt_11: _t.default.compute('nc_tt_11'),
    ad_text: _environment.default.PLATFORM.ad_text,
    isCroppedHeroImageActived: false,
    isCroppedLogoImageActived: false,
    dos: _environment.default.PLATFORM.dos,
    donts: _environment.default.PLATFORM.donts,
    init: function () {
      this._super(...arguments);

      this.active_tab = 1;
      this.set('countries', _countries.default.create({}));
      this.set('ad_text', this.ad_text);
    },
    actions: {
      onLoading() {
        this.active_tab = 1;
        this.send('setActiveTab');
        this.compressor = new ImageCompressor();
        this.set('autoRedirect', false);
        var that = this; // do not show for a user pre-defined dos and donts from a platform config

        const default_dos = this.campaignBuilder.excludeItemsFromArray(this.get('campaign.dos'), _environment.default.PLATFORM.dos);
        const default_donts = this.campaignBuilder.excludeItemsFromArray(this.get('campaign.donts'), _environment.default.PLATFORM.donts);
        this.set('campaign.dos', default_dos);
        this.set('campaign.donts', default_donts);
      },

      onGenderChange(gender) {
        if (gender == 1) {
          (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont .male').toggleClass('selected');
        }

        if (gender == 2) {
          (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont .female').toggleClass('selected');
        }

        if (gender == 3) {
          (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont button').removeClass('selected');
          (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont .male').addClass('selected');
          (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont .female').addClass('selected');
        }

        if (gender == 4) {
          (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont button').removeClass('selected');
        }

        var m = (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont .male').hasClass('selected');
        var f = (0, _jquery.default)('.briefcompaign .influencers-cont .gender-cont .female').hasClass('selected');

        if (m && f) {
          this.set('campaign.gender', 'all');
        } else if (m && !f) {
          this.set('campaign.gender', 'male');
        } else if (!m && f) {
          this.set('campaign.gender', 'female');
        } else {
          this.set('campaign.gender', '');
        }
      },

      onPublishChange(pub) {
        (0, _jquery.default)('.briefcompaign .submit-cont button').removeClass('selected');

        if (pub == 1) {
          (0, _jquery.default)('.briefcompaign .submit-cont .now').addClass('selected');
          this.set('campaign.publish_status', 'now');
          (0, _jquery.default)('.briefcompaign .submit-cont .later-date').hide();
        } else {
          (0, _jquery.default)('.briefcompaign .submit-cont .later').addClass('selected');
          this.set('campaign.publish_status', 'later');
          (0, _jquery.default)('.briefcompaign .submit-cont .later-date').show();
        }
      },

      next() {
        this.send('saveTempData', this.active_tab, true);
      },

      previous() {
        this.send('saveTempData', this.active_tab, false);
      },

      saveTempData(tab, validate) {
        // tab is current step
        // this function is called prior to next/prev tab to save data.
        (0, _jquery.default)('html,body').scrollTop(0);

        if (tab == 1) {
          if (validate) {
            var ok = true;
            (0, _jquery.default)('#brand_comp_name').parent().removeClass('info-required');

            if (!this.get('session.isAuthenticated') && !(0, _jquery.default)('#brand_comp_name').val()) {
              (0, _jquery.default)('#brand_comp_name').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#pro_comp_name').parent().removeClass('info-required');

            if (!(0, _jquery.default)('#pro_comp_name').val()) {
              (0, _jquery.default)('#pro_comp_name').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#pro_desc').parent().removeClass('info-required');

            if (!(0, _jquery.default)('#pro_desc').val()) {
              (0, _jquery.default)('#pro_desc').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#campaign_link').parent().removeClass('info-required');

            if (!(0, _jquery.default)('#campaign_link').val()) {
              (0, _jquery.default)('#campaign_link').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#where_purchase').parent().removeClass('info-required');

            if (!(0, _jquery.default)('#where_purchase').val()) {
              (0, _jquery.default)('#where_purchase').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('.hero-image-cont').removeClass('info-required');

            if (!this.get('campaign.hero_image') || this.get('isCroppedHeroImageActived')) {
              (0, _jquery.default)('.hero-image-cont').addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('.logo-image-cont').removeClass('info-required');

            if (!this.get('campaign.logo') || this.get('isCroppedLogoImageActived')) {
              (0, _jquery.default)('.logo-image-cont').addClass('info-required');
              ok = false;
            }

            if (!ok) return;
          }
          /*if(this.get("session.isAuthenticated")){
                          this.set("campaign.brand_name", this.get('session.data.brand_company'));
                      }else{*/


          this.set('campaign.brand_name', (0, _jquery.default)('#brand_comp_name').val());
          this.set('campaign.brand_comp_name', (0, _jquery.default)('#brand_comp_name').val());
          console.log('comp_name', (0, _jquery.default)('#brand_comp_name').val()); //}

          this.set('campaign.campaign_name', (0, _jquery.default)('#pro_comp_name').val());
          this.set('campaign.product_description', (0, _jquery.default)('#pro_desc').val());
          this.set('campaign.link', (0, _jquery.default)('#campaign_link').val());
          this.set('campaign.where_to_purchase', (0, _jquery.default)('#where_purchase').val());
        } else if (tab == 2) {
          var instagram_tags = '',
              twitter_tags = '',
              facebook_tags = '';
          var dos = this.get('campaign.dos'),
              donts = this.get('campaign.donts');

          if (validate) {
            var ok = true;
            (0, _jquery.default)('#content_love').parent().removeClass('info-required');

            if (!(0, _jquery.default)('#content_love').val()) {
              (0, _jquery.default)('#content_love').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#call_action').parent().removeClass('info-required');

            if (!(0, _jquery.default)('#call_action').val()) {
              (0, _jquery.default)('#call_action').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#pre_tags_instagram').parent().removeClass('info-required');

            if (this.get('campaign.instagram') && !(0, _jquery.default)('#pre_tags_instagram').val()) {
              (0, _jquery.default)('#pre_tags_instagram').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#pre_tags_twitter').parent().removeClass('info-required');

            if (this.get('campaign.twitter') && !(0, _jquery.default)('#pre_tags_twitter').val()) {
              (0, _jquery.default)('#pre_tags_twitter').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('#pre_tags_facebook').parent().removeClass('info-required');

            if (this.get('campaign.facebook') && !(0, _jquery.default)('#pre_tags_facebook').val()) {
              (0, _jquery.default)('#pre_tags_facebook').parent().addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('.platform-cont').removeClass('info-required');

            if (!this.get('campaign.facebook') && !this.get('campaign.instagram') && !this.get('campaign.twitter')) {
              (0, _jquery.default)('.platform-cont').addClass('info-required');
              ok = false;
            }

            if (!ok) return;
          }

          this.set('campaign.content_we_love', (0, _jquery.default)('#content_love').val());
          this.set('campaign.call_to_action', (0, _jquery.default)('#call_action').val());

          if (this.get('campaign.instagram')) {
            instagram_tags = (0, _jquery.default)('#pre_tags_instagram').val();
          }

          if (this.get('campaign.twitter')) {
            twitter_tags = (0, _jquery.default)('#pre_tags_twitter').val();
          }

          if (this.get('campaign.facebook')) {
            facebook_tags = (0, _jquery.default)('#pre_tags_facebook').val();
          }

          this.set('campaign.instagram_tags', instagram_tags);
          this.set('campaign.twitter_tags', twitter_tags);
          this.set('campaign.facebook_tags', facebook_tags);
          this.set('campaign.instagram_tags_list', instagram_tags.split(' '));
          this.set('campaign.twitter_tags_list', twitter_tags.split(' '));
          this.set('campaign.facebook_tags_list', facebook_tags.split(' '));
          const testdos = this.get('campaign.dos', dos);
          const testdonts = this.get('campaign.donts', donts);
          (0, _jquery.default)('#dos_list li').each(function () {
            const dosText = (0, _jquery.default)((0, _jquery.default)(this)[0].firstChild).text().trim();

            if (dosText) {
              dos.push(dosText);
            }
          });
          (0, _jquery.default)('#donts_list li').each(function () {
            const dontsText = (0, _jquery.default)((0, _jquery.default)(this)[0].firstChild).text().trim();

            if (dontsText) {
              donts.push(dontsText);
            }
          });
          this.set('campaign.dos', dos);
          this.set('campaign.donts', donts);
        } else if (tab == 3) {
          var regions = [],
              list = [];
          (0, _jquery.default)('.briefcompaign .influencers-cont .demographic-cont .region-cont span').each(function (index) {
            if ((0, _jquery.default)(this).hasClass('selected')) {
              regions.push((0, _jquery.default)(this).text());
            }
          });
          this.set('campaign.target_regions', regions);
          (0, _jquery.default)('.briefcompaign .influencers-cont .demographic-cont .interest div').each(function (index) {
            if ((0, _jquery.default)(this).hasClass('selected')) {
              list.push((0, _jquery.default)(this).text());
            }
          });
          this.set('campaign.interests', list);

          if (validate) {
            var ok = true;
            (0, _jquery.default)('.region-list-cont').removeClass('info-required');

            if (this.get('campaign.target_regions').length == 0) {
              (0, _jquery.default)('.region-list-cont').addClass('info-required');
              ok = false;
            }

            (0, _jquery.default)('.interest-list-cont').removeClass('info-required');

            if (this.get('campaign.interests').length == 0) {
              (0, _jquery.default)('.interest-list-cont').addClass('info-required');
              ok = false;
            }

            var g = this.get('campaign.gender');
            (0, _jquery.default)('.gender-cont').parent().removeClass('info-required');

            if (!g || g == '') {
              (0, _jquery.default)('.gender-cont').parent().addClass('info-required');
              ok = false;
            }

            if (!ok) return;
          }
        } else if (tab == 4) {// review tab
        } else if (tab == 5) {
          //submit tab
          this.set('campaign.first_name', (0, _jquery.default)('#firstname').val());
          this.set('campaign.last_name', (0, _jquery.default)('#lastname').val());
          this.set('campaign.campaign_name', (0, _jquery.default)('#companyname').val());
          this.set('campaign.email', (0, _jquery.default)('#email').val());
          this.set('campaign.ph', (0, _jquery.default)('#phone').val());
          this.set('campaign.mob', (0, _jquery.default)('#mobile').val());
          this.set('campaign.pass', (0, _jquery.default)('#password').val());
          this.set('campaign.c_pass', (0, _jquery.default)('#passwordconfirm').val());
          this.set('campaign.address', (0, _jquery.default)('#streetaddress').val());
          this.set('campaign.city', (0, _jquery.default)('#city').val());
          this.set('campaign.state', (0, _jquery.default)('#state').val());
          this.set('campaign.postcode', (0, _jquery.default)('#postcode').val());
          this.set('campaign.country', (0, _jquery.default)('#country').val());
          this.set('campaign.response_time', '2 hours');
          this.set('campaign.publish_date', (0, _jquery.default)('#publishdate').val());
          this.set('campaign.until_date', (0, _jquery.default)('#publishuntil').val());
          this.set('campaign.agreement', (0, _jquery.default)('#brandchecked').prop('checked'));
          this.set('campaign.agreement_tc', (0, _jquery.default)('#brandchecked_tc').prop('checked'));
        }

        if (validate) {
          this.active_tab++;
          this.send('setActiveTab');
        } else {
          this.active_tab--;
          this.send('setActiveTab');
        }
      },

      togglePlatform(op) {
        if (op == 1) {
          (0, _jquery.default)('.briefcompaign .platform-cont .instagram').toggleClass('instagram-selected');
          this.set('campaign.instagram', false);

          if ((0, _jquery.default)('.briefcompaign .platform-cont .instagram').hasClass('instagram-selected')) {
            this.set('campaign.instagram', true);
          }
        } else if (op == 2) {
          (0, _jquery.default)('.briefcompaign .platform-cont .twitter').toggleClass('twitter-selected');
          this.set('campaign.twitter', false);

          if ((0, _jquery.default)('.briefcompaign .platform-cont .twitter').hasClass('twitter-selected')) {
            this.set('campaign.twitter', true);
          }
        } else if (op == 3) {
          (0, _jquery.default)('.briefcompaign .platform-cont .facebook').toggleClass('facebook-selected');
          this.set('campaign.facebook', false);

          if ((0, _jquery.default)('.briefcompaign .platform-cont .facebook').hasClass('facebook-selected')) {
            this.set('campaign.facebook', true);
          }
        }

        (0, _jquery.default)('.platform-cont').removeClass('info-required');
      },

      onSaveCroppedHeroImage() {
        (0, _jquery.default)('.hero-image-cont .processing').show();
        var that = this;
        var cropped = (0, _jquery.default)('#hero_image_cropped').cropper('getCroppedCanvas');
        setTimeout(function () {
          that.set('campaign.hero_image', cropped.toDataURL());
          (0, _jquery.default)('#new_hero_image_cont').show();
          (0, _jquery.default)('#crop_hero_image_cont').hide();
          (0, _jquery.default)('#crop_hero_image_subcont').text('');
          fetch(cropped.toDataURL('image/png')).then(res => res.blob()).then(blob => {
            const file = new File([blob], 'heroimage.png');
            that.compressor.compress(blob, {
              quality: 0.6,
              maxWidth: 450
            }).then(results => {
              const reader = new FileReader();
              var base64DataURL;

              reader.onload = e => {
                base64DataURL = e.target.result;
                that.set('campaign.hero_image', base64DataURL);
                that.set('isCroppedHeroImageActived', false);
                (0, _jquery.default)('.hero-image-cont').removeClass('info-required');
                (0, _jquery.default)('.hero-image-cont .processing').hide();
              };

              reader.readAsDataURL(results);
            });
          });
        }, 1000);
      },

      onSaveCroppedLogoImage() {
        (0, _jquery.default)('.logo-image-cont .processing').show();
        var that = this;
        var cropped = (0, _jquery.default)('#logo_image_cropped').cropper('getCroppedCanvas');
        setTimeout(function () {
          (0, _jquery.default)('.logo-image-cont .processing').css({
            height: '300px',
            'margin-top': '-300px'
          });
          that.set('campaign.logo', cropped.toDataURL());
          (0, _jquery.default)('#new_logo_image_cont').show();
          (0, _jquery.default)('#crop_logo_image_cont').hide();
          (0, _jquery.default)('#crop_logo_image_subcont').text('');
          fetch(cropped.toDataURL('image/png')).then(res => res.blob()).then(blob => {
            const file = new File([blob], 'logo.png');
            that.compressor.compress(blob, {
              quality: 0.6,
              maxWidth: 450
            }).then(results => {
              const reader = new FileReader();
              var base64DataURL;

              reader.onload = e => {
                base64DataURL = e.target.result;
                that.set('campaign.logo', base64DataURL);
                that.set('isCroppedLogoImageActived', false);
                (0, _jquery.default)('.logo-image-cont').removeClass('info-required');
                (0, _jquery.default)('.logo-image-cont .processing').hide();
              };

              reader.readAsDataURL(results);
            });
          });
        }, 1000);
      },

      setActiveTab() {
        this.set('brief_tabl', false);
        this.set('campaign_tabl', false);
        this.set('influencers_tabl', false);
        this.set('review_tabl', false);
        this.set('submit_tabl', false);
        (0, _jquery.default)('.briefcompaign .option-list .col-md-2').removeClass('selected');

        switch (this.active_tab) {
          case 1:
            this.set('campaign_tabl', true);
            (0, _jquery.default)('.campaign-tab').addClass('selected');
            this.set('title', 'Create Campaign');
            var that = this;
            setTimeout(function () {
              (0, _jquery.default)('.hero-image-cont .processing').hide();
              var heroImage = document.getElementById('hero_input_1');
              heroImage.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.hero-image-cont .processing').show();
                that.compressor.compress(file, {
                  quality: 0.8
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    that.set('campaign.hero_image', base64DataURL);
                    (0, _jquery.default)('#new_hero_image_cont').hide();
                    (0, _jquery.default)('#crop_hero_image_cont').show();
                    (0, _jquery.default)('#crop_hero_image_subcont').text('');
                    (0, _jquery.default)('#crop_hero_image_subcont').append('<div><img id="hero_image_cropped" style="max-width: 100%;" src="' + base64DataURL + '"></div>');
                    that.set('isCroppedHeroImageActived', true);
                    (0, _jquery.default)('.hero-image-cont .processing').hide();
                    (0, _jquery.default)('#hero_image_cropped').cropper({
                      aspectRatio: 16 / 9,
                      zoomable: false,
                      crop: function (e) {}
                    });
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              (0, _jquery.default)('.logo-image-cont .processing').hide();
              var logo = document.getElementById('hero_input_2');
              logo.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.logo-image-cont .processing').css({
                  height: '300px',
                  'margin-top': '-300px'
                });
                (0, _jquery.default)('.logo-image-cont .processing').show();
                that.compressor.compress(file, {
                  quality: 0.8
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    that.set('campaign.logo', base64DataURL);
                    (0, _jquery.default)('#crop_logo_image_subcont').text('');
                    var img = new Image();

                    img.onload = function () {
                      var w = this.width,
                          h = this.height;
                      var size = w;
                      (0, _jquery.default)('#new_logo_image_cont').hide();
                      (0, _jquery.default)('#crop_logo_image_cont').show();

                      if (w > h) {
                        size = w;
                      } else if (h > w) {
                        size = h;
                      }

                      size = size + 'px';
                      (0, _jquery.default)('#crop_logo_image_subcont').append('<div class="logo-crop-main-cont"><img id="logo_image_cropped" style="max-width: 100%;" src="' + base64DataURL + '"></div>');
                      that.set('isCroppedLogoImageActived', true);
                      (0, _jquery.default)('.logo-image-cont .processing').css({
                        height: '564px',
                        'margin-top': '-564px'
                      });
                      (0, _jquery.default)('.logo-image-cont .processing').hide();
                      (0, _jquery.default)('#logo_image_cropped').cropper({
                        aspectRatio: 10 / 10,
                        zoomable: false,
                        crop: function (e) {}
                      });
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
            }, 100);
            break;

          case 2:
            this.set('brief_tabl', true);
            (0, _jquery.default)('.brief-tab').addClass('selected');
            this.set('title', 'Brief for Campaign');
            var that = this;
            setTimeout(function () {
              (0, _jquery.default)('.mood-board .mood-processing').hide();
              var mood1 = document.getElementById('file_input_1');
              mood1.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.mood-board .mood-processing').show();
                that.compressor.compress(file, {
                  quality: 0.6,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)('.mood-board .mood-processing').hide();
                    that.set('campaign.file_input_1_label', base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set('campaign.file_input_1_label_w', this.width);
                      that.set('campaign.file_input_1_label_h', this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood2 = document.getElementById('file_input_2');
              mood2.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.mood-board .mood-processing').show();
                that.compressor.compress(file, {
                  quality: 0.6,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)('.mood-board .mood-processing').hide();
                    that.set('campaign.file_input_2_label', base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set('campaign.file_input_2_label_w', this.width);
                      that.set('campaign.file_input_2_label_h', this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood3 = document.getElementById('file_input_3');
              mood3.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.mood-board .mood-processing').show();
                that.compressor.compress(file, {
                  quality: 0.6,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)('.mood-board .mood-processing').hide();
                    that.set('campaign.file_input_3_label', base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set('campaign.file_input_3_label_w', this.width);
                      that.set('campaign.file_input_3_label_h', this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood4 = document.getElementById('file_input_4');
              mood4.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.mood-board .mood-processing').show();
                that.compressor.compress(file, {
                  quality: 0.6,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)('.mood-board .mood-processing').hide();
                    that.set('campaign.file_input_4_label', base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set('campaign.file_input_4_label_w', this.width);
                      that.set('campaign.file_input_4_label_h', this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood5 = document.getElementById('file_input_5');
              mood5.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.mood-board .mood-processing').show();
                that.compressor.compress(file, {
                  quality: 0.6,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)('.mood-board .mood-processing').hide();
                    that.set('campaign.file_input_5_label', base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set('campaign.file_input_5_label_w', this.width);
                      that.set('campaign.file_input_5_label_h', this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
              var mood6 = document.getElementById('file_input_6');
              mood6.addEventListener('change', function (evt) {
                const file = evt.target.files[0];
                if (!file) return;
                (0, _jquery.default)('.mood-board .mood-processing').show();
                that.compressor.compress(file, {
                  quality: 0.6,
                  maxWidth: 450
                }).then(results => {
                  const reader = new FileReader();
                  var base64DataURL;

                  reader.onload = e => {
                    base64DataURL = e.target.result;
                    (0, _jquery.default)('.mood-board .mood-processing').hide();
                    that.set('campaign.file_input_6_label', base64DataURL);
                    var img = new Image();

                    img.onload = function () {
                      that.set('campaign.file_input_6_label_w', this.width);
                      that.set('campaign.file_input_6_label_h', this.height);
                    };

                    img.src = base64DataURL;
                  };

                  reader.readAsDataURL(results);
                });
              }, false);
            }, 100);
            break;

          case 3:
            this.set('influencers_tabl', true);
            (0, _jquery.default)('.influencers-tab').addClass('selected');
            this.set('title', 'Influencers');
            var that = this;
            setTimeout(function () {
              var ageMinMax = [18, 43];

              if (that.get('campaign.age_min')) {
                ageMinMax[0] = that.get('campaign.age_min');
              }

              if (that.get('campaign.age_max')) {
                ageMinMax[1] = that.get('campaign.age_max');
              }

              that.set('campaign.age_min', ageMinMax[0]);
              that.set('campaign.age_max', ageMinMax[1]);

              if (that.get('campaign.gender') == 'female') {
                that.send('onGenderChange', 2);
              } else if (that.get('campaign.gender') == 'male') {
                that.send('onGenderChange', 1);
              } else if (that.get('campaign.gender') == 'all') {
                that.send('onGenderChange', 3);
              } else {
                that.send('onGenderChange', 4);
              }

              var slider = document.getElementById('age-slider');
              noUiSlider.create(slider, {
                start: ageMinMax,
                connect: true,
                limit: 100,
                step: 1,
                tooltips: true,
                range: {
                  min: 0,
                  max: 100
                },
                behaviour: 'tap-drag',
                format: {
                  to: function (value) {
                    return value;
                  },
                  from: function (value) {
                    return value;
                  }
                }
              });
              slider.noUiSlider.on('change', function (val) {
                that.set('campaign.age_min', val[0]);
                that.set('campaign.age_max', val[1]);
              });
              (0, _jquery.default)('.briefcompaign .influencers-cont .demographic-cont .region-cont span').click(function (e) {
                (0, _jquery.default)(this).toggleClass('selected');
              });
              var regions = that.get('campaign.target_regions');

              if (regions && regions.length > 0) {
                for (var i = 0; i < regions.length; i++) {
                  // jQuery( jQuery(".briefcompaign .influencers-cont .demographic-cont .region-cont span").eq(regions[i]) ).addClass("selected");
                  (0, _jquery.default)('.briefcompaign .influencers-cont .demographic-cont .region-cont span').filter(function () {
                    return (0, _jquery.default)(this).text() == regions[i];
                  }).addClass('selected');
                }
              }

              (0, _jquery.default)('.briefcompaign .influencers-cont .demographic-cont .interest div').click(function (e) {
                (0, _jquery.default)(this).toggleClass('selected');
              });
              var interests = that.get('campaign.interests');

              if (interests && interests.length > 0) {
                for (var i = 0; i < interests.length; i++) {
                  (0, _jquery.default)('.briefcompaign .influencers-cont .demographic-cont .interest div').filter(function () {
                    return (0, _jquery.default)(this).text() == interests[i];
                  }).addClass('selected');
                }
              }
            }, 100);
            break;

          case 4:
            this.set('review_tabl', true);
            (0, _jquery.default)('.review-tab').addClass('selected');
            this.set('title', 'Review Campaign');
            var interests = this.get('campaign.interests');
            var list = '';

            if (interests && interests.length > 0) {
              for (var i = 0; i < interests.length; i++) {
                list += interests[i] + ', ';
              }
            }

            var regions = this.get('campaign.target_regions'),
                temp_string = '';

            if (regions && regions.length > 0) {
              for (var i = 0; i < regions.length; i++) {
                // temp_string += this.get("countries.countries").get(regions[i]).country_name+", ";
                temp_string += regions[i] + ', ';
              }
            }

            this.set('campaign.interests_string', list);
            this.set('campaign.target_regions_string', temp_string);
            var gallery = [];

            if (this.get('campaign.file_input_1_label')) {
              gallery.push({
                src: this.get('campaign.file_input_1_label'),
                w: this.get('campaign.file_input_1_label_w'),
                h: this.get('campaign.file_input_1_label_h')
              });
            }

            if (this.get('campaign.file_input_2_label')) {
              gallery.push({
                src: this.get('campaign.file_input_2_label'),
                w: this.get('campaign.file_input_2_label_w'),
                h: this.get('campaign.file_input_2_label_h')
              });
            }

            if (this.get('campaign.file_input_3_label')) {
              gallery.push({
                src: this.get('campaign.file_input_3_label'),
                w: this.get('campaign.file_input_3_label_w'),
                h: this.get('campaign.file_input_3_label_h')
              });
            }

            if (this.get('campaign.file_input_4_label')) {
              gallery.push({
                src: this.get('campaign.file_input_4_label'),
                w: this.get('campaign.file_input_4_label_w'),
                h: this.get('campaign.file_input_4_label_h')
              });
            }

            if (this.get('campaign.file_input_5_label')) {
              gallery.push({
                src: this.get('campaign.file_input_5_label'),
                w: this.get('campaign.file_input_5_label_w'),
                h: this.get('campaign.file_input_5_label_h')
              });
            }

            if (this.get('campaign.file_input_6_label')) {
              gallery.push({
                src: this.get('campaign.file_input_6_label'),
                w: this.get('campaign.file_input_6_label_w'),
                h: this.get('campaign.file_input_6_label_h')
              });
            }

            this.set('mood_gallery', gallery);
            break;

          case 5:
            this.set('submit_tabl', true);
            (0, _jquery.default)('.submit-tab').addClass('selected');
            this.set('title', 'Submit Campaign');
            var that = this;
            setTimeout(function () {
              if (that.get('campaign.publish_status') == 'later') {
                that.send('onPublishChange', 2);
              } else {
                that.send('onPublishChange', 1);
              }

              (0, _jquery.default)('#brandchecked').change(function (e) {
                that.set('campaign.agreement', (0, _jquery.default)(e.target).is(':checked'));
              });
              (0, _jquery.default)('#brandchecked_tc').change(function (e) {
                that.set('campaign.agreement_tc', (0, _jquery.default)(e.target).is(':checked'));
              });
              (0, _jquery.default)('#publishdate').datetimepicker({
                format: 'd/m/y',
                timepicker: false,
                scrollInput: false,
                scrollMonth: false,
                minDate: '0'
              });
              (0, _jquery.default)('#publishdate').change(function () {
                var min_d = (0, _jquery.default)('#publishdate').val();
                min_d = moment(min_d, 'DD/MM/YY').format('YYYY/MM/DD');
                (0, _jquery.default)('#publishuntil').datetimepicker({
                  format: 'd/m/y',
                  timepicker: false,
                  scrollInput: false,
                  scrollMonth: false,
                  minDate: min_d
                });
              });
              (0, _jquery.default)('#publishuntil').datetimepicker({
                format: 'd/m/y',
                timepicker: false,
                scrollInput: false,
                scrollMonth: false,
                minDate: '0'
              });
              (0, _jquery.default)('#country').val(that.get('campaign.country'));
            }, 100);
            break;
        }
      },

      submit(op) {
        var that = this,
            ok = true;

        if (this.get('session.isAuthenticated')) {
          (0, _jquery.default)('#publishdate').parent().removeClass('info-required');

          if (this.get('campaign.publish') == 'later') {
            if (!(0, _jquery.default)('#publishdate').val()) {
              (0, _jquery.default)('#publishdate').parent().addClass('info-required');
              ok = false;
            }
          }

          (0, _jquery.default)('#publishuntil').parent().removeClass('info-required');

          if (!(0, _jquery.default)('#publishuntil').val()) {
            (0, _jquery.default)('#publishuntil').parent().addClass('info-required');
            ok = false;
          }

          if (!ok) {
            return;
          }

          this.get('applicationController').send('showLoader');
          this.send('createNewCampaign', that.get('session.data.username'), that.get('session.data.brand_company'), function (campaign_id) {
            if (campaign_id) {
              that.set('autoRedirect', true); // setTimeout(function(){
              //     that.transitionToRoute('mycampaign');
              // }, 2000);

              that.get('applicationController').send('submitAnimationDone', function () {
                setTimeout(function () {
                  that.get('applicationController').send('hideLoader');
                  that.transitionToRoute('mycampaign');
                }, 1000);
              });
            } else {
              that.get('applicationController').send('hideLoader');
            }
          });
        }
      },

      deleteMoodImages(campaign_id, cred, cb) {
        const data = JSON.stringify({
          campaign_id: parseInt(campaign_id, 10)
        });
        $.ajax({
          type: 'DELETE',
          dataType: 'json',
          url: _environment.default.APP.API_HOST + '/deletecampaignmoodimage',
          data: data,
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', cred);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            cb();
          },
          error: function (e, exception) {// that.get('applicationController').send('showToast', 'Please, try again', '', null);
          }
        });
      },

      createNewCampaign(user_id, brand_id, cb) {
        var publish_date = moment().format('YYYY-MM-DDThh:mm:ss');
        var open_until_date = (0, _jquery.default)('#publishuntil').val();
        var mood_board = [];
        var response_time = '2 hours';

        if (this.get('campaign.publish_status') == 'later') {
          publish_date = (0, _jquery.default)('#publishdate').val();
          publish_date = moment(publish_date, 'DD/MM/YY hh:mm').format('YYYY-MM-DDThh:mm:ss');
        }

        if (this.get('campaign.file_input_1_label')) {
          mood_board.push(this.get('campaign.file_input_1_label'));
        }

        if (this.get('campaign.file_input_2_label')) {
          mood_board.push(this.get('campaign.file_input_2_label'));
        }

        if (this.get('campaign.file_input_3_label')) {
          mood_board.push(this.get('campaign.file_input_3_label'));
        }

        if (this.get('campaign.file_input_4_label')) {
          mood_board.push(this.get('campaign.file_input_4_label'));
        }

        if (this.get('campaign.file_input_5_label')) {
          mood_board.push(this.get('campaign.file_input_5_label'));
        }

        if (this.get('campaign.file_input_6_label')) {
          mood_board.push(this.get('campaign.file_input_6_label'));
        }

        var instagram = this.get('campaign.instagram_tags');
        var twitter = this.get('campaign.twitter_tags');
        var facebook = this.get('campaign.facebook_tags');

        if (instagram.indexOf(this.get('ad_text')) < 0) {
          instagram = this.get('ad_text') + ' ' + this.get('campaign.instagram_tags');
        }

        if (twitter.indexOf(this.get('ad_text')) < 0) {
          twitter = this.get('ad_text') + ' ' + this.get('campaign.twitter_tags');
        }

        if (facebook.indexOf(this.get('ad_text')) < 0) {
          facebook = this.get('ad_text') + ' ' + this.get('campaign.facebook_tags');
        }

        var tags = {
          instagram: instagram.trim(),
          twitter: twitter.trim(),
          facebook: facebook.trim()
        };
        var that = this;
        var cred = '';

        if (this.get('session.isAuthenticated')) {
          cred = this.get('session.data.authenticated.token_type') + ' ' + this.get('session.data.authenticated.access_token');
        }

        const base64 = 'data:image';
        let campaign_logo = that.get('campaign.logo');
        let hero_image = that.get('campaign.hero_image');
        campaign_logo = campaign_logo.startsWith(base64) ? campaign_logo : undefined;
        hero_image = hero_image.startsWith(base64) ? hero_image : undefined;
        const actual_dos = this.campaignBuilder.makeUniqueArray(this.get('campaign.dos'), this.dos);
        const actual_donts = this.campaignBuilder.makeUniqueArray(this.get('campaign.donts'), this.donts);
        this.store.findRecord('campaign', this.id).then(function (c) {
          c.set('hero_image', hero_image);
          c.set('campaign_logo', campaign_logo);
          c.set('content_love', that.get('campaign.content_we_love'));
          c.set('call_action', that.get('campaign.call_to_action')); // c.set('pre_tags', that.get("campaign.tags"));

          c.set('pre_tags', tags);
          c.set('pub_guides', actual_dos);
          c.set('dont_dos', actual_donts);
          c.set('mood_board', []);
          c.set('platform_insta', that.get('campaign.instagram'));
          c.set('platform_twitter', that.get('campaign.twitter'));
          c.set('platform_fb', that.get('campaign.facebook'));
          c.set('hero_image_url', that.get('campaign.hero_image_url'));
          c.set('campaign_logo_url', that.get('campaign.campaign_logo_url'));
          c.set('brand_name', that.get('campaign.brand_name'));
          c.set('brand_comp_name', that.get('campaign.brand_comp_name'));
          c.set('pro_comp_name', that.get('campaign.campaign_name'));
          c.set('pro_desc', that.get('campaign.product_description'));
          c.set('campaign_link', that.get('campaign.link'));
          c.set('where_purchase', that.get('campaign.where_to_purchase'));
          c.set('reviewed', false);
          c.set('ageMin', that.get('campaign.age_min'));
          c.set('ageMax', that.get('campaign.age_max'));
          c.set('gender', that.get('campaign.gender').toLowerCase());
          c.set('info_demographic', that.get('campaign.target_regions_string'));
          c.set('interests', that.get('campaign.interests'));
          c.set('response_time', response_time);
          c.set('publish_brief_app', publish_date);
          c.set('open_until_date', moment(open_until_date, 'DD/MM/YY hh:mm').format('YYYY-MM-DDThh:mm:ss'));
          c.set('updated_date', moment().format('YYYY-MM-DDThh:mm:ss'));
          c.save().then(function (e) {
            if (e.isError) {
              that.get('applicationController').send('hideLoader');
              alert(_t.default.compute('campaign_fail'));
            } else {
              that.send('deleteMoodImages', that.id, cred, function () {
                that.send('pushMoodImages', that.id, mood_board, 0, cred, function () {
                  that.set('autoRedirect', true);
                  that.get('applicationController').send('submitAnimationDone', function () {
                    setTimeout(function () {
                      that.get('applicationController').send('hideLoader');
                      that.transitionToRoute('mycampaign');
                    }, 1000);
                  });
                });
              });
            }
          }, function (e) {});
        }, function (e) {}); // ----------------- Testing ------------ //

        return;
        var campaign = {
          campaign: {
            user: user_id,
            content_love: this.get('campaign.content_we_love'),
            call_action: this.get('campaign.call_to_action'),
            pre_tags: tags,
            pub_guides: this.get('campaign.dos'),
            dont_dos: this.get('campaign.donts'),
            mood_board: mood_board,
            platform_insta: this.get('campaign.instagram'),
            platform_twitter: this.get('campaign.twitter'),
            platform_fb: this.get('campaign.facebook'),
            hero_image_url: this.get('campaign.hero_image_url'),
            campaign_logo_url: this.get('campaign.campaign_logo_url'),
            brand_name: this.get('campaign.brand_name'),
            brand_comp_name: this.get('campaign.brand_comp_name'),
            pro_comp_name: this.get('campaign.campaign_name'),
            pro_desc: this.get('campaign.product_description'),
            campaign_link: this.get('campaign.link'),
            where_purchase: this.get('campaign.where_to_purchase'),
            ageMin: this.get('campaign.age_min'),
            ageMax: this.get('campaign.age_max'),
            gender: this.get('campaign.gender').toLowerCase(),
            info_demographic: this.get('campaign.target_regions_string'),
            interests: this.get('campaign.interests'),
            post_submissions: 0,
            posts_accepted: 0,
            response_time: response_time,
            reviewed: false,
            // "response_time_date": "",
            publish_brief_app: publish_date,
            open_until_date: moment(open_until_date, 'DD/MM/YY hh:mm').format('YYYY-MM-DDThh:mm:ss'),
            created_datetime: moment().format('YYYY-MM-DDThh:mm:ss'),
            updated_date: moment().format('YYYY-MM-DDThh:mm:ss')
          }
        };
        var cred = '';

        if (this.get('session.isAuthenticated')) {
          cred = this.get('session.data.authenticated.token_type') + ' ' + this.get('session.data.authenticated.access_token');
        } else {
          var email = (0, _jquery.default)('#email').val();
          var pass = (0, _jquery.default)('#password').val();
          cred = 'Basic ' + btoa(email + ':' + pass);
        }

        _jquery.default.ajax({
          type: 'POST',
          url: _environment.default.APP.API_HOST + '/campaigns',
          data: JSON.stringify(campaign),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', cred);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res && res.campaign) {
              cb(res.campaign.id);
            } else {
              cb();
            }
          },
          error: function (e, exception) {
            cb();
            alert(_t.default.compute('campaign_fail'));
          }
        });
      },

      pushMoodImages(campaign_id, mood_board, index, cred, cb) {
        if (index < mood_board.length) {
          var data = {
            campaign_id: campaign_id,
            mood_image: mood_board[index]
          };
          var that = this;

          _jquery.default.ajax({
            type: 'POST',
            url: _environment.default.APP.API_HOST + '/addcampaignmoodimage',
            data: data,
            beforeSend: function (xhr) {
              xhr.setRequestHeader('Authorization', cred);
              xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            },
            success: function (res) {
              that.send('pushMoodImages', campaign_id, mood_board, index + 1, cred, cb);
            },
            error: function (e, exception) {
              that.send('pushMoodImages', campaign_id, mood_board, index + 1, cred, cb);
            }
          });
        } else {
          cb();
        }
      },

      removeUploadedImage(f, e) {
        if (f == 1) {
          this.set('campaign.hero_image', '');
          (0, _jquery.default)('#hero_input_1').val('');
          this.set('isCroppedHeroImageActived', false);
        } else if (f == 2) {
          this.set('campaign.logo', '');
          (0, _jquery.default)('#hero_input_2').val('');
          this.set('isCroppedLogoImageActived', false);
        }

        e.preventDefault();
        e.stopPropagation();
      },

      removeUploadedMood(f, e) {
        var key = 'campaign.file_input_' + f + '_label';
        this.set(key, '');
        (0, _jquery.default)('#file_input_' + f).val('');
        e.preventDefault();
        e.stopPropagation();
      },

      addGuideline(flag) {
        if (flag == 1) {
          //dos
          var dos = (0, _jquery.default)('#pub_guides').val();

          if (dos) {
            (0, _jquery.default)('#dos_list').prepend('<li><p>' + dos + " </p><span onclick='jQuery(this).parent().remove()'>X</span></li>");
            (0, _jquery.default)('#pub_guides').val('');
          }
        } else if (flag == 2) {
          //don'ts
          var donts = (0, _jquery.default)('#dont_dos').val();

          if (donts) {
            (0, _jquery.default)('#donts_list').prepend('<li><p>' + donts + " </p><span onclick='jQuery(this).parent().remove()'>X</span></li>");
            (0, _jquery.default)('#dont_dos').val('');
          }
        }
      }

    }
  });

  _exports.default = _default;
});