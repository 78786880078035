define("flytrendy/utils/dynamic-segment-resolver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = dynamicSegmentResolver;

  /* eslint-disable */
  function dynamicSegmentResolver(dynamicSegmentKey, allSegments, otherDynamicSegments) {
    /**
     * Uncomment the line below and open localhost:4200/sitemap.txt on your browser.
     * You will see what parameters are passed to this function in the console.
     */
    // console.log('dynamicSegmentResolver:', dynamicSegmentKey, allSegments, otherDynamicSegments);
    return [];
  }
});