define("flytrendy/templates/developer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eXvIQ6OX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"developer\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"developer-cont\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"title-cont\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"p\"],[12],[2,\"Developer Page\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"img\"],[14,\"src\",\"assets/logo-black-text.png\"],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"section\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"p\"],[12],[2,\"Build #\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[12],[2,\"Version 4.8\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"section\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"p\"],[14,0,\"done\"],[12],[2,\"In this build\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"ol\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"section\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"p\"],[14,0,\"todo\"],[12],[2,\"TO DO\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"ol\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[10,\"li\"],[12],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"date\"],[12],[2,\"26th June, 2018\"],[13],[2,\"\\n\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flytrendy/templates/developer.hbs"
    }
  });

  _exports.default = _default;
});