define("flytrendy/lang/lang", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    languages: _environment.default.PLATFORM.languages
  });

  _exports.default = _default;
});