define("flytrendy/services/store", ["exports", "@ember-data/store"], function (_exports, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MyStore extends _store.default {}

  _exports.default = MyStore;
});