define("flytrendy/controllers/influencer", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    observationDialog: null,
    currentProgress: 0,
    src: "",
    process: ["assets/influencer/1.png", "assets/influencer/2.png", "assets/influencer/3.png", "assets/influencer/4.png", "assets/influencer/5.png", "assets/influencer/6.png"],
    process_it: ["assets/influencer/1.png", "assets/influencer/2.png", "assets/influencer/3.png", "assets/influencer/4.png", "assets/influencer/5_it.png", "assets/influencer/6_it.png"],
    init: function () {
      this._super(...arguments);
    },
    actions: {
      onVideoClick() {
        var dialog = jQuery("#influencer-vid-dialog").modal({
          showClose: true,
          fadeDuration: 300,
          escapeClose: false,
          clickClose: false
        });

        if (this.observationDialog) {
          jQuery(this.observationDialog[0]).remove();
        }

        this.observationDialog = dialog;

        if (localStorage.getItem('lang') == "en") {
          this.set("src", "http://flytrendy.com/videos/influencers_eng_splash.mp4");
        } else {
          this.set("src", "http://flytrendy.com/videos/influencers.mp4");
        }

        var that = this;
        setTimeout(function () {
          jQuery(".close-modal ").click(function () {
            that.set("src", "");
          });

          if (that.get("applicationController.isMobileView")) {
            jQuery("#influencer-vid-dialog .vjs-big-play-button").click();
            jQuery("#influencer-vid-dialog .vjs-fullscreen-control").click();
          }
        }, 1000);
        return false;
      },

      appleAppTop() {
        dataLayer.push({
          'event': 'asyncAction',
          'gaHitType': 'event',
          'event_category': 'download_app',
          'event_action': 'pg top click Apple App store',
          'event_label': 'influencers'
        });
        window.open("https://itunes.apple.com/us/app/flytrendy-influencer/id1240289950?ls=1&mt=8");
      },

      androidAppTop() {
        dataLayer.push({
          'event': 'asyncAction',
          'gaHitType': 'event',
          'event_category': 'download_app',
          'event_action': 'pg top click Google Play Android store',
          'event_label': 'influencers'
        });
        window.open("https://play.google.com/store/apps/details?id=com.flytrendy.flytrendy");
      },

      appleAppBottom() {
        dataLayer.push({
          'event': 'asyncAction',
          'gaHitType': 'event',
          'event_category': 'download_app',
          'event_action': 'pg bottom click Apple App store',
          'event_label': 'influencers'
        });
        window.open("https://itunes.apple.com/us/app/flytrendy-influencer/id1240289950?ls=1&mt=8");
      },

      androidAppBottom() {
        dataLayer.push({
          'event': 'asyncAction',
          'gaHitType': 'event',
          'event_category': 'download_app',
          'event_action': 'pg bottom click Google Play Android store',
          'event_label': 'influencers'
        });
        window.open("https://play.google.com/store/apps/details?id=com.flytrendy.flytrendy");
      },

      hover(index, e) {
        this.set("currentProgress", index);
        var image = this.get("process")[index];

        if (localStorage.getItem('lang') != "en") {
          image = this.get("process_it")[index];
        }

        jQuery("#influencer-process").attr("src", image);
      },

      progressPrev() {
        var index = this.get("currentProgress");
        if (index == 0) return;
        index = index - 1;
        var image = this.get("process")[index];

        if (localStorage.getItem('lang') != "en") {
          image = this.get("process_it")[index];
        }

        jQuery("#influencer-process").attr("src", image);
        this.set("currentProgress", index);
      },

      progressNext() {
        var index = this.get("currentProgress");
        if (index == 5) return;
        index = index + 1;
        var image = this.get("process")[index];

        if (localStorage.getItem('lang') != "en") {
          image = this.get("process_it")[index];
        }

        jQuery("#influencer-process").attr("src", image);
        this.set("currentProgress", index);
      }

    }
  });

  _exports.default = _default;
});