define("flytrendy/routes/mycampaignoverview", ["exports", "jquery", "flytrendy/models/objects/campaign", "flytrendy/models/objects/campaignpost", "flytrendy/config/environment", "flytrendy/helpers/t"], function (_exports, _jquery, _campaign, _campaignpost, _environment, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: _t.default.compute('title_my_campaign_overview'),
    session: Ember.inject.service('session'),
    refreshFlag: true,
    id: null,
    campaign_wallet: 0,
    campaign_spent: 0,
    metaTags: [{
      tagName: _t.default.compute('seo_description'),
      tagContent: _t.default.compute('title_my_campaign_overview')
    }, {
      tagName: _t.default.compute('seo_keywords'),
      tagContent: _t.default.compute('seo_content')
    }],

    beforeModel(o) {
      if (!this.get('session.isAuthenticated')) {
        if (o.queryParams.id) {
          this.set('applicationController.redirect', 'mycampaignoverview');
          this.set('applicationController.redirect_id', o.queryParams.id);

          if (o.queryParams.post) {
            this.set('applicationController.redirect_post', o.queryParams.post);
          } else {
            this.set('applicationController.redirect_post', null);
          }
        } else {
          this.set('applicationController.redirect_post', null);
          this.set('applicationController.redirect_post', null);
        }

        this.transitionTo('login');
      }
    },

    afterModel() {
      this.set('refreshFlag', true); // this.refreshData();
    },

    model(params) {
      this.set('id', params.id);
      return Ember.RSVP.hash({
        myCampaign: this.store.findRecord('campaign', params.id) // brand: this.store.query('brand', {}),
        // myCampaignPosts: this.store.query('campaignpost', {campaign: params.id}),

      });
    },

    actions: {
      willTransition(transition) {//this.set("refreshFlag", false);
      },

      // loading(transition, originRoute) {
      //   return true; // allows the loading template to be shown
      // }
      error(error, transition) {
        if (!error.errors && !this.get('session.isAuthenticated')) {
          this.transitionTo('login');
        } else if (error.errors[0].status == 404) {
          this.transitionTo('404');
        }
      }

    },
    refreshData: function () {
      if (this.get('refreshFlag')) {
        var that = this;
        var controller = this.controllerFor('mycampaignoverview');
        setTimeout(function () {
          if (!that.id) {
            that.set('refreshFlag', false);
          } else {
            var userauth = that.get('session.data.authenticated.token_type') + ' ' + that.get('session.data.authenticated.access_token');

            _jquery.default.ajax({
              type: 'GET',
              url: _environment.default.APP.API_HOST + '/campaignpostupdatedate?campaign_id=' + that.id,
              dataType: 'json',
              beforeSend: function (xhr) {
                xhr.setRequestHeader('Authorization', userauth);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
              },
              success: function (res) {
                if (res && res.campaignPostUpdateDate && res.campaignPostUpdateDate.success) {
                  var refreshDate = new Date(res.campaignPostUpdateDate.update_date);
                  var currentRefreshDate = new Date(controller.get('data.updated_date')); // FIRST TIME :: currentRefreshDate is an hour behind
                  // so this will refresh even if none of the posts has been updated

                  if (refreshDate > currentRefreshDate) {
                    that.getUpdatedPost(function () {
                      controller.set('data.updated_date', res.campaignPostUpdateDate.update_date);
                      setTimeout(function () {
                        (0, _jquery.default)('.lazy').Lazy({
                          appendScroll: (0, _jquery.default)('.post-cont')
                        });
                      }, 1000);
                    });
                  }
                }
              },
              error: function (jqXHR, exception) {
                console.log(jqXHR);
              }
            });
          }

          that.refreshData();
        }, 60000); // every 1 minute
      }
    },
    getUpdatedPost: function (cb) {
      var that = this;
      var controller = this.controllerFor('mycampaignoverview');
      var brand_country = this.get('session.data.brand_origin');

      if (!that.id) {
        that.set('refreshFlag', false);
        cb();
      } else {
        var posts = that.store.query('campaignpost', {
          campaign: that.id
        }).then(function (data) {
          var obj = null,
              postList = [];
          var postsByIds = {},
              postObj = null;
          var preLoadedPostsById = controller.get('postsByIds');

          for (var i = 0; i < data.content.length; i++) {
            obj = data.objectAt(i);
            postObj = _campaignpost.default.create(obj.data);
            postObj.set('brand_country', brand_country);
            postObj.set('data_id', obj.id);

            if (preLoadedPostsById && preLoadedPostsById[obj.id]) {
              postObj.set('post_image', preLoadedPostsById[obj.id].post_image);
            }

            postObj.setPostImage(that, true);
            postObj.setData();
            postList.push(postObj);
            postsByIds[obj.id] = postObj;
          }

          controller.set('posts', postList);
          controller.set('filteredPosts', postList);
          controller.send('onCloseContactBrand');
          controller.set('postsByIds', postsByIds);
          controller.send('sortPosts', null);
          controller.send('filterByPlatform');

          if (controller.get('postClickId')) {
            controller.set('selectedPostObject', postsByIds[controller.get('postClickId')]);
          }

          var p = 0,
              s = 0,
              d = 0,
              a = 0,
              obj = null;
          controller.set('totalPending_updated', false);
          controller.set('totalApproved_updated', false);
          controller.set('totalSubmitted_updated', false);
          controller.set('totalDeclined_updated', false);

          for (var i = 0; i < postList.length; i++) {
            obj = postList[i];

            if (obj.status == 'approved') {
              a++;

              if (!obj.seen) {
                controller.set('totalApproved_updated', true);
              }
            }

            if (obj.status == 'published') {
              s++;

              if (!obj.seen) {
                controller.set('totalSubmitted_updated', true);
              }
            }

            if (obj.status == 'pending') {
              p++;

              if (!obj.seen) {
                controller.set('totalPending_updated', true);
              }
            }

            if (obj.status == 'declined') {
              d++;

              if (!obj.seen) {
                controller.set('totalDeclined_updated', true);
              }
            }
          }

          var n_tp = that.abbreviate_number(p, 0);
          var n_ta = that.abbreviate_number(a, 0);
          var n_ts = that.abbreviate_number(s, 0);
          var n_td = that.abbreviate_number(d, 0);
          controller.set('totalPending', n_tp);
          controller.set('totalApproved', n_ta);
          controller.set('totalSubmitted', n_ts);
          controller.set('totalDeclined', n_td);
          cb();
        });
      }
    },
    abbreviate_number: function (num, fixed) {
      if (num === null) {
        return 0;
      }

      if (fixed == 0) {
        num = parseInt(num);
      } else if (fixed == 2) {
        num = parseInt(num);
      } else {
        num = parseFloat(num).toFixed(2);
      }

      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    },
    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      controller.set('current_spend', 0);
      const brandId = this.get('session.data.brand_id');
      this.store.findRecord('brand', brandId).then(function (brand) {
        controller.set('is_agency', brand.is_agency);
      });
      var userauth = this.get('session.data.authenticated.token_type') + ' ' + this.get('session.data.authenticated.access_token');
      var that = this;

      _jquery.default.ajax({
        type: 'GET',
        url: _environment.default.APP.API_HOST + '/campaignposts?campaign=' + this.get('id'),
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', userauth);
          xhr.setRequestHeader('Content-Type', 'application/json');
        },
        success: function (res) {
          if (res && res.campaignPost) {
            that.loadPosts(controller, res.campaignPost);
          }
        },
        error: function (jqXHR, exception) {}
      });

      controller.set('analyticsData.is_finished', false);
      controller.set('isCompletedPostsLoading', true);
      controller.set('filteredPosts', []); // Analytics

      _jquery.default.ajax({
        type: 'GET',
        url: _environment.default.APP.API_HOST + '/analytics?id=' + this.get('id'),
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', userauth);
          xhr.setRequestHeader('Content-Type', 'application/json');
        },
        success: function (res) {
          if (res) {
            controller.set('analyticsData', res);
            controller.set('analyticsData.is_finished', true);
            var potential_reach_total = res['campaignAnalytics']['potential_reach_total'];
            var media_spent_including_tech_fee = res['campaignAnalytics']['media_spent_including_tech_fee'];

            function abbreviate_number(num, fixed) {
              if (num === null) {
                return 0;
              }

              if (fixed == 0) {
                num = parseInt(num);
              } else if (fixed == 2) {
                num = parseInt(num);
              } else {
                num = parseFloat(num).toFixed(2);
              }

              return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
            }

            controller.set('potentialReach', abbreviate_number(potential_reach_total, 2));
            controller.set('mediaSpend', abbreviate_number(media_spent_including_tech_fee, 2));
          }
        },
        error: function (jqXHR, exception) {// controller.set("analyticsData", []);
        }
      }); // end Analytics


      var obj = model.myCampaign;

      var campObj = _campaign.default.create(obj.data);

      campObj.set('data_id', obj.id); //campObj.setHeroImage(this);

      this.set('campaign_wallet', campObj.campaign_wallet);
      this.set('campaign_spent', campObj.campaign_spent);
      controller.set('data', campObj);
      controller.send('onLoading');
    },
    loadPosts: function (controller, posts) {
      var brand_country = this.get('session.data.brand_origin');
      var obj = null,
          campaignList = [],
          postList = [];
      var postsByIds = {},
          campObj = null,
          postObj = null,
          currentSpend = 0,
          reach = 0;
      var p = 0,
          s = 0,
          d = 0,
          a = 0;
      var potential_reach_user = {};
      controller.set('totalPending_updated', false);
      controller.set('totalApproved_updated', false);
      controller.set('totalSubmitted_updated', false);
      controller.set('totalDeclined_updated', false);

      for (var i = 0; i < posts.length; i++) {
        obj = posts[i];
        postObj = _campaignpost.default.create(obj);

        if (postObj.staff_status == 'pending' || postObj.staff_status == 'declined') {
          continue;
        }

        postObj.set('brand_country', brand_country);
        postObj.set('data_id', obj.id);
        postObj.setPostImage(this);
        postObj.setData();
        postList.push(postObj);
        postsByIds[obj.id] = postObj;

        if (postObj.status == 'approved' || postObj.status == 'published') {
          currentSpend = currentSpend + parseFloat(postObj.grand_total);
        }

        if (postObj.status == 'approved' || postObj.status == 'published' || postObj.status == 'pending') {
          if (obj.social_account_info) {
            if (!potential_reach_user[obj.social_account_info.username + '_' + obj.post_social_type]) {
              reach = reach + obj.social_account_info.followers;
            }

            potential_reach_user[obj.social_account_info.username + '_' + obj.post_social_type] = obj.social_account_info.followers;
          }
        }

        if (postObj.status == 'approved') {
          a++;

          if (!postObj.seen) {
            controller.set('totalApproved_updated', true);
          }
        }

        if (postObj.status == 'published') {
          s++;

          if (!postObj.seen) {
            controller.set('totalSubmitted_updated', true);
          }
        }

        if (postObj.status == 'pending') {
          p++;

          if (!postObj.seen) {
            controller.set('totalPending_updated', true);
          }
        }

        if (postObj.status == 'declined') {
          d++;

          if (!postObj.seen) {
            controller.set('totalDeclined_updated', true);
          }
        }

        if (controller.get('post') && controller.get('post') == obj.id) {
          controller.set('purchasePostObject', postObj);
        }
      }

      controller.set('campaign_id', +this.get('id'));
      controller.set('posts', postList);
      controller.set('filteredPosts', postList);
      controller.set('isCompletedPostsLoading', false); // reset filters to selected state

      controller.set('filterPending', true);
      controller.set('filterApproved', true);
      controller.set('filterDeclined', true);
      controller.set('filterSubmitted', true);
      controller.send('onCloseContactBrand');
      controller.set('postsByIds', postsByIds); //var techFee = (35 / 100) * currentSpend; // append 35% tech fee
      //var currentSpend = currentSpend + techFee; // to currentSpend

      controller.set('current_spend', this.abbreviate_number(currentSpend, 1));
      controller.set('potential_reach', this.abbreviate_number(reach, 2));
      controller.set('totalPending', this.abbreviate_number(p, 0));
      controller.set('totalApproved', this.abbreviate_number(a, 0));
      controller.set('totalSubmitted', this.abbreviate_number(s, 0));
      controller.set('totalDeclined', this.abbreviate_number(d, 0));
      var total_posts = p + a + s + d;
      controller.set('totalPosts', this.abbreviate_number(total_posts, 0));
      var total_posts_approved_published = a + s;
      controller.set('totalApprovedPublished', this.abbreviate_number(total_posts_approved_published, 0));
      controller.set('campaign_budget', this.abbreviate_number(this.get('campaign_wallet'), 1));
      controller.set('campaign_spent', this.abbreviate_number(currentSpend, 1));
      controller.send('sortPosts', null);
    }
  });

  _exports.default = _default;
});