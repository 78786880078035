define("flytrendy/templates/clients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FuM0wAXV",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"aboutus\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"aboutus-cont\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"title-cont\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"p\"],[12],[1,[30,[36,0],[\"h_clients\"],null]],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"content clients-logos\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"main-data-cont\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"data-cont\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"img\"],[14,\"src\",\"assets/clients.png\"],[12],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "flytrendy/templates/clients.hbs"
    }
  });

  _exports.default = _default;
});