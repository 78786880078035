define("flytrendy/controllers/splash", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    observationDialog: null,
    src: '',
    users: 12801,
    instagram_followers: 36331628,
    facebook_followers: 3892325,
    twitter_followers: 697518,
    init: function () {
      this._super(...arguments);

      if (this.get('session.isAuthenticated')) {//this.transitionToRoute('mycampaign');
        //TODO: commented to prevent redirect to
        // mycampaign everytime, needs to be tested
      }

      setTimeout(function () {
        jQuery('#abu_slider').slick({
          autoplay: true,
          arrows: true,
          infinite: true,
          dots: true,
          autoplaySpeed: 3000,
          centerPadding: 0,
          speed: 1000
        });
      }, 100);
    },
    actions: {
      showStats() {
        jQuery('.counting').each(function () {
          var $this = jQuery(this),
              countTo = $this.attr('data-count');
          jQuery({
            countNum: $this.text()
          }).animate({
            countNum: countTo
          }, {
            duration: 5000,
            easing: 'linear',
            step: function () {
              $this.text(commaSeparateNumber(Math.floor(this.countNum)));
            },
            complete: function () {
              $this.text(commaSeparateNumber(this.countNum));
            }
          });
        });

        function commaSeparateNumber(val) {
          while (/(\d+)(\d{3})/.test(val.toString())) {
            val = val.toString().replace(/(\d+)(\d{3})/, '$1' + '.' + '$2');
          }

          return val;
        }
      },

      newCampaign() {
        dataLayer.push({
          event: 'asyncAction',
          gaHitType: 'event',
          event_category: 'new campaign',
          event_action: 'click start for free button in home page footer',
          event_label: 'homepage'
        });
        this.transitionToRoute('newcampaign');
      },

      onVideoClick() {
        var dialog = jQuery('#splash-vid-dialog').modal({
          showClose: true,
          fadeDuration: 300,
          escapeClose: false,
          clickClose: false
        });

        if (this.observationDialog) {
          jQuery(this.observationDialog[0]).remove();
        }

        if (localStorage.getItem('lang') == 'en') {
          this.set('src', 'http://flytrendy.com/videos/brand_eng.mp4');
        } else {
          this.set('src', 'http://flytrendy.com/videos/brands.mp4');
        }

        var that = this;
        setTimeout(function () {
          jQuery('.close-modal ').click(function () {
            that.set('src', '');
          });

          if (that.get('applicationController.isMobileView')) {
            jQuery('#splash-vid-dialog .vjs-big-play-button').click();
            jQuery('#splash-vid-dialog .vjs-fullscreen-control').click();
          }
        }, 1000);
        this.observationDialog = dialog;
        return false;
      },

      appleApp() {
        dataLayer.push({
          event: 'asyncAction',
          gaHitType: 'event',
          event_category: 'download_app',
          event_action: 'click Apple App store',
          event_label: 'homepage'
        });
        window.open('https://itunes.apple.com/us/app/flytrendy-influencer/id1240289950?ls=1&mt=8');
      },

      androidApp() {
        dataLayer.push({
          event: 'asyncAction',
          gaHitType: 'event',
          event_category: 'download_app',
          event_action: 'click Google Play Android store',
          event_label: 'homepage'
        });
        window.open('https://play.google.com/store/apps/details?id=com.flytrendy.flytrendy');
      }

    }
  });

  _exports.default = _default;
});