define("flytrendy/controllers/faq", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    filterSelectedG: false,
    filterSelectedB: false,
    filterSelectedI: false,
    observationDialog: null,
    src: '',
    poster: '',
    init: function () {
      this._super(...arguments);

      this.send('onFilterSelection', 1);
    },
    actions: {
      onVideoClick(flag) {
        if (flag == 1) {
          if (localStorage.getItem('lang') == 'en') {
            this.set('src', 'http://flytrendy.com/videos/brand_eng.mp4');
          } else {
            this.set('src', 'http://flytrendy.com/videos/brands.mp4');
          }

          this.set('poster', 'assets/brand-poster.png');
        } else if (flag == 2) {
          if (localStorage.getItem('lang') == 'en') {
            this.set('src', 'http://flytrendy.com/videos/influencers_eng_splash.mp4');
          } else {
            this.set('src', 'http://flytrendy.com/videos/influencers.mp4');
          }

          this.set('poster', 'assets/influencer-poster.png');
        }

        var dialog = jQuery('#faq-vid-dialog').modal({
          showClose: true,
          fadeDuration: 300,
          escapeClose: false,
          clickClose: false
        });

        if (this.observationDialog) {
          jQuery(this.observationDialog[0]).remove();
        }

        this.observationDialog = dialog;
        var that = this;
        setTimeout(function () {
          jQuery('.close-modal ').click(function () {
            that.set('src', '');
            that.set('poster', '');
          });

          if (that.get('applicationController.isMobileView')) {
            jQuery('#faq-vid-dialog .vjs-big-play-button').click();
            jQuery('#faq-vid-dialog .vjs-fullscreen-control').click();
          }
        }, 1000);
        return false;
      },

      onFilterSelection(f, e) {
        jQuery('.faq .option-cont .button').removeClass('selected');

        if (e) {
          jQuery(e.target).addClass('selected');
        }

        this.set('filterSelectedG', false);
        this.set('filterSelectedB', false);
        this.set('filterSelectedI', false);

        switch (f) {
          case 1:
            this.set('filterSelectedG', true);
            break;

          case 2:
            this.set('filterSelectedB', true);
            break;

          case 3:
            this.set('filterSelectedI', true);
            break;
        }
      }

    }
  });

  _exports.default = _default;
});