define("flytrendy/controllers/termsandconditions", ["exports", "jquery", "flytrendy/config/environment", "flytrendy/helpers/tnc"], function (_exports, _jquery, _environment, _tnc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    queryParams: ['filter'],
    filter: null,
    init: function () {
      this._super(...arguments);
    },
    actions: {
      tncUpdate(filter) {
        (0, _jquery.default)('#termscontent').html('Loading...');
        (0, _jquery.default)('html,body').scrollTop(0);
        setTimeout(function () {
          _tnc.default.compute(filter, function (x) {
            (0, _jquery.default)('#termscontent').html(x);
          });
        }, 500);
      }

    }
  });

  _exports.default = _default;
});