define("flytrendy/models/campaignpost", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _model.default.extend({
    campaign: _emberData.default.attr(),
    data_id: _emberData.default.attr(),
    user: _emberData.default.attr(),
    post_social_type: _emberData.default.attr(),
    engagement_rate: _emberData.default.attr(),
    social_account_info: _emberData.default.attr(),
    post_copy: _emberData.default.attr(),
    post_image: _emberData.default.attr(),
    post_price: _emberData.default.attr(),
    status: _emberData.default.attr(),
    seen: _emberData.default.attr(),
    brand_feedback: _emberData.default.attr(),
    created_datetime: _emberData.default.attr(),
    updated_date: _emberData.default.attr(),
    post_discount: _emberData.default.attr(),
    video_url: _emberData.default.attr()
  });

  _exports.default = _default;
});