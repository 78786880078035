define("flytrendy/controllers/mycampaign", ["exports", "jquery", "flytrendy/config/environment", "flytrendy/helpers/t"], function (_exports, _jquery, _environment, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    notify: Ember.inject.service('notify'),
    data: [],
    sf_data_active: [],
    sf_data_inactive: [],
    draftCampaign: [],
    completedCampaign: [],
    isDraft: false,
    isActiveCampaignLoading: true,
    isCompletedCampaignLoading: true,
    init: function () {
      this._super(...arguments);
    },
    actions: {
      onLoading() {
        this.set('isActiveCampaignLoading', false);
        this.set('isCompletedCampaignLoading', false);
      },

      toggleDraftCampaign() {
        (0, _jquery.default)('#collapsable_draft').slideToggle();
      },

      toggleActiveCampaign() {
        (0, _jquery.default)('#collapsable_active').slideToggle();
      },

      toggleCompletedCampaign() {
        (0, _jquery.default)('#collapsable_completed').slideToggle();
      },

      deleteDraft(id) {
        var deleteCampaign = confirm(_t.default.compute('mc_desc_8'));

        if (deleteCampaign) {
          var campaignlist = JSON.parse(localStorage.getItem('campaignlist'));
          delete campaignlist[id];
          localStorage.setItem('campaignlist', JSON.stringify(campaignlist));
          var temp = [];

          for (var key in campaignlist) {
            if (campaignlist[key]) {
              temp.push(JSON.parse(campaignlist[key]));
            }
          }

          if (temp.length > 0) {
            this.set('draftCampaign', temp);
            this.set('isDraft', true);
          } else {
            this.set('draftCampaign', []);
            this.set('isDraft', false);
          }

          this.get('notify').alert({
            html: '<div>' + _t.default.compute('mc_desc_9') + '</div>'
          });
        }
      },

      resetSearch() {
        (0, _jquery.default)('#search_text').val('');
        (0, _jquery.default)('.filter-container input').prop('checked', false);
        this.send('onSearch');
      },

      onSearch() {
        var search_text = (0, _jquery.default)('#search_text').val();
        var active_data = this.get('data'),
            inactive_data = this.get('completedCampaign');
        var sf_data_active = [],
            sf_data_inactive = [];

        if (search_text.length == 0) {
          sf_data_active = active_data;
          sf_data_inactive = inactive_data;
        } else {
          search_text = search_text.toLowerCase().trim(); // Search text (name and description)

          for (var i = 0; i < active_data.length; i++) {
            if (active_data[i].pro_comp_name.toLowerCase().trim().includes(search_text) || active_data[i].pro_desc.toLowerCase().trim().includes(search_text)) {
              sf_data_active.push(active_data[i]);
            }
          }

          for (var i = 0; i < inactive_data.length; i++) {
            if (inactive_data[i].pro_comp_name.toLowerCase().trim().includes(search_text) || inactive_data[i].pro_desc.toLowerCase().trim().includes(search_text)) {
              sf_data_inactive.push(inactive_data[i]);
            }
          }
        }

        this.set('sf_data_active', sf_data_active);
        this.set('sf_data_inactive', sf_data_inactive);
        this.send('filterTerritory');
        this.send('filterInterest');
        return false;
      },

      filterTerritory() {
        var territory_list = [];
        var active_data = this.get('sf_data_active'),
            inactive_data = this.get('sf_data_inactive');
        var sf_data_active = [],
            sf_data_inactive = [];

        if ((0, _jquery.default)('#t-italy').is(':checked')) {
          territory_list.push('italy');
        }

        if ((0, _jquery.default)('#t-switzerland').is(':checked')) {
          territory_list.push('switzerland');
        }

        if (territory_list.length > 0) {
          var sf_data_active_dict = {},
              sf_data_inactive_dict = {};

          for (var i = 0; i < territory_list.length; i++) {
            for (var j = 0; j < active_data.length; j++) {
              if (active_data[j].info_demographic.toLowerCase().includes(territory_list[i])) {
                if (!sf_data_active_dict[active_data[j].data_id]) {
                  sf_data_active.push(active_data[j]);
                  sf_data_active_dict[active_data[j].data_id] = active_data[j].data_id;
                }
              }
            }

            for (var j = 0; j < inactive_data.length; j++) {
              if (inactive_data[j].info_demographic.toLowerCase().includes(territory_list[i])) {
                if (!sf_data_inactive_dict[inactive_data[j].data_id]) {
                  sf_data_inactive.push(inactive_data[j]);
                  sf_data_inactive_dict[inactive_data[j].data_id] = inactive_data[j].data_id;
                }
              }
            }
          }
        } else {
          sf_data_active = active_data;
          sf_data_inactive = inactive_data;
        }

        this.set('sf_data_active', sf_data_active);
        this.set('sf_data_inactive', sf_data_inactive);
      },

      filterInterest() {
        var interest_list = [];
        var active_data = this.get('sf_data_active'),
            inactive_data = this.get('sf_data_inactive');
        var sf_data_active = [],
            sf_data_inactive = [];

        if ((0, _jquery.default)('#t-animal').is(':checked')) {
          interest_list.push('animals');
        }

        if ((0, _jquery.default)('#t-music-movies').is(':checked')) {
          interest_list.push('Music & Movies');
        }

        if ((0, _jquery.default)('#t-photography').is(':checked')) {
          interest_list.push('Photography');
        }

        if ((0, _jquery.default)('#t-social-family').is(':checked')) {
          interest_list.push('Social & Family');
        }

        if ((0, _jquery.default)('#t-travel-car').is(':checked')) {
          interest_list.push('Travel & Cars');
        }

        if ((0, _jquery.default)('#t-ed-tech').is(':checked')) {
          interest_list.push('Education & Technology');
        }

        if ((0, _jquery.default)('#t-books-mag-games').is(':checked')) {
          interest_list.push('Books, Magazines & Games');
        }

        if ((0, _jquery.default)('#t-food-lifestyle-ev').is(':checked')) {
          interest_list.push('Food, Lifestyle & Events');
        }

        if ((0, _jquery.default)('#t-health').is(':checked')) {
          interest_list.push('Sports, Fitness & Wellbeing');
        }

        if ((0, _jquery.default)('#t-et-shop').is(':checked')) {
          interest_list.push('Online Shopping & Entertainment');
        }

        if (interest_list.length > 0) {
          var sf_data_active_dict = {},
              sf_data_inactive_dict = {};

          for (var i = 0; i < interest_list.length; i++) {
            for (var j = 0; j < active_data.length; j++) {
              if (active_data[j].interests.toString().toLowerCase().includes(interest_list[i].toLowerCase())) {
                if (!sf_data_active_dict[active_data[j].data_id]) {
                  sf_data_active.push(active_data[j]);
                  sf_data_active_dict[active_data[j].data_id] = active_data[j].data_id;
                }
              }
            }

            for (var j = 0; j < inactive_data.length; j++) {
              if (inactive_data[j].interests.toString().toLowerCase().includes(interest_list[i].toLowerCase())) {
                if (!sf_data_inactive_dict[inactive_data[j].data_id]) {
                  sf_data_inactive.push(inactive_data[j]);
                  sf_data_inactive_dict[inactive_data[j].data_id] = inactive_data[j].data_id;
                }
              }
            }
          }
        } else {
          sf_data_active = active_data;
          sf_data_inactive = inactive_data;
        }

        this.set('sf_data_active', sf_data_active);
        this.set('sf_data_inactive', sf_data_inactive);
      }

    }
  });

  _exports.default = _default;
});