define("flytrendy/models/objects/countries", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    countries: _environment.default.PLATFORM.countries
  });

  _exports.default = _default;
});