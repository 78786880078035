define("flytrendy/models/objects/influencer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    user: "",
    email: "",
    phone: "",
    gender: "",
    home_country: "",
    dob: "",
    shipping_address: "",
    interests: "",
    favourites: "",
    instagram: "",
    twitter: "",
    facebook: "",
    agent_name: "",
    agent_phone: "",
    agent_email: "",
    stripe_token: "",
    created_datetime: "",
    updated_date: "",
    getSomeThing: Ember.computed(function () {})
  });

  _exports.default = _default;
});