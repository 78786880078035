define("flytrendy/templates/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9GVbO9ml",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"pagenotfound\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"title\"],[12],[2,\"404\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"subtitle\"],[12],[2,\"Page not found\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"desc\"],[12],[2,\"The page you are looking for doesn't exist or an other error occurred!\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"subdesc\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"activeClass\",\"route\"],[\"active\",\"splash\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\tGo back\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "flytrendy/templates/404.hbs"
    }
  });

  _exports.default = _default;
});