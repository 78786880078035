define("flytrendy/controllers/contact", ["exports", "jquery", "flytrendy/config/environment", "flytrendy/helpers/t"], function (_exports, _jquery, _environment, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    notify: Ember.inject.service('notify'),
    contactUsSubject: _environment.default.PLATFORM.contactus.defaultSubject,
    init: function () {
      this._super(...arguments);
    },
    actions: {
      onContactUs() {
        var name = (0, _jquery.default)('#contact_name').val();
        var email = (0, _jquery.default)('#contact_email').val();
        var company = (0, _jquery.default)('#contact_company').val();
        const subject = this.contactUsSubject ? this.contactUsSubject : (0, _jquery.default)('#contact_need option:selected').text();
        var message = (0, _jquery.default)('#contact_message').val();
        var that = this;

        if (!name) {
          alert('Your name is required.');
          return;
        }

        if (!email) {
          alert('Your email is required.');
          return;
        }

        if (!message) {
          alert('Your message is required.');
          return;
        }

        that.get('applicationController').send('showLoader');
        var endpoint = _environment.default.APP.API_HOST + '/contact_us?name=' + name + '&email=' + email + '&company=' + company + '&subject=' + subject + '&message=' + message;

        _jquery.default.ajax({
          type: 'POST',
          data: {},
          url: encodeURI(endpoint),
          dataType: 'text',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            that.get('applicationController').send('hideLoader');
            res = JSON.parse(res);
            console.log(res);
            console.log(res.contactUsEmail);
            console.log(res.contactUsEmail.Success);

            if (res && res.contactUsEmail && res.contactUsEmail.Success) {
              that.get('notify').alert({
                html: '<div>' + _t.default.compute('contactus_success') + '</div>'
              });
            } else {
              that.get('notify').warning({
                html: '<div>' + _t.default.compute('contactus_fail') + '</div>'
              });
            }
          },
          error: function (jqXHR, exception) {
            that.get('applicationController').send('hideLoader');
            that.get('notify').warning({
              html: '<div>' + _t.default.compute('contactus_fail') + '</div>'
            });
          }
        });
      }

    }
  });

  _exports.default = _default;
});