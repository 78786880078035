define("flytrendy/controllers/resetpassword", ["exports", "jquery", "flytrendy/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    init: function () {
      this._super(...arguments);

      if (this.get('session.isAuthenticated')) {
        this.transitionToRoute('mycampaign');
      }
    },
    actions: {
      authenticate() {
        let {
          identification
        } = this.getProperties('identification');
        var that = this;

        if (identification) {
          (0, _jquery.default)('.resetpassword .loading').show();

          _jquery.default.ajax({
            type: 'GET',
            url: _environment.default.APP.API_HOST + '/resetpassword?email=' + identification,
            dataType: 'json',
            success: function (res) {
              (0, _jquery.default)('.resetpassword .loading').hide();

              if (res && res.success) {
                alert('Thanks! Please check ' + identification + ' for a link to reset your password.');
              } else {
                if (res && !res.success) {
                  alert(res.result);
                } else {
                  alert('Cannot connect to the server, please try again later!');
                }
              }
            },
            error: function (jqXHR, exception) {
              (0, _jquery.default)('.resetpassword .loading').hide();
              alert('Cannot connect to the server, please try again later!');
            }
          });
        } else {
          that.get('applicationController').send('hideLoader');
          alert('Email is required!');
        }
      }

    }
  });

  _exports.default = _default;
});