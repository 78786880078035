define("flytrendy/controllers/application", ["exports", "flytrendy/config/environment", "flytrendy/lang/lang", "jquery"], function (_exports, _environment, _lang, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    isLoginRoute: Ember.computed.equal('currentRouteName', 'login'),
    isResetPasswordRoute: Ember.computed.equal('currentRouteName', 'resetpassword'),
    isInfluencerRoute: Ember.computed.equal('currentRouteName', 'influencer'),
    isChangePasswordRoute: Ember.computed.equal('currentRouteName', 'changepassword'),
    isChangePasswordVisible: false,
    isServicesFeedback: Ember.computed.equal('currentRouteName', 'services/feedback'),
    is404: Ember.computed.equal('currentRouteName', '404'),
    currentRouteName: Ember.computed.reads('appController.currentRouteName'),
    notify: Ember.inject.service('notify'),
    identification: null,
    password: null,
    loading: false,
    reset: true,
    isLoggedin: false,
    walletDialog: null,
    insufficientWalletDialog: null,
    insufficientWalletCallback: null,
    availableFund: '',
    languages: null,
    lang: 'en',
    redirect: null,
    redirect_id: null,
    redirect_post: null,
    insufficient_amount: 0,
    isMobileView: false,
    paypal_flat_fee: 0.35,
    paypal_fee: 0.039,
    ls: 0,
    le: 0,
    success_path: `assets/platform-specific/${_environment.default.APP.platform_prefix}/submit-success.gif`,
    init: function () {
      this._super(...arguments);

      this.set('redirect', null);
      this.set('redirect_id', null);
      this.set('redirect_post', null);

      const languages = _lang.default.create({});

      this.set('languages', languages.languages);

      if (!localStorage.getItem('lang')) {
        let lang = navigator.language || navigator.userLanguage;
        lang = lang.split('-')[0].toLowerCase();

        if (!languages.languages[lang]) {
          lang = _environment.default.PLATFORM.defaultLanguage;
        }

        localStorage.setItem('lang', lang);
      }

      this.set('isMobileView', false);

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.set('isMobileView', true);
      }

      var that = this;
      setTimeout(function () {
        (0, _jquery.default)('#language').text(localStorage.getItem('lang'));
        (0, _jquery.default)('.tooltip-lang').tooltipster({
          animation: 'grow',
          delay: 100,
          theme: 'tooltipster-Borderless',
          interactive: true,
          side: 'top',
          contentAsHTML: true,
          content: 'Loading..',
          trigger: 'click',
          functionBefore: function (instance, helper) {
            var self = that;
            instance.content("<ul class='language-change-options'><li><img src='assets/flags/en.jpg' data-id='en' alt='Australia'></li><li><img data-id='it' src='assets/flags/it.jpg' alt='Italy'></li></ul>");
            setTimeout(function () {
              (0, _jquery.default)('.language-change-options li img').click(function (e) {
                self.onLanguageSelect((0, _jquery.default)(e.target).attr('data-id')); // instance.close();
              });
            }, 500);
          }
        });
      }, 500), this.set('lang', localStorage.getItem('lang'));
      new Image().src = 'assets/influencer/1.png';
      new Image().src = 'assets/influencer/2.png';
      new Image().src = 'assets/influencer/3.png';
      new Image().src = 'assets/influencer/4.png';
      new Image().src = 'assets/influencer/5.png';
      new Image().src = 'assets/influencer/6.png';
      new Image().src = 'assets/influencer/5_it.png';
      new Image().src = 'assets/influencer/6_it.png';
      new Image().src = 'assets/brand/1.png';
      new Image().src = 'assets/brand/2.png';
      new Image().src = 'assets/brand/3.png';
      new Image().src = 'assets/brand/4.png';
      new Image().src = 'assets/brand/5.png';
      new Image().src = 'assets/brand/6.png';
    },
    onLanguageSelect: function (lang) {
      localStorage.setItem('lang', lang);
      this.set('lang', lang);
      window.location.reload(true);
    },
    refreshFunds: function () {
      if (this.get('session.isAuthenticated')) {
        var that = this;
        setTimeout(function () {
          that.refreshFunds();
        }, 60000); // every 1 minute

        that.store.query('brand', {}).then(function (data) {
          if (data.content.length > 0) {
            var obj = data.get('firstObject');
            that.get('session').set('data.brand_id', obj.id);
            that.get('session').set('data.brand_company', obj.company);
            that.get('session').set('data.brand_origin', obj.origin);
            that.get('session').set('data.brand_funds', obj.funds);
            that.get('session').set('data.funds', that.abbreviate_number(obj.funds, 1));
          }
        });
      }
    },
    GTM: function () {
      // SCRIPT 1, GTM
      var that = this;
      var is_Loggedin = this.get('session.isAuthenticated');
      Ember.run.schedule('afterRender', this, function () {
        // console.log('***** MY DATALAYER: ' + is_Loggedin + that.currentRouteName + this.lang);
        dataLayer.push({
          userStatus: this.isLoggedin,
          siteSection: that.currentRouteName,
          storeLanguage: this.lang
        });
      });
    },
    abbreviate_number: function (num, fixed) {
      if (num === null) {
        return 0;
      }

      num = parseFloat(num).toFixed(2);
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    notificationMessage(type, message) {
      this.get('notify')[type]({
        html: message
      });
    },

    actions: {
      onLoading() {
        this.refreshFunds();
        this.GTM();
      },

      showUpdatePasswordForm() {
        this.set('isChangePasswordVisible', true);
      },

      updatePasswordSubUser() {
        const pass = (0, _jquery.default)('#pass').val();
        const pass_c = (0, _jquery.default)('#passc').val();
        const userId = this.get('session.data.user_id');

        if (!pass || !pass_c) {
          (0, _jquery.default)('#pass').addClass('err');
          (0, _jquery.default)('#passc').addClass('err');
          this.notificationMessage('warning', 'E’ richiesta la password!');
          return;
        }

        if (pass != pass_c) {
          (0, _jquery.default)('#pass').removeClass('err');
          (0, _jquery.default)('#passc').addClass('err');
          this.notificationMessage('warning', 'La conferma della password deve essere identica!');
          return;
        }

        const user = {
          user: {
            password: pass
          }
        };
        const that = this;
        const cred = this.get('session.data.authenticated.token_type') + ' ' + this.get('session.data.authenticated.access_token');

        _jquery.default.ajax({
          type: 'PATCH',
          url: _environment.default.APP.API_HOST + '/users/' + userId,
          data: JSON.stringify(user),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', cred);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res && res.user) {
              const user = res.user;
              that.set('user', user);
              that.get('session').authenticate('authenticator:oauth2', user.username, pass).then(result => {
                that.get('session').set('data.username', user.username);
                that.get('session').set('data.password', pass);
                that.notificationMessage('alert', '<div>Password aggiornata con successo!</div>');
                that.set('isChangePasswordVisible', false);
              }, err => {
                var user_token = that.get('session.data.authenticated.access_token');

                if (user_token) {
                  that.get('session').set('data.username', user.username);
                  that.get('session').set('data.password', pass);
                  that.notificationMessage('alert', '<div>Password aggiornata con successo!</div>');
                  that.set('isChangePasswordVisible', false);
                } else {
                  that.notificationMessage('warning', '<div>Impossibile connettersi al server, riprova più tardi!</div>');
                }
              });
            } else {
              this.notificationMessage('warning', '<div>Impossibile connettersi al server, riprova più tardi!</div>');
            }
          },
          error: function (e, exception) {
            this.notificationMessage('warning', '<div>Impossibile connettersi al server, riprova più tardi!</div>');
          }
        });
      },

      newCampaign() {
        var that = this;
        var current_route = that.currentRouteName;
        console.log('NEW CAMPAIGN BTN: ' + current_route);
        dataLayer.push({
          event: 'asyncAction',
          gaHitType: 'event',
          event_category: 'new campaign',
          event_action: 'click button in main header nav',
          event_label: current_route
        });
        this.transitionToRoute('newcampaign');
      },

      onHamburgerMenuClick() {
        if ((0, _jquery.default)('#hamburger_menu').attr('aria-expanded') && (0, _jquery.default)('#navbar').hasClass('in')) {
          setTimeout(function () {
            (0, _jquery.default)('#navbar').removeClass('in');
            (0, _jquery.default)('#hamburger_menu').attr('aria-expanded', false);
          }, 500);
        }
      },

      onLanguageChange(e) {
        var lang = (0, _jquery.default)('#language').val();
        localStorage.setItem('lang', lang);
        this.set('lang', lang);
        window.location.reload(true);
      },

      showLoader() {
        var h = (0, _jquery.default)(window).height();
        h = h / 2;
        (0, _jquery.default)('.loading-overlay .cont').css({
          marginTop: h
        });

        if (this.currentRouteName == 'newcampaign' || this.currentRouteName == 'draftcampaign' || this.currentRouteName == 'editcampaign') {
          (0, _jquery.default)('#submit-success-animation').attr('src', '');
          (0, _jquery.default)('.loading-overlay-submit .cont img').hide();
          (0, _jquery.default)('.loading-overlay-submit .cont .s-spinner').show();
          (0, _jquery.default)('.loading-overlay-submit').show();
        } else {
          (0, _jquery.default)('.loading-overlay').show();
        }
      },

      submitAnimationDone(cb) {
        (0, _jquery.default)('.loading-overlay-submit .cont .s-spinner').hide();
        (0, _jquery.default)('.loading-overlay-submit .cont img').show();
        (0, _jquery.default)('#submit-success-animation').attr('src', this.success_path);
        setTimeout(function () {
          cb();
        }, 3000);
      },

      hideLoader() {
        (0, _jquery.default)('.loading-overlay').hide();
        (0, _jquery.default)('.loading-overlay-submit').hide();
        (0, _jquery.default)('.check').attr('class', 'check');
        (0, _jquery.default)('.fill').attr('class', 'fill');
        (0, _jquery.default)('.path').attr('class', 'path');
      },

      logout() {
        this.set('session.data.brand_code', '');
        this.set('session.data.brand_company', '');
        this.set('session.data.brand_id', '');
        this.set('session.data.brand_name', '');
        this.set('session.data.brand_origin', '');
        this.set('session.data.username', '');
        this.set('session.data.password', '');
        this.set('session.data.user_id', '');
        this.set('session.data.is_additional_brand_user', '');
        this.get('session').invalidate();
        localStorage.setItem('campaignlist', JSON.stringify({}));
      },

      onWalletClick(e) {
        // IOL sub user don't get to see top up window
        if (this.get('session.data.is_sub_user')) {
          return;
        }

        var dialog = (0, _jquery.default)('#wallet-dialog').modal({
          showClose: true,
          fadeDuration: 300,
          escapeClose: false,
          clickClose: false
        });

        if (this.walletDialog) {
          (0, _jquery.default)(this.walletDialog[0]).remove();
        }

        this.walletDialog = dialog;
        setTimeout(function () {
          (0, _jquery.default)('.payment-section').show();
          (0, _jquery.default)('.payment-success-section').hide();
        }, 500);
        var that = this;
        setTimeout(function () {
          that.send('displayPaypalButton');
        }, 1000);
        return false;
      },

      displayPaypalButton() {
        var that = this;
        (0, _jquery.default)('.loading-paypal').show();
        (0, _jquery.default)('#wallet_paypal').text('');
        var amount = 50; //default

        if ((0, _jquery.default)('#paypal_new_amount').val()) {
          amount = (0, _jquery.default)('#paypal_new_amount').val();
        }

        (0, _jquery.default)('#wallet_topup_amount').text(amount);
        var pp_fee = (parseFloat(amount) * that.paypal_fee).toFixed(2);
        pp_fee = (parseFloat(pp_fee) + parseFloat(that.paypal_flat_fee)).toFixed(2);
        (0, _jquery.default)('#wallet_paypal_fee').text(pp_fee); // var final_amount = parseFloat(parseFloat(amount) + parseFloat(pp_fee));

        var final_amount = parseFloat(amount);
        final_amount = final_amount.toFixed(2);
        var ac = this.get('session.data.authenticated.access_token');
        var CREATE_PAYMENT_URL = encodeURI(_environment.default.APP.API_HOST + '/createpaypalpayment?amount=' + final_amount + '&access_token=' + ac + '&charges=' + pp_fee);
        var EXECUTE_PAYMENT_URL = _environment.default.APP.API_HOST + '/executepaypalpayment?access_token=' + ac;
        paypal.Button.render({
          // env: "sandbox",
          env: 'production',
          commit: true,
          payment: function () {
            return paypal.request.post(CREATE_PAYMENT_URL).then(function (data) {
              if (data.createPaypalPayment) {
                if (data.createPaypalPayment.id) {
                  return data.createPaypalPayment.id;
                }
              }
            });
          },
          onAuthorize: function (data) {
            return paypal.request.post(EXECUTE_PAYMENT_URL, {
              paymentID: data.paymentID,
              payerID: data.payerID
            }).then(function (data) {
              if (data && data.executePaypalPayment && data.executePaypalPayment.success) {
                that.store.query('brand', {}).then(function (data) {
                  if (data.content.length > 0) {
                    var obj = data.get('firstObject');
                    that.get('session').set('data.brand_id', obj.id);
                    that.get('session').set('data.brand_company', obj.company);
                    that.get('session').set('data.brand_origin', obj.origin);
                    that.get('session').set('data.brand_funds', obj.funds);
                    that.get('session').set('data.funds', that.abbreviate_number(obj.funds, 1));
                  }
                });
                setTimeout(function () {
                  (0, _jquery.default)('.payment-section').hide();
                  (0, _jquery.default)('.payment-success-section').show();
                  (0, _jquery.default)('.payment-success-section div').text('');
                  (0, _jquery.default)('.payment-success-section div').append('<img src="assets/payment_success.gif">');
                }, 1500);
              } else {
                alert('Cannot connect to the server, please try again later!');
              }
            });
          }
        }, '#wallet_paypal');
        setTimeout(function () {
          (0, _jquery.default)('.loading-paypal').hide();
        }, 1000);
      },

      onWalletAmountInput(e) {
        var amount = (0, _jquery.default)('#paypal_new_amount').val().replace(/[^0-9]/g, '');
        (0, _jquery.default)('#paypal_new_amount').val(amount);
        this.send('displayPaypalButton');
      },

      onInsufficientWalletClick(postCost, cb) {
        // IOL sub user don't get to see top up window
        if (this.get('session.data.is_sub_user')) {
          return;
        }

        var dialog = (0, _jquery.default)('#insufficient-wallet-dialog').modal({
          showClose: true,
          fadeDuration: 300,
          escapeClose: false,
          clickClose: false
        });

        if (this.insufficientWalletDialog) {
          (0, _jquery.default)(this.insufficientWalletDialog[0]).remove();
        }

        this.insufficientWalletDialog = dialog;
        setTimeout(function () {
          (0, _jquery.default)('.payment-section').show();
          (0, _jquery.default)('.payment-success-section').hide();
        }, 500);
        var that = this;
        setTimeout(function () {
          var available_funds = that.get('session').get('data.brand_funds');
          var amount = parseFloat(parseFloat(postCost) - parseFloat(available_funds));
          amount = amount.toFixed(2);
          that.set('insufficient_amount', amount);
          (0, _jquery.default)('#ins_paypal_new_amount').val(amount);
          that.send('insufficientDisplayPaypalButton');
        }, 1000);
        this.set('insufficientWalletCallback', cb);
        return false;
      },

      insufficientDisplayPaypalButton() {
        var that = this;
        (0, _jquery.default)('.loading-paypal').show();
        (0, _jquery.default)('#ins_wallet_paypal').text('');
        var amount = 100; //default

        if ((0, _jquery.default)('#ins_paypal_new_amount').val()) {
          amount = parseFloat((0, _jquery.default)('#ins_paypal_new_amount').val());
          amount = amount.toFixed(2);
        }

        (0, _jquery.default)('#wallet_topup_amount_inf').text(amount);
        var pp_fee = (parseFloat(amount) * that.paypal_fee).toFixed(2);
        pp_fee = (parseFloat(pp_fee) + parseFloat(that.paypal_flat_fee)).toFixed(2);
        (0, _jquery.default)('#wallet_paypal_fee_inf').text(pp_fee); // var final_amount = parseFloat(parseFloat(amount) + parseFloat(pp_fee));

        var final_amount = parseFloat(amount);
        final_amount = final_amount.toFixed(2);
        var ac = this.get('session.data.authenticated.access_token');
        var CREATE_PAYMENT_URL = encodeURI(_environment.default.APP.API_HOST + '/createpaypalpayment?amount=' + final_amount + '&access_token=' + ac + '&charges=' + pp_fee);
        var EXECUTE_PAYMENT_URL = _environment.default.APP.API_HOST + '/executepaypalpayment?access_token=' + ac;
        paypal.Button.render({
          // env: "sandbox",
          env: 'production',
          commit: true,
          payment: function () {
            return paypal.request.post(CREATE_PAYMENT_URL).then(function (data) {
              if (data.createPaypalPayment) {
                if (data.createPaypalPayment.id) {
                  return data.createPaypalPayment.id;
                }
              }
            });
          },
          onAuthorize: function (data) {
            return paypal.request.post(EXECUTE_PAYMENT_URL, {
              paymentID: data.paymentID,
              payerID: data.payerID
            }).then(function (data) {
              if (data && data.executePaypalPayment && data.executePaypalPayment.success) {
                that.store.query('brand', {}).then(function (data) {
                  if (data.content.length > 0) {
                    var obj = data.get('firstObject');
                    that.get('session').set('data.brand_id', obj.id);
                    that.get('session').set('data.brand_company', obj.company);
                    that.get('session').set('data.brand_origin', obj.origin);
                    that.get('session').set('data.brand_funds', obj.funds);
                    that.get('session').set('data.funds', that.abbreviate_number(obj.funds, 1));
                  }
                });
                setTimeout(function () {
                  (0, _jquery.default)('.payment-section').hide();
                  (0, _jquery.default)('.payment-success-section').show();
                  (0, _jquery.default)('.payment-success-section div').text('');
                  (0, _jquery.default)('.payment-success-section div').append('<img src="assets/payment_success.gif">');
                }, 1500);

                if (that.get('insufficientWalletCallback')) {
                  that.get('insufficientWalletCallback')(true);
                }
              } else {
                if (that.get('insufficientWalletCallback')) {
                  that.get('insufficientWalletCallback')(false);
                }

                alert('Cannot connect to the server, please try again later!');
              }
            });
          }
        }, '#ins_wallet_paypal');
        setTimeout(function () {
          (0, _jquery.default)('.loading-paypal').hide();
        }, 1000);
      },

      onInsufficientWalletAmountInput(e) {
        var amount = (0, _jquery.default)('#ins_paypal_new_amount').val(); //.replace(/[^0-9]/g,'');

        (0, _jquery.default)('#ins_paypal_new_amount').val(amount);
        this.set('insufficient_amount', amount);
        this.send('insufficientDisplayPaypalButton');
      }

    }
  });

  _exports.default = _default;
});