define("flytrendy/routes/termsandconditions", ["exports", "ember-simple-auth/mixins/application-route-mixin", "flytrendy/helpers/t"], function (_exports, _applicationRouteMixin, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    title: _t.default.compute('title_terms_and_conditions'),
    session: Ember.inject.service('session'),
    filter: null,
    queryParams: {
      filter: {
        replace: true
      }
    },
    metaTags: [{
      tagName: _t.default.compute('seo_description'),
      tagContent: _t.default.compute('title_terms_and_conditions')
    }, {
      tagName: _t.default.compute('seo_keywords'),
      tagContent: _t.default.compute('seo_content')
    }],
    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      this.getTermsAndConditions(this.filter);
    },
    actions: {
      queryParamsDidChange(changed) {
        this.filter = changed.filter;

        this._super(...arguments);

        if (this.get('controller') && changed.filter) {
          this.getTermsAndConditions(changed.filter);
        }
      }

    },

    getTermsAndConditions(filter) {
      this.get('controller').send('tncUpdate', filter);
    }

  });

  _exports.default = _default;
});