define("flytrendy/helpers/t", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (key) {
    const locale = localStorage.getItem('lang');
    const loadDefault = `lang_${_environment.default.PLATFORM.defaultLanguage}`;
    const localeObject = `lang_${locale}`;
    return window[localeObject] && window[localeObject][key] || window[loadDefault][key];
  });

  _exports.default = _default;
});