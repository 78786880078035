define("flytrendy/models/objects/campaignpost", ["exports", "flytrendy/config/environment", "numeral"], function (_exports, _environment, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    campaign: '',
    user: '',
    data_id: '',
    post_social_type: '',
    social_account_info: '',
    post_copy: '',
    post_image: '',
    post_price: '',
    post_discount: 0,
    status: '',
    seen: false,
    brand_feedback: '',
    created_datetime: '',
    updated_date: '',
    showLoader: false,
    approved_img: '',
    declined_img: '',
    popup_approved_img: '',
    popup_declined_img: '',
    popup_pending_img: '',
    platform_i: false,
    platform_t: false,
    platform_f: false,
    is_approved: false,
    brand_country: '',
    brand_tax_text: '',
    influecer_price: 0,
    influecer_total_price: 0,
    service_fee: 0,
    subtotal: 0,
    iva_fee: 0,
    grand_total: 0,
    show_discount: false,
    discounted_price: 0,
    tech_fees: _environment.default.PLATFORM.tech_fees,
    iva_charges: _environment.default.PLATFORM.iva,
    video_url: '',
    is_video: false,
    influecer_total_price_abbreviated: 0,
    third_party_selection_ebabled: false,
    third_party_post_price: 0,
    third_party_post_price_total: 0,
    tech_fees: _environment.default.PLATFORM.tech_fees,
    setPostImage: function (controller, hideSpinner) {
      var s3_images = _environment.default.APP.S3_IMAGES;
      var url = s3_images + '/post_images/' + this.get('campaign') + '/' + this.get('campaign') + '-' + this.get('data_id') + '.png';
      this.set('post_image', url);
    },
    setData: function () {
      var approved = 'assets/icon_tick_gray.png',
          papproved = 'assets/icon-approve-gray.png';
      var rejected = 'assets/icon_close_gray.png',
          prejected = 'assets/icon-decline-gray.png';
      var change = 'assets/change-gray.png',
          pchange = 'assets/change-gray.png';
      this.set('is_approved', false);

      if (this.get('status') == 'approved' || this.get('status') == 'published') {
        approved = 'assets/icon_tick_green.png';
        papproved = 'assets/icon-approve.png';
        this.set('is_approved', true);
      }

      if (this.get('status') == 'declined') {
        rejected = 'assets/icon_cross_red.png';
        prejected = 'assets/icon-decline.png';
      }

      if (this.get('status') == 'pending') {
        change = 'assets/change_gray.png';
        pchange = 'assets/change.png';
      }

      this.set('approved_img', approved);
      this.set('declined_img', rejected);
      this.set('pending_img', change);
      this.set('popup_approved_img', papproved);
      this.set('popup_declined_img', prejected);
      this.set('popup_pending_img', pchange);

      if (this.get('post_social_type') == 'instagram' || this.get('post_social_type') == 'instagramstory') {
        this.set('platform_i', true);
      } else if (this.get('post_social_type') == 'twitter') {
        this.set('platform_t', true);
      } else if (this.get('post_social_type') == 'facebook') {
        this.set('platform_f', true);
      }

      this.setPostPrice();
      this.set('is_video', false);
      let videoUrl = this.get('video_url');

      if (videoUrl && !videoUrl.includes('https')) {
        videoUrl = 'https://' + videoUrl;
        this.set('video_url', videoUrl);
      }

      if (this.get('video_url')) {
        this.set('is_video', true);
      }
    },
    setPostPrice: function (newPrice) {
      const iva_charges = this.get('iva_charges');
      iva_charges ? this.set('brand_tax_text', 'IVA') : this.set('brand_tax_text', 'TVA');
      var infpr = newPrice ? newPrice : parseFloat(this.get('post_price'));

      if (parseFloat(this.get('post_discount'))) {
        this.set('show_discount', true);
        var discount = parseFloat(this.get('post_discount'));
        this.set('post_discount', discount.toFixed(2));
        var sf = 0;
        sf = infpr * this.tech_fees;
        var inf_tot_pr = infpr + sf;
        var df = discount;
        var st = inf_tot_pr - discount;
        var iva = st * iva_charges;
        var gt = st + iva;
        this.set('service_fee', sf.toFixed(2));
        this.set('subtotal', st.toFixed(2));
        this.set('iva_fee', iva.toFixed(2));
        this.set('grand_total', gt.toFixed(2));
        this.set('influecer_price', infpr.toFixed(2));
        this.set('influecer_total_price', inf_tot_pr.toFixed(2));
        this.set('discounted_price', df.toFixed(2));
      } else {
        const sf = infpr * this.tech_fees;
        var inf_tot_pr = infpr + sf;
        var st = sf + infpr;
        var iva = st * iva_charges;
        var gt = st + iva;
        this.set('show_discount', false);
        this.set('service_fee', sf.toFixed(2));
        this.set('subtotal', st.toFixed(2));
        this.set('iva_fee', iva.toFixed(2));
        this.set('grand_total', gt.toFixed(2));
        this.set('influecer_price', infpr.toFixed(2));
        this.set('influecer_total_price', inf_tot_pr.toFixed(2));
      }

      var influecer_total_price = inf_tot_pr.toFixed(2);
      this.set('influecer_total_price_abbreviated', (0, _numeral.default)(influecer_total_price).format('0.0a'));
      var third_party_post_price = parseFloat(this.get('post_price'));
      var third_party_ft_fee = 0;
      third_party_ft_fee = third_party_post_price + third_party_post_price * this.tech_fees;
      var final_markup = third_party_ft_fee * 2;
      this.set('third_party_post_price', final_markup.toFixed(2));
      this.set('third_party_post_price_total', final_markup.toFixed(2));
    },
    setStatus: function (st) {
      this.set('status', st);
      this.setData();
    },
    getUserFollowers: Ember.computed(function () {
      var followers = 0;

      if (this.get('social_account_info.followers')) {
        followers = this.get('social_account_info.followers');
      }

      if (parseInt(followers) < 1000) {
        followers = (0, _numeral.default)(followers).format('0a');
      } else {
        followers = (0, _numeral.default)(followers).format('0.0a');
      }

      return followers;
    }),
    getPostPlatformImage: Ember.computed(function () {
      var platform = '';

      if (this.get('post_social_type') == 'instagram') {
        platform = 'assets/icon_instagram_s.png';
      } else if (this.get('post_social_type') == 'twitter') {
        platform = 'assets/icon_twitter_s.png';
      } else if (this.get('post_social_type') == 'facebook') {
        platform = 'assets/icon_facebook_s.png';
      } else if (this.get('post_social_type') == 'instagramstory') {
        platform = 'assets/icon_instagram_story.png';
      }

      return platform;
    }),
    getStatusRejected: Ember.computed(function () {
      var rejected = 'assets/icon_close_gray.png';

      if (this.get('status') == 'declined') {
        rejected = 'assets/icon_cross_red.png';
      }

      return rejected;
    }),
    getStatusApproved: Ember.computed(function () {
      var approved = 'assets/icon_tick_gray.png';

      if (this.get('status') == 'approved' || this.get('status') == 'published') {
        approved = 'assets/icon_tick_green.png';
      }

      return approved;
    }),
    getProfileLink: Ember.computed(function () {
      var link = '';
      var username = this.get('social_account_info.username').replace(/\s/g, '');
      var id = this.get('social_account_info.id').replace(/\s/g, '');

      if (this.get('post_social_type') == 'instagram' || this.get('post_social_type') == 'instagramstory') {
        link = 'https://www.instagram.com/' + username;
      } else if (this.get('post_social_type') == 'twitter') {
        link = 'https://twitter.com/' + username;
      } else if (this.get('post_social_type') == 'facebook') {
        link = 'https://www.facebook.com/' + id;
      }

      return link;
    })
  });

  _exports.default = _default;
});