define("flytrendy/routes/aboutus", ["exports", "ember-simple-auth/mixins/application-route-mixin", "flytrendy/helpers/t", "flytrendy/config/environment"], function (_exports, _applicationRouteMixin, _t, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    title: _t.default.compute('title_aboutus'),
    session: Ember.inject.service('session'),
    metaTags: [{
      tagName: _t.default.compute('seo_description'),
      tagContent: _t.default.compute('title_aboutus')
    }, {
      tagName: _t.default.compute('seo_keywords'),
      tagContent: _t.default.compute('seo_content')
    }],
    setupController: function (controller, model) {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});