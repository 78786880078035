define("flytrendy/routes/services/feedback", ["exports", "jquery", "flytrendy/models/objects/services/post", "flytrendy/config/environment"], function (_exports, _jquery, _post, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    id: null,
    email: null,

    beforeModel() {},

    model(params) {
      this.set('id', params.id);
      this.set('email', params.email);
    },

    activate: function () {
      (0, _jquery.default)('body').toggleClass('hide-navbar');
    },
    deactivate: function () {
      (0, _jquery.default)('body').toggleClass('hide-navbar');
    },
    actions: {
      willTransition: function (transition) {
        if (transition.intent.name === 'mycampaign') {
          transition.abort();
        }
      }
    },
    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      var id = this.get('id'),
          email = this.get('email'),
          that = this;
      controller.set('id', id);
      controller.set('email', email);

      _jquery.default.ajax({
        type: 'GET',
        url: _environment.default.APP.API_HOST + '/abu/services?id=' + id + '&email=' + email,
        dataType: 'json',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Basic c2VydmljZXNAZmx5dHJlbmR5LmNvbTpIaXRvcmkyMDkqKiE=');
          xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        },
        success: function (res) {
          if (res && res.getServicePosts && res.getServicePosts.success) {
            var data = res.getServicePosts.result[0];
            var post_data = [],
                obj = null;

            for (var i = 0; i < data.post_data.length; i++) {
              obj = _post.default.create(data.post_data[i][0]);
              obj.setData();
              post_data.push(obj);
            }

            controller.set('clientData', res.getServicePosts.result[0]);
            controller.set('data', post_data);
          } else {
            controller.set('clientData', []);
          }
        },
        error: function (jqXHR, exception) {
          console.log(jqXHR);
        }
      });
    }
  });

  _exports.default = _default;
});