define("flytrendy/templates/privacypolicy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S55p/c9P",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"privacypolicy\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"privacypolicy-cont\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"title-cont\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"p\"],[12],[1,[30,[36,0],[\"pp_title\"],null]],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"content\"],[14,1,\"privacycontent\"],[12],[2,\"\\n\\t\\t\\tLoading...\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "flytrendy/templates/privacypolicy.hbs"
    }
  });

  _exports.default = _default;
});