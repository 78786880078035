define("flytrendy/models/objects/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    is_brand: "",
    is_additional_brand_user: "",
    is_staff: "",
    invoice: "",
    getSomeThing: Ember.computed(function () {})
  });

  _exports.default = _default;
});