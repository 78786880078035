define("flytrendy/models/user", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _model.default.extend({
    username: _emberData.default.attr(),
    first_name: _emberData.default.attr(),
    last_name: _emberData.default.attr(),
    email: _emberData.default.attr(),
    is_brand: _emberData.default.attr('boolean'),
    is_additional_brand_user: _emberData.default.attr(),
    is_first_login: _emberData.default.attr('boolean'),
    is_staff: _emberData.default.attr('boolean'),
    invoice: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});