define("flytrendy/models/objects/platforms/twitter", ["exports", "jquery", "flytrendy/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    influencer: '',
    id: '',
    username: '',
    name: '',
    bio: '',
    profile_picture: '',
    posts: {},
    followers: 0,
    abbreviate_followers: 0,
    totalComments: 0,
    totalLikes: 0,
    avgLikes: 0,
    avgComments: 0,
    mostRecentPost: {},
    // Only 1
    mostRecentPosts: [],
    // Recent two posts
    loader: false,
    graph: {},
    init: function () {
      this._super(...arguments);

      this.set('graph', {
        labels: [],
        datasets: [{
          label: 'Engagement',
          data: [],
          backgroundColor: ['rgba(0, 201, 146, 0.2)'],
          borderColor: ['rgba(0, 201, 146, 1)'],
          borderWidth: 1
        }]
      });
    },
    load: function (controller, cb) {
      this.set('loader', true);
      var that = this;
      var session = controller.get('session');
      var userauth = 'Basic ' + btoa(session.get('data.username') + ':' + session.get('data.password'));
      var data = {
        i: this.get('influencer'),
        p: 'twitter'
      };

      _jquery.default.ajax({
        type: 'POST',
        url: _environment.default.APP.API_HOST + '/researchinfluencerplatform',
        data: data,
        dataType: 'json',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', userauth);
          xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        },
        success: function (res) {
          if (res && res.researchInfluencerPlatformViewSet && res.researchInfluencerPlatformViewSet.data) {
            var obj = res.researchInfluencerPlatformViewSet.data,
                t_l = 0,
                temp_recent_posts = [];
            that.set('posts', obj);

            if (obj[0]) {
              that.set('mostRecentPost', obj[0]);
              temp_recent_posts.push(obj[0]);

              if (obj[1]) {
                temp_recent_posts.push(obj[1]);
              }
            }

            that.set('mostRecentPosts', temp_recent_posts);

            for (var i = 0; i < obj.length; i++) {
              t_l += obj[i].likes;
            } // TODO:: get comments on tweets


            that.set('totalComments', 0);
            that.set('avgComments', 0);
            that.set('totalLikes', t_l);
            that.set('avgLikes', parseInt(t_l / obj.length));
            that.setGraph();
          }

          that.set('loader', false);
        },
        error: function (jqXHR, exception) {
          console.log(jqXHR);
          that.set('loader', false);
        }
      });

      _jquery.default.ajax({
        type: 'POST',
        url: _environment.default.APP.API_HOST + '/influencerplatformfollower',
        data: data,
        dataType: 'json',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', userauth);
          xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        },
        success: function (res) {
          if (res && res.researchInfluencerPlatformViewFollowersSet && res.researchInfluencerPlatformViewFollowersSet.data) {
            var obj = res.researchInfluencerPlatformViewFollowersSet.data;

            if (obj.length > 0) {
              obj = obj[0];
              that.set('bio', obj.description);
              that.set('profile_picture', obj.profile);
              that.set('followers', obj.followers);
              that.set('abbreviate_followers', that.abbreviate_number(obj.followers, 0));
            }
          }

          cb();
        },
        error: function (jqXHR, exception) {
          console.log(jqXHR);
          cb();
        }
      });
    },
    setGraph: function () {
      var label = [],
          data = [],
          posts = this.get('posts');

      if (posts) {
        var temp_dict = {},
            d = '';

        for (var i = 0; i < posts.length; i++) {
          d = moment(posts[i].date).format('MM/YY');

          if (!temp_dict[d]) {
            temp_dict[d] = 0;
            label.push(d);
          }

          temp_dict[d]++;
        }

        for (var key in temp_dict) {
          data.push(temp_dict[key]);
        }
      }

      var graph = {
        labels: label.reverse(),
        datasets: [{
          label: 'Engagement',
          data: data.reverse(),
          backgroundColor: ['rgba(0, 201, 146, 0.2)'],
          borderColor: ['rgba(0, 201, 146, 1)'],
          borderWidth: 1
        }]
      };
      this.set('graph', graph);
    },
    getProfilePic: Ember.computed(function () {
      var pic = this.get('profile_picture');

      if (pic) {
        pic = pic.replace('_normal', '');
      }

      return pic;
    }),
    abbreviate_number: function (num, fixed) {
      if (num === null) {
        return null;
      }

      if (num === 0) {
        return '0';
      }

      fixed = !fixed || fixed < 0 ? 0 : fixed;
      var b = num.toPrecision(2).split('e'),
          k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3),
          c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed),
          d = c < 0 ? c : Math.abs(c),
          e = d + ['', 'K', 'M', 'B', 'T'][k];
      return e;
    }
  });

  _exports.default = _default;
});