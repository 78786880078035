define("flytrendy/controllers/aboutus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      newCampaign() {
        dataLayer.push({
          event: 'asyncAction',
          gaHitType: 'event',
          event_category: 'new campaign',
          event_action: 'start campaign for free button',
          event_label: 'aboutus_page'
        });
        this.transitionToRoute('newcampaign');
      },

      appleApp() {
        dataLayer.push({
          event: 'asyncAction',
          gaHitType: 'event',
          event_category: 'download_app',
          event_action: 'pg top click Apple App store on pg bottom',
          event_label: 'aboutus_page'
        });
        window.open('https://itunes.apple.com/us/app/flytrendy-influencer/id1240289950?ls=1&mt=8');
      },

      androidApp() {
        dataLayer.push({
          event: 'asyncAction',
          gaHitType: 'event',
          event_category: 'download_app',
          event_action: 'click Google Play Android store on pg bottom',
          event_label: 'aboutus_page'
        });
        window.open('https://play.google.com/store/apps/details?id=com.flytrendy.flytrendy');
      }

    }
  });

  _exports.default = _default;
});