define("flytrendy/models/objects/newcampaign", ["exports", "jquery", "flytrendy/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    data_id: '',
    hero_image: '',
    hero_image_url: '',
    logo: '',
    brand_name: '',
    campaign_name: '',
    product_description: '',
    link: '',
    where_to_purchase: '',
    content_we_love: '',
    call_to_action: '',
    file_input_1_label: '',
    file_input_1_label_w: 0,
    file_input_1_label_h: 0,
    file_input_2_label: '',
    file_input_2_label_w: 0,
    file_input_2_label_h: 0,
    file_input_3_label: '',
    file_input_3_label_w: 0,
    file_input_3_label_h: 0,
    file_input_4_label: '',
    file_input_4_label_w: 0,
    file_input_4_label_h: 0,
    file_input_5_label: '',
    file_input_5_label_w: 0,
    file_input_5_label_h: 0,
    file_input_6_label: '',
    file_input_6_label_w: 0,
    file_input_6_label_h: 0,
    instagram: false,
    twitter: false,
    facebook: false,
    instagram_tags: '',
    twitter_tags: '',
    facebook_tags: '',
    instagram_tags_list: [],
    twitter_tags_list: [],
    facebook_tags_list: [],
    dos: [],
    donts: [],
    age_min: '',
    age_max: '',
    gender: '',
    target_regions: [],
    target_regions_string: '',
    interests: [],
    interests_list: [],
    interests_string: '',
    response_time: '',
    publish_status: '',
    publish_date: '',
    until_date: '',
    first_name: '',
    last_name: '',
    email: '',
    ph: '',
    mob: '',
    pass: '',
    c_pass: '',
    address: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
    agreement: false,
    mood_images_count: 0,
    init: function (obj) {},
    setData: function (data) {
      if (data) {
        var obj = data.data;
        this.set('data_id', data.id);
        this.set('logo', obj.campaign_logo_url);
        this.set('hero_image', obj.hero_image_url);
        this.set('mood_board_urls', obj.mood_board_urls);
        this.set('hero_image_url', obj.hero_image_url);
        this.set('campaign_logo_url', obj.campaign_logo_url);
        this.set('brand_name', obj.brand_comp_name);
        this.set('campaign_name', obj.pro_comp_name);
        this.set('product_description', obj.pro_desc);
        this.set('link', obj.campaign_link);
        this.set('where_to_purchase', obj.where_purchase);
        this.set('content_we_love', obj.content_love);
        this.set('call_to_action', obj.call_action);
        this.set('mood_images_count', obj.mood_images_count);
        this.set('instagram', obj.platform_insta);
        this.set('twitter', obj.platform_twitter);
        this.set('facebook', obj.platform_fb);
        this.set('instagram_tags', '');
        this.set('instagram_tags_list', []);

        if (obj.pre_tags && obj.pre_tags['instagram']) {
          var tags = obj.pre_tags['instagram'];
          this.set('instagram_tags', tags);
          this.set('instagram_tags_list', tags.split(' '));
        }

        this.set('twitter_tags', '');
        this.set('twitter_tags_list', []);

        if (obj.pre_tags && obj.pre_tags['twitter']) {
          var tags = obj.pre_tags['twitter'];
          this.set('twitter_tags', tags);
          this.set('twitter_tags_list', tags.split(' '));
        }

        this.set('facebook_tags', '');
        this.set('facebook_tags_list', []);

        if (obj.pre_tags && obj.pre_tags['facebook']) {
          var tags = obj.pre_tags['facebook'];
          this.set('facebook_tags', tags);
          this.set('facebook_tags_list', tags.split(' '));
        }

        this.set('dos', obj.pub_guides);
        this.set('donts', obj.dont_dos);
        this.set('age_min', obj.ageMin);
        this.set('age_max', obj.ageMax);
        this.set('gender', obj.gender);
        this.set('target_regions', []);
        this.set('target_regions_string', obj.info_demographic);

        if (obj.info_demographic) {
          var r = obj.info_demographic.replace(/, /g, ',');
          this.set('target_regions', r.split(','));
        }

        this.set('interests', obj.interests);
        this.set('interests_list', obj.interests);
        this.set('response_time', obj.response_time);
        this.set('publish_status', 'later');
        this.set('publish_date', moment(obj.publish_brief_app).format('DD/MM/YY'));
        this.set('until_date', moment(obj.open_until_date).format('DD/MM/YY'));
        this.set('agreement', false);
      }
    },
    setHeroImage: function (controller) {
      var that = this;
      var session = controller.get('session');
      var userauth = 'Basic ' + btoa(session.get('data.username') + ':' + session.get('data.password'));
      this.set('showLoader', true);

      _jquery.default.ajax({
        type: 'GET',
        url: _environment.default.APP.API_HOST + '/getcampaignheroimage?campaign_id=' + this.get('data_id'),
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', userauth);
          xhr.setRequestHeader('Content-Type', 'application/json');
        },
        success: function (res) {
          if (res && res.getCampaignHeroImage && res.getCampaignHeroImage.success) {
            that.set('hero_image', res.getCampaignHeroImage.hero_image);
          }

          setTimeout(function () {
            that.set('showLoader', false);
          }, 500);
        },
        error: function (jqXHR, exception) {
          that.set('showLoader', false);
        }
      });
    },
    base64Converter: async function (imageUrl) {
      const res = await fetch(imageUrl);
      const blob = await res.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          resolve(reader.result);
        }, false);

        reader.onerror = () => {
          return reject(this);
        };

        reader.readAsDataURL(blob);
      });
    },
    setMoodImages: function () {
      const moodUrls = this.get('mood_board_urls');
      const that = this;
      moodUrls.map((moodUrl, index) => {
        return this.base64Converter(moodUrl).then(image => {
          const counter = index + 1;
          let key = 'file_input_' + counter + '_label';
          this.set('file_input_' + counter + '_label', image);
          let img = new Image();

          img.onload = function () {
            that.set(key + '_w', this.width);
            that.set(key + '_h', this.height);
          };

          img.src = image;
        });
      });
    },
    dosomething: Ember.computed(function () {})
  });

  _exports.default = _default;
});