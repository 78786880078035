define("flytrendy/modifiers/prevent-unload", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PreventUnload extends _emberModifier.default {
    didReceiveArguments() {
      if (this.listener) {
        window.removeEventListener('beforeunload', this.listener);
      }

      this.listener = this.args.positional[0];
      window.addEventListener('beforeunload', this.listener);
    }

    willRemove() {
      window.removeEventListener('beforeunload', this.listener);
    }

  }

  _exports.default = PreventUnload;
});