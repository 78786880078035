define("flytrendy/routes/changepassword", ["exports", "ember-simple-auth/mixins/application-route-mixin", "flytrendy/helpers/t"], function (_exports, _applicationRouteMixin, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    title: _t.default.compute("title_changepassword"),
    session: Ember.inject.service('session'),
    metaTags: [{
      tagName: _t.default.compute("seo_description"),
      tagContent: _t.default.compute("title_changepassword")
    }, {
      tagName: _t.default.compute("seo_keywords"),
      tagContent: _t.default.compute("seo_content")
    }],
    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      setTimeout(function () {
        controller.send("onLoading");
      }, 100);
    }
  });

  _exports.default = _default;
});