define("flytrendy/routes/activate", ["exports", "ember-simple-auth/mixins/application-route-mixin", "flytrendy/helpers/t"], function (_exports, _applicationRouteMixin, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    title: _t.default.compute("title_activate_account"),
    session: Ember.inject.service('session'),
    metaTags: [{
      tagName: _t.default.compute("seo_description"),
      tagContent: _t.default.compute("title_activate_account")
    }, {
      tagName: _t.default.compute("seo_keywords"),
      tagContent: _t.default.compute("seo_content")
    }]
  });

  _exports.default = _default;
});