define("flytrendy/models/objects/services/post", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    id: '1',
    campaign: null,
    video_url: '',
    post_social_type: '',
    price: 0,
    social_account_info: {},
    profile_link: '',
    post_copy: '',
    post_image: '',
    show_price: true,
    status: '',
    status_feedback: '',
    platform: '',
    setData: function () {
      var platform = '',
          profile_link = '';

      if (this.get('post_social_type') == 'instagram' || this.get('post_social_type') == 'instagramstory') {
        platform = 'https://instagram-brand.com/wp-content/themes/ig-branding/assets/images/ig-logo-email.png';
        profile_link = 'https://www.instagram.com/' + this.get('social_account_info.username');
      } else if (this.get('post_social_type') == 'twitter') {
        platform = 'https://static.makeuseof.com/wp-content/uploads/2013/03/twitter-bird-white-on-blue.png';
        profile_link = 'https://www.twitter.com/' + this.get('social_account_info.username');
      } else if (this.get('post_social_type') == 'facebook') {
        platform = 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Facebook-icon-1.png/240px-Facebook-icon-1.png';
        profile_link = 'https://www.facebook.com/' + this.get('social_account_info.id');
      }

      let videoUrl = this.get('video_url');

      if (videoUrl && !videoUrl.includes('https')) {
        videoUrl = 'https://' + videoUrl;
        this.set('video_url', videoUrl);
      }

      this.set('platform', platform);
      this.set('profile_link', profile_link);
      this.set('post_image', _environment.default.APP.S3_IMAGES + '/post_images/' + this.get('campaign') + '/' + this.get('campaign') + '-' + this.get('id') + '.png');
    }
  });

  _exports.default = _default;
});