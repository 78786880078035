define("flytrendy/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service('session'),
    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      controller.send('onLoading');
    }
  });

  _exports.default = _default;
});