define("flytrendy/models/objects/campaign", ["exports", "jquery", "flytrendy/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    user: '',
    data_id: '',
    brand: '',
    content_love: '',
    call_action: '',
    mood_board: [],
    platform_insta: false,
    platform_twitter: false,
    platform_fb: false,
    pre_tags: '',
    pub_guides: '',
    dont_dos: '',
    hero_image: '',
    hero_image_url: '',
    campaign_logo: '',
    campaign_logo_url: '',
    brand_comp_name: '',
    pro_comp_name: '',
    pro_desc: '',
    pro_category_primary: '',
    pro_category_secondary: '',
    campaign_link: '',
    where_purchase: '',
    ageMin: '',
    ageMax: '',
    gender: '',
    reviewed: false,
    info_demographic: '',
    interests: '',
    post_submissions: '',
    posts_accepted: '',
    response_time: '',
    response_time_date: '',
    publish_brief_app: '',
    open_until_date: '',
    created_datetime: '',
    updated_date: '',
    showLoader: false,
    mood_images_count: 0,
    is_finished: false,
    init: function (obj) {
      this._super(...arguments);

      var today = new Date(),
          campDateUntil = new Date(this.get('open_until_date'));

      if (today - campDateUntil > 0) {
        this.set('is_finished', true);
      }
    },
    setHeroImage: function (controller) {
      var that = this;
      var session = controller.get('session');
      var userauth = 'Basic ' + btoa(session.get('data.username') + ':' + session.get('data.password'));
      this.set('showLoader', true);

      _jquery.default.ajax({
        type: 'GET',
        url: _environment.default.APP.API_HOST + '/getcampaignheroimage?campaign_id=' + this.get('data_id'),
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', userauth);
          xhr.setRequestHeader('Content-Type', 'application/json');
        },
        success: function (res) {
          if (res && res.getCampaignHeroImage && res.getCampaignHeroImage.success) {
            that.set('hero_image', res.getCampaignHeroImage.hero_image);
          }

          setTimeout(function () {
            that.set('showLoader', false);
          }, 500);
        },
        error: function (jqXHR, exception) {
          that.set('showLoader', false);
        }
      });
    },
    setMoodImages: function (controller, cb) {
      var that = this,
          moods = this.get('mood_board'),
          couter = 0;
      var session = controller.get('session');
      var userauth = 'Basic ' + btoa(session.get('data.username') + ':' + session.get('data.password'));

      for (var i = 0; i < this.get('mood_images_count'); i++) {
        _jquery.default.ajax({
          type: 'GET',
          url: _environment.default.APP.API_HOST + '/getmoodimagewithid?campaign_id=' + this.get('data_id') + '&mood_image_id=' + i,
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', userauth);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            couter++;

            if (res && res.getMoodImageWithId && res.getMoodImageWithId.success) {
              moods.push(res.getMoodImageWithId.mood_image);
            }

            if (couter == that.get('mood_images_count')) {
              cb();
            }
          },
          error: function (jqXHR, exception) {
            couter++;

            if (couter == that.get('mood_images_count')) {
              cb();
            }
          }
        });
      }
    },
    getInstagramTags: Ember.computed(function () {
      var list = [];

      if (this.get('pre_tags') && this.get('pre_tags')['instagram']) {
        list = this.get('pre_tags')['instagram'];
      }

      return list;
    }),
    getTwitterTags: Ember.computed(function () {
      var list = [];

      if (this.get('pre_tags') && this.get('pre_tags')['twitter']) {
        list = this.get('pre_tags')['twitter'];
      }

      return list;
    }),
    getFacebookTags: Ember.computed(function () {
      var list = [];

      if (this.get('pre_tags') && this.get('pre_tags')['facebook']) {
        list = this.get('pre_tags')['facebook'];
      }

      return list;
    })
  });

  _exports.default = _default;
});