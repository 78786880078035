define("flytrendy/controllers/myprofile", ["exports", "jquery", "flytrendy/config/environment", "flytrendy/models/objects/brand"], function (_exports, _jquery, _environment, _brand) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    notify: Ember.inject.service('notify'),
    user: {},
    brand: {},
    editYourDetails: false,
    editBillingDetails: false,
    editIVA: false,
    editCC: false,
    editPEC: false,
    changePassword: false,
    invoiceNotification: true,
    is_sub_user: false,
    agencyAccount: true,
    init: function () {
      this._super(...arguments);

      const SUB_USER = this.get('session.data.is_sub_user');
      this.set('is_sub_user', SUB_USER);
    },
    actions: {
      onLoading() {},

      onEditClick(flag) {
        if (flag == 1) {
          this.set('editYourDetails', true);
        } else if (flag == 2) {
          this.set('editBillingDetails', true);
        } else if (flag == 3) {
          this.set('editIVA', true);
        } else if (flag == 4) {
          this.set('changePassword', true);
        } else if (flag == 5) {
          this.set('editCC', true);
        } else if (flag == 6) {
          this.set('editPEC', true);
        }
      },

      onCancel(flag) {
        if (flag == 1) {
          this.set('editYourDetails', false);
        } else if (flag == 2) {
          this.set('editBillingDetails', false);
        } else if (flag == 3) {
          this.set('editIVA', false);
        } else if (flag == 4) {
          this.set('changePassword', false);
        } else if (flag == 5) {
          this.set('editCC', false);
        } else if (flag == 6) {
          this.set('editPEC', false);
        }
      },

      onSave(flag, e) {
        (0, _jquery.default)(e.target).parent().removeClass('saving');
        (0, _jquery.default)(e.target).parent().addClass('saving');
        var that = this;

        if (flag == 1) {
          this.send('saveYourDetails', function (ok, msg) {
            if (ok) {
              that.set('editYourDetails', false);
              that.get('notify').alert({
                html: '<div>Changes saved successfully!</div>'
              });
            } else {
              if (msg) {
                that.get('notify').warning({
                  html: '<div>' + msg + '</div>'
                });
              } else {
                that.get('notify').warning({
                  html: '<div>Cannot connect to the server, or there are no changes!</div>'
                });
              }

              (0, _jquery.default)(e.target).parent().removeClass('saving');
            }
          });
        } else if (flag == 2) {
          this.send('saveBillingDetails', function (ok, msg) {
            if (ok) {
              that.set('editBillingDetails', false);
              that.get('notify').alert({
                html: '<div>Changes saved successfully!</div>'
              });
            } else {
              if (msg) {
                that.get('notify').warning({
                  html: '<div>' + msg + '</div>'
                });
              } else {
                that.get('notify').warning({
                  html: '<div>Cannot connect to the server, please try again later!</div>'
                });
              }

              (0, _jquery.default)(e.target).parent().removeClass('saving');
            }
          });
        } else if (flag == 3) {
          this.send('saveIVA', function (ok, msg) {
            if (ok) {
              that.set('editIVA', false);
              that.get('notify').alert({
                html: '<div>Changes saved successfully!</div>'
              });
            } else {
              if (msg) {
                that.get('notify').warning({
                  html: '<div>' + msg + '</div>'
                });
              } else {
                that.get('notify').warning({
                  html: '<div>Cannot connect to the server, please try again later!</div>'
                });
              }

              (0, _jquery.default)(e.target).parent().removeClass('saving');
            }
          });
        } else if (flag == 4) {
          this.send('changePassword', function (ok, msg) {
            if (ok) {
              that.set('changePassword', false);
              that.get('notify').alert({
                html: '<div>Password updated successfully!</div>'
              });
            } else {
              if (msg) {
                that.get('notify').warning({
                  html: '<div>' + msg + '</div>'
                });
              } else {
                that.get('notify').warning({
                  html: '<div>Cannot connect to the server, please try again later!</div>'
                });
              }

              (0, _jquery.default)(e.target).parent().removeClass('saving');
            }
          });
        } else if (flag == 5) {
          this.send('saveCC', function (ok, msg) {
            if (ok) {
              that.set('editCC', false);
              that.get('notify').alert({
                html: '<div>CC email updated successfully!</div>'
              });
            } else {
              if (msg) {
                that.get('notify').warning({
                  html: '<div>' + msg + '</div>'
                });
              } else {
                that.get('notify').warning({
                  html: '<div>Cannot connect to the server, please try again later!</div>'
                });
              }

              (0, _jquery.default)(e.target).parent().removeClass('saving');
            }
          });
        } else if (flag == 6) {
          this.send('savePEC', function (ok, msg) {
            if (ok) {
              that.set('editPEC', false);
              that.get('notify').alert({
                html: '<div>Changes saved successfully!</div>'
              });
            } else {
              if (msg) {
                that.get('notify').warning({
                  html: '<div>' + msg + '</div>'
                });
              } else {
                that.get('notify').warning({
                  html: '<div>Cannot connect to the server, please try again later!</div>'
                });
              }

              (0, _jquery.default)(e.target).parent().removeClass('saving');
            }
          });
        }
      },

      saveYourDetails(cb) {
        const fn = (0, _jquery.default)('#first_name').val();
        const ln = (0, _jquery.default)('#last_name').val();
        const c = (0, _jquery.default)('#company').val();
        const p = (0, _jquery.default)('#phone').val();
        const m = (0, _jquery.default)('#mobile').val();
        const flag = false;
        (0, _jquery.default)('#first_name').removeClass('err');
        (0, _jquery.default)('#last_name').removeClass('err');
        (0, _jquery.default)('#company').removeClass('err');
        (0, _jquery.default)('#mobile').removeClass('err');

        if (!fn) {
          (0, _jquery.default)('#first_name').addClass('err');
          flag = true;
        }

        if (!ln) {
          (0, _jquery.default)('#last_name').addClass('err');
          flag = true;
        }

        if (!c) {
          (0, _jquery.default)('#company').addClass('err');
          flag = true;
        }

        if (!m && (0, _jquery.default)('#mobile')) {
          (0, _jquery.default)('#mobile').addClass('err');
          flag = true;
        }

        if (flag) {
          cb(false);
          return;
        }

        var user = {
          user: {
            first_name: fn,
            last_name: ln
          }
        };
        var that = this;
        var cred = this.get('session.data.authenticated.token_type') + ' ' + this.get('session.data.authenticated.access_token');

        _jquery.default.ajax({
          type: 'PATCH',
          url: _environment.default.APP.API_HOST + '/users/' + this.user.id,
          data: JSON.stringify(user),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', cred);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res && res.user) {
              that.set('user', res.user);
              var brand = {
                brand: {
                  company: c,
                  phone: p,
                  mobile: m
                }
              };

              _jquery.default.ajax({
                type: 'PATCH',
                url: _environment.default.APP.API_HOST + '/brands/' + that.brand.id,
                data: JSON.stringify(brand),
                beforeSend: function (xhr) {
                  xhr.setRequestHeader('Authorization', 'Basic ' + btoa(that.get('session').get('data.username') + ':' + that.get('session').get('data.password')));
                  xhr.setRequestHeader('Content-Type', 'application/json');
                },
                success: function (res) {
                  if (res && res.brand) {
                    that.set('brand', res.brand);
                    that.send('updateBrand', res.brand);
                    cb(true);
                  } else {
                    cb(false);
                  }
                },
                error: function (e, exception) {
                  if (e.responseJSON.brand) {
                    if (e.responseJSON.brand.company) {
                      cb(false, 'Company name is invalid!');
                    }
                  } else {
                    cb(false);
                  }
                }
              });
            } else {
              cb(false);
            }
          },
          error: function (e, exception) {
            cb(false);
          }
        });
      },

      saveBillingDetails(cb) {
        var billing_c = (0, _jquery.default)('#billing_company').val();
        var ad = (0, _jquery.default)('#address').val();
        var ct = (0, _jquery.default)('#city').val();
        var st = (0, _jquery.default)('#state').val();
        var pc = (0, _jquery.default)('#postcode').val();
        var flag = false;
        (0, _jquery.default)('#billing_company').removeClass('err');
        (0, _jquery.default)('#address').removeClass('err');
        (0, _jquery.default)('#city').removeClass('err');
        (0, _jquery.default)('#state').removeClass('err');
        (0, _jquery.default)('#postcode').removeClass('err');

        if (!billing_c) {
          (0, _jquery.default)('#billing_company').addClass('err');
          flag = true;
        }

        if (!ad) {
          (0, _jquery.default)('#address').addClass('err');
          flag = true;
        }

        if (!ct) {
          (0, _jquery.default)('#city').addClass('err');
          flag = true;
        }

        if (!st) {
          (0, _jquery.default)('#state').addClass('err');
          flag = true;
        }

        if (!pc) {
          (0, _jquery.default)('#postcode').addClass('err');
          flag = true;
        }

        if (flag) {
          cb(false);
          return;
        }

        var brand = {
          brand: {
            billing_company: billing_c,
            street_address: ad,
            location_city: ct,
            location_state: st,
            location_postcode: pc
          }
        };
        var that = this;

        _jquery.default.ajax({
          type: 'PATCH',
          url: _environment.default.APP.API_HOST + '/brands/' + this.brand.id,
          data: JSON.stringify(brand),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Basic ' + btoa(that.get('session').get('data.username') + ':' + that.get('session').get('data.password')));
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res && res.brand) {
              that.set('brand', res.brand);
              that.send('updateBrand', res.brand);
              cb(true);
            } else {
              cb(false);
            }
          },
          error: function (e, exception) {
            cb(false);
          }
        });
      },

      saveIVA(cb) {
        var iva = (0, _jquery.default)('#iva').val();
        (0, _jquery.default)('#iva').removeClass('err'); // if(!iva || iva.length != 11){
        //     jQuery("#address").addClass("err");
        //     cb(false);
        //     return;
        // }

        var brand = {
          brand: {
            iva: iva
          }
        };
        var that = this;

        _jquery.default.ajax({
          type: 'PATCH',
          url: _environment.default.APP.API_HOST + '/brands/' + this.brand.id,
          data: JSON.stringify(brand),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Basic ' + btoa(that.get('session').get('data.username') + ':' + that.get('session').get('data.password')));
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res && res.brand) {
              var b = _brand.default.create(res.brand);

              b.id = res.brand.id;
              that.set('brand', b);
              that.send('updateBrand', res.brand);
              cb(true);
            } else {
              cb(false);
            }
          },
          error: function (e, exception) {
            cb(false);
          }
        });
      },

      savePEC(cb) {
        var pec_univoco = (0, _jquery.default)('#pec_univoco').val();
        (0, _jquery.default)('#pec_univoco').removeClass('err'); // if(!iva || iva.length != 11){
        //     jQuery("#address").addClass("err");
        //     cb(false);
        //     return;
        // }

        var brand = {
          brand: {
            pec_univoco: pec_univoco
          }
        };
        var that = this;

        _jquery.default.ajax({
          type: 'PATCH',
          url: _environment.default.APP.API_HOST + '/brands/' + this.brand.id,
          data: JSON.stringify(brand),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Basic ' + btoa(that.get('session').get('data.username') + ':' + that.get('session').get('data.password')));
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res && res.brand) {
              var b = _brand.default.create(res.brand);

              b.id = res.brand.id;
              that.set('brand', b);
              that.send('updateBrand', res.brand);
              cb(true);
            } else {
              cb(false);
            }
          },
          error: function (e, exception) {
            cb(false);
          }
        });
      },

      saveCC(cb) {
        var ccemail = (0, _jquery.default)('#ccemail').val();
        (0, _jquery.default)('#ccemail').removeClass('err'); // if(!iva || iva.length != 11){
        //     jQuery("#address").addClass("err");
        //     cb(false);
        //     return;
        // }

        var brand = {
          brand: {
            cc_email: ccemail
          }
        };
        var that = this;

        _jquery.default.ajax({
          type: 'PATCH',
          url: _environment.default.APP.API_HOST + '/brands/' + this.brand.id,
          data: JSON.stringify(brand),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Basic ' + btoa(that.get('session').get('data.username') + ':' + that.get('session').get('data.password')));
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res && res.brand) {
              var b = _brand.default.create(res.brand);

              b.id = res.brand.id;
              that.set('brand', b);
              that.send('updateBrand', res.brand);
              cb(true);
            } else {
              cb(false);
            }
          },
          error: function (e, exception) {
            cb(false);
          }
        });
      },

      changePassword(cb) {
        var pass = (0, _jquery.default)('#change_password').val();
        var pass_c = (0, _jquery.default)('#change_password_confirm').val();

        if (!pass || !pass_c) {
          (0, _jquery.default)('#change_password').addClass('err');
          (0, _jquery.default)('#change_password_confirm').addClass('err');
          cb(false, 'Password is required!');
          return;
        }

        if (pass != pass_c) {
          (0, _jquery.default)('#change_password').removeClass('err');
          (0, _jquery.default)('#change_password_confirm').addClass('err');
          cb(false, 'Confirm password does not match!');
          return;
        }

        var user = {
          user: {
            password: pass
          }
        };
        var that = this;
        var cred = this.get('session.data.authenticated.token_type') + ' ' + this.get('session.data.authenticated.access_token');

        _jquery.default.ajax({
          type: 'PATCH',
          url: _environment.default.APP.API_HOST + '/users/' + this.user.id,
          data: JSON.stringify(user),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', cred);
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res && res.user) {
              var user = res.user;
              that.set('user', user);
              that.get('session').authenticate('authenticator:oauth2', user.username, pass).then(result => {
                that.get('session').set('data.username', user.username);
                that.get('session').set('data.password', pass);
                cb(true);
              }, err => {
                var user_token = that.get('session.data.authenticated.access_token');

                if (user_token) {
                  that.get('session').set('data.username', user.username);
                  that.get('session').set('data.password', pass);
                  cb(true);
                } else {
                  cb(false);
                }
              });
            } else {
              cb(false);
            }
          },
          error: function (e, exception) {
            cb(false);
          }
        });
      },

      updateBrand(obj) {
        this.get('session').set('data.brand_id', obj.id);
        this.get('session').set('data.brand_company', obj.company);
        this.get('session').set('data.brand_symbol', obj.brand_currency.symbol);
        this.get('session').set('data.brand_code', obj.brand_currency.code);
        this.get('session').set('data.brand_name', obj.brand_currency.name);
        this.get('session').set('data.funds', this.abbreviate_number(obj.funds));
        this.get('session').set('data.brand_funds', obj.funds);
      },

      onInvoiceNotificationChange(e) {
        this.set('invoiceNotification', e);
        var user = {
          user: {
            invoice: e
          }
        };
        var that = this;
        var lastToggle = !e;

        _jquery.default.ajax({
          type: 'PATCH',
          url: _environment.default.APP.API_HOST + '/users/' + this.user.id,
          data: JSON.stringify(user),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Basic ' + btoa(that.get('session').get('data.username') + ':' + that.get('session').get('data.password')));
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res && res.user) {
              if (e) {
                that.get('notify').alert({
                  html: '<div>Invoice notifications enabled!</div>'
                });
              } else {
                that.get('notify').alert({
                  html: '<div>Invoice notifications disabled!</div>'
                });
              }
            } else {
              that.get('notify').warning({
                html: '<div>Request Failed!</div>'
              });
              that.set('invoiceNotification', lastToggle);
            }
          },
          error: function (e, exception) {
            that.get('notify').warning({
              html: '<div>Request Failed!</div>'
            });
            that.set('invoiceNotification', lastToggle);
          }
        });
      },

      onAgencyAccountChange(e) {
        this.set('agencyAccount', e);
        var brand_id = this.get('brand.id');
        console.log('is_agency');
        this.store.findRecord('brand', brand_id).then(function (b) {
          b.set('is_agency', e);
          console.log('is_agency');
        });
        var brand = {
          brand: {
            is_agency: e
          }
        };
        var that = this;
        var lastToggle = !e;

        _jquery.default.ajax({
          type: 'PATCH',
          url: _environment.default.APP.API_HOST + '/brands/' + that.brand.id,
          data: JSON.stringify(brand),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Basic ' + btoa(that.get('session').get('data.username') + ':' + that.get('session').get('data.password')));
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            if (res && res.brand) {
              if (e) {
                that.get('notify').alert({
                  html: '<div>Agency account enabled!</div>'
                });
              } else {
                that.get('notify').alert({
                  html: '<div>Agency account disabled!</div>'
                });
              }
            } else {
              that.get('notify').warning({
                html: '<div>Request Failed!</div>'
              });
              that.set('agencyAccount', lastToggle);
            }
          },
          error: function (e, exception) {
            that.get('notify').warning({
              html: '<div>Request Failed!</div>'
            });
            that.set('agencyAccount', lastToggle);
          }
        });
      }

    },
    abbreviate_number: function (num) {
      if (num === null) {
        return 0;
      }

      num = parseFloat(num).toFixed(2);
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  });

  _exports.default = _default;
});