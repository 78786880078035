define("flytrendy/serializers/campaignpost", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      var tempData = {
        campaignpost: payload.campaignPost
      };
      payload = tempData;
      return this.normalizeArrayResponse(store, primaryModelClass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});