define("flytrendy/models/analytic", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _model.default.extend({
    potential_reach_approved: _emberData.default.attr(),
    number_posts_received: _emberData.default.attr(),
    potential_reach_pending: _emberData.default.attr(),
    number_posts_accepted: _emberData.default.attr(),
    number_comments: _emberData.default.attr(),
    cpe: _emberData.default.attr(),
    number_likes: _emberData.default.attr(),
    media_spent_including_tech_fee: _emberData.default.attr()
  });

  _exports.default = _default;
});