define("flytrendy/helpers/privacy", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (callback) {
    const privacyPath = `locale/platform-specific/${_environment.default.APP.platform_prefix}/privacy/privacy-policy.txt`;
    jQuery.get(privacyPath, tx => callback(tx));
  });

  _exports.default = _default;
});