define("flytrendy/models/campaign", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _model.default.extend({
    user: _emberData.default.attr(),
    data_id: _emberData.default.attr(),
    brand: _emberData.default.attr(),
    content_love: _emberData.default.attr(),
    call_action: _emberData.default.attr(),
    mood_board: _emberData.default.attr(),
    platform_insta: _emberData.default.attr(),
    platform_insta_story: _emberData.default.attr(),
    platform_twitter: _emberData.default.attr(),
    platform_fb: _emberData.default.attr(),
    pre_tags: _emberData.default.attr(),
    mood_board_urls: _emberData.default.attr(),
    pub_guides: _emberData.default.attr(),
    dont_dos: _emberData.default.attr(),
    hero_image: _emberData.default.attr(),
    hero_image_url: _emberData.default.attr(),
    campaign_logo: _emberData.default.attr(),
    campaign_logo_url: _emberData.default.attr(),
    brand_comp_name: _emberData.default.attr(),
    pro_comp_name: _emberData.default.attr(),
    pro_desc: _emberData.default.attr(),
    pro_category_primary: _emberData.default.attr(),
    pro_category_secondary: _emberData.default.attr(),
    campaign_link: _emberData.default.attr(),
    where_purchase: _emberData.default.attr(),
    campaign_wallet: _emberData.default.attr(),
    campaign_wallet_type_id: _emberData.default.attr(),
    campaign_spent: _emberData.default.attr(),
    ageMin: _emberData.default.attr(),
    ageMax: _emberData.default.attr(),
    gender: _emberData.default.attr(),
    info_demographic: _emberData.default.attr(),
    interests: _emberData.default.attr(),
    post_submissions: _emberData.default.attr(),
    posts_accepted: _emberData.default.attr(),
    response_time: _emberData.default.attr(),
    response_time_date: _emberData.default.attr(),
    publish_brief_app: _emberData.default.attr(),
    open_until_date: _emberData.default.attr(),
    created_datetime: _emberData.default.attr(),
    updated_date: _emberData.default.attr(),
    mood_images_count: _emberData.default.attr(),
    reviewed: _emberData.default.attr()
  });

  _exports.default = _default;
});