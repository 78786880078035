define("flytrendy/helpers/tnc", ["exports", "flytrendy/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (filter, callback) {
    const tncPath = `locale/platform-specific/${_environment.default.APP.platform_prefix}/tnc/${filter}-toc.txt`;

    _jquery.default.get(tncPath, terms => callback(terms));
  });

  _exports.default = _default;
});