define("flytrendy/controllers/pricing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      newCampaignTop() {
        dataLayer.push({
          'event': 'asyncAction',
          'gaHitType': 'event',
          'event_category': 'new campaign',
          'event_action': 'flytrendy automated box',
          'event_label': 'services_page'
        });
        this.transitionToRoute('newcampaign');
      },

      newCampaignBtm() {
        dataLayer.push({
          'event': 'asyncAction',
          'gaHitType': 'event',
          'event_category': 'new campaign',
          'event_action': 'click start for free button in footer',
          'event_label': 'services_page'
        });
        this.transitionToRoute('newcampaign');
      }

    }
  });

  _exports.default = _default;
});