define("flytrendy/routes/mycampaign", ["exports", "flytrendy/models/objects/campaign", "flytrendy/models/objects/newcampaign", "flytrendy/helpers/t", "flytrendy/config/environment"], function (_exports, _campaign, _newcampaign, _t, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: _t.default.compute('title_my_campaign'),
    session: Ember.inject.service('session'),
    platform: _environment.default.APP.platform_name,
    metaTags: [{
      tagName: _t.default.compute('seo_description'),
      tagContent: _t.default.compute('title_my_campaign')
    }, {
      tagName: _t.default.compute('seo_keywords'),
      tagContent: _t.default.compute('seo_content')
    }],

    beforeModel() {
      if (!this.get('session.isAuthenticated')) {
        this.transitionTo('splash');
      }
    },

    model(params) {// return Ember.RSVP.hash({
      // 		myCampaign: this.store.query('campaign', {}),
      // });
    },

    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      var that = this;
      controller.set('isActiveCampaignLoading', true);
      controller.set('isCompletedCampaignLoading', true);

      if (localStorage.getItem('campaignlist')) {
        var campaignlist = JSON.parse(localStorage.getItem('campaignlist'));
        var temp = [];

        for (var camp in campaignlist) {
          temp.push(JSON.parse(campaignlist[camp]));
        }

        if (temp.length > 0) {
          controller.set('draftCampaign', temp);
          controller.set('isDraft', true);
        } else {
          controller.set('draftCampaign', []);
          controller.set('isDraft', false);
        }
      } else {
        controller.set('draftCampaign', []);
        controller.set('isDraft', false);
      }

      this.store.query('campaign', {
        platform_names: this.platform
      }).then(function (d) {
        if (!d) {
          controller.set('completedCampaign', []);
          controller.set('data', []);
          controller.send('onLoading');
          return;
        }

        var len = d.content.length;
        var obj = null,
            campaignList = [],
            campObj = null,
            completedCampaign = [];

        for (var i = 0; i < len; i++) {
          obj = d.objectAt(i);
          campObj = _campaign.default.create(obj.data);
          campObj.set('data_id', obj.id);

          if (campObj.get('is_finished')) {
            completedCampaign.push(campObj);
          } else {
            campaignList.push(campObj);
          }
        }

        controller.set('completedCampaign', completedCampaign);
        controller.set('sf_data_inactive', completedCampaign);
        controller.set('data', campaignList);
        controller.set('sf_data_active', campaignList);
        controller.send('onLoading');
      }); // return;
      // var len = model.myCampaign.content.length;
      // var obj = null,
      //   campaignList = [],
      //   campObj = null,
      //   completedCampaign = [];
      // for (var i = 0; i < len; i++) {
      //   obj = model.myCampaign.objectAt(i);
      //   campObj = Campaign.create(obj.data);
      //   campObj.set('data_id', obj.id);
      //   campObj.setHeroImage(this);
      //   if (campObj.get('is_finished')) {
      //     completedCampaign.push(campObj);
      //   } else {
      //     campaignList.push(campObj);
      //   }
      // }
      // if (localStorage.getItem('campaignlist')) {
      //   var campaignlist = JSON.parse(localStorage.getItem('campaignlist'));
      //   var temp = [];
      //   for (var camp in campaignlist) {
      //     temp.push(JSON.parse(campaignlist[camp]));
      //   }
      //   if (temp.length > 0) {
      //     controller.set('draftCampaign', temp);
      //     controller.set('isDraft', true);
      //   } else {
      //     controller.set('draftCampaign', []);
      //     controller.set('isDraft', false);
      //   }
      // } else {
      //   controller.set('draftCampaign', []);
      //   controller.set('isDraft', false);
      // }
      // controller.set('completedCampaign', completedCampaign);
      // controller.set('data', campaignList);
      // controller.send('onLoading');
    }
  });

  _exports.default = _default;
});