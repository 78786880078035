define("flytrendy/templates/changepassword", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sB25zI6s",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"changepassword\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"background-cont\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"logo\"],[12],[10,\"img\"],[14,\"src\",\"assets/logo_abu_trans.png\"],[14,\"width\",\"300\"],[12],[13],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"login-cont\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"p\"],[14,0,\"title\"],[12],[10,\"span\"],[12],[2,\"Password Reset\"],[13],[13],[2,\"\\n\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-md-12 content-top login-form\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[11,\"form\"],[24,0,\"form-horizontal\"],[24,\"role\",\"form\"],[4,[38,0],[[32,0],\"authenticate\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[10,\"label\"],[14,\"for\",\"pass\"],[12],[2,\"Update your password\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,2],null,[[\"id\",\"placeholder\",\"class\",\"type\",\"maxlength\",\"value\"],[\"pass\",\"New Password\",\"form-control\",\"password\",\"100\",[35,1]]]]],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,2],null,[[\"id\",\"placeholder\",\"class\",\"type\",\"maxlength\",\"value\"],[\"passc\",\"Confirm Password\",\"form-control\",\"password\",\"100\",[35,3]]]]],[2,\"\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"form-group login-btn\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[10,\"input\"],[14,\"data-role\",\"button\"],[14,0,\"btn-submit\"],[14,2,\"UPDATE\"],[14,4,\"submit\"],[12],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"pass\",\"input\",\"passc\"]}",
    "meta": {
      "moduleName": "flytrendy/templates/changepassword.hbs"
    }
  });

  _exports.default = _default;
});