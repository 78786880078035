define("flytrendy/templates/activate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mmuX4aEo",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"resetpassword\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"background-cont\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"login-cont\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"p\"],[14,0,\"title\"],[12],[10,\"span\"],[12],[1,[30,[36,0],[\"aa_title\"],null]],[13],[13],[2,\"\\n\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-md-12 content-top login-form\"],[12],[2,\"\\n\\n\\t\\t\\t\\t\"],[1,[34,1]],[2,\"\\n\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"loading\"],[12],[2,\".\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"activateText\"]}",
    "meta": {
      "moduleName": "flytrendy/templates/activate.hbs"
    }
  });

  _exports.default = _default;
});