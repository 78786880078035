define("flytrendy/helpers/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function ([lhs, rhs]) {
    return lhs === rhs;
  });

  _exports.default = _default;
});