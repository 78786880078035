define("flytrendy/controllers/services/feedback", ["exports", "jquery", "flytrendy/config/environment", "flytrendy/models/objects/services/post"], function (_exports, _jquery, _environment, _post) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    notify: Ember.inject.service('notify'),
    applicationController: Ember.inject.controller('application'),
    postGalleryData: [{}],
    galleryOptions: {
      hideShare: true,
      hideZoomInOut: false,
      hideToggleFullScreen: false
    },
    collapsed: false,
    data: [],
    isSubmitting: false,
    clientData: null,
    id: null,
    email: '',
    status_type: '',
    lang: localStorage.getItem('lang'),
    actions: {
      onSubmit() {
        var data = this.get('data');
        var status_type = this.get('status_type');

        if (status_type == 'change') {
          if ((0, _jquery.default)('#change_feedback').val() == '') {
            alert('Feedback richiesto!');
            this.set('isSubmitting', false);
            return false;
          }
        } else if (status_type == 'decline') {
          if ((0, _jquery.default)('#decline_feedback').val() == '') {
            alert('Feedback richiesto!');
            this.set('isSubmitting', false);
            return false;
          }
        }

        var post_obj = {},
            post_data = [];

        for (var i = 0; i < data.length; i++) {
          post_data.push({
            id: data[i].id,
            feedback: data[i].status_feedback,
            status: data[i].status,
            price: data[i].price
          });
        }

        let feedback_data = {
          brandClients: {
            posts: post_data,
            client_reviewed: true
          }
        };
        const onePostWithoutFeedback = post_data.some(item => item.status === '');
        const lang = this.get('lang');
        const errorText = lang === 'it' ? '<div>Per mandare il tuo feedback, devi rispondere ad ogni post.</div>' : '<div>You need to provide feedback for every post to confirm.</div>';

        if (onePostWithoutFeedback) {
          this.get('notify').warning({
            html: errorText
          });
          return;
        }

        this.set('isSubmitting', true);
        var id = this.get('id'),
            email = this.get('email'),
            that = this;

        _jquery.default.ajax({
          type: 'PATCH',
          url: _environment.default.APP.API_HOST + '/brandclients/' + this.get('clientData.id'),
          data: JSON.stringify(feedback_data),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Basic c2VydmljZXNAZmx5dHJlbmR5LmNvbTpIaXRvcmkyMDkqKiE=');
            xhr.setRequestHeader('Content-Type', 'application/json');
          },
          success: function (res) {
            that.set('isSubmitting', false);
            that.set('clientData.client_reviewed', true);
          },
          error: function (jqXHR, exception) {
            alert('Cannot connect to the server, please try again!');
            that.set('isSubmitting', false);
          }
        });
      },

      onPostStatusClick(obj, status) {
        if (obj.get('status') != status) {
          obj.set('status_feedback', '');
          this.set('status_type', status);
        }

        if (obj.get('status') == 'approve') {
          obj.set('status_feedback', '');
        }

        obj.set('status', status);
      },

      onDeclineReasonChange(obj, e) {
        var option = (0, _jquery.default)(e.target).val();

        if (option.length < 5) {
          obj.set('status_feedback', '');
          return;
        }

        obj.set('status_feedback', option);
      },

      viewPost(post) {
        var that = this,
            postObj = null;
        var img = document.createElement('img');
        img.src = post.get('post_image');

        img.onload = function () {
          postObj = new Object();
          postObj.src = post.get('post_image');
          postObj.w = img.width;
          postObj.h = img.height;
          postObj.title = 'This is title';
          that.set('postGalleryData', [postObj]);
          var pswpElement = document.querySelectorAll('.pswp')[0];
          var gallery = new PhotoSwipe(pswpElement, false, [postObj], {
            bgOpacity: 0.8,
            history: false,
            focus: false,
            showAnimationDuration: 0,
            hideAnimationDuration: 0
          });
          gallery.init();
        };
      }

    }
  });

  _exports.default = _default;
});