define("flytrendy/routes/newcampaign", ["exports", "flytrendy/helpers/t", "jquery"], function (_exports, _t, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: _t.default.compute('title_new_campaign'),
    session: Ember.inject.service('session'),
    metaTags: [{
      tagName: _t.default.compute('seo_description'),
      tagContent: _t.default.compute('title_new_campaign')
    }, {
      tagName: _t.default.compute('seo_keywords'),
      tagContent: _t.default.compute('seo_content')
    }],
    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      controller.send('onLoading');
    },
    actions: {
      willTransition(transition) {
        var controller = this.controllerFor('newcampaign');

        if (!controller.get('autoRedirect')) {
          var confirm_msg = _t.default.compute("popup_leave_page_confirm");

          if (!confirm(confirm_msg)) {
            (0, _jquery.default)('#login-dialog .close-modal').click();
            controller.set('is_loggingin', false);
            transition.abort();
          }
        }
      }

    }
  });

  _exports.default = _default;
});