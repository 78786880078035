define("flytrendy/controllers/privacypolicy", ["exports", "flytrendy/config/environment", "flytrendy/helpers/privacy"], function (_exports, _environment, _privacy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    init: function () {
      this._super(...arguments);

      var that = this;
      setTimeout(function () {
        _privacy.default.compute(function (x) {
          jQuery('#privacycontent').html(x);
        });
      }, 500);
    },
    actions: {
      privacyUpdate() {
        _privacy.default.compute(function (x) {
          jQuery('#privacycontent').html(x);
        });
      }

    }
  });

  _exports.default = _default;
});