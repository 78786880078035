define("flytrendy/routes/sitemap-txt", ["exports", "flytrendy/utils/dynamic-segment-resolver", "flytrendy/utils/sitemap-entry-filter"], function (_exports, _dynamicSegmentResolver, _sitemapEntryFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // The line below is not needed if you don't have any dynamic segments
  var _default = Ember.Route.extend({
    sitemap: Ember.inject.service(),

    model() {
      const sitemap = this.get('sitemap'); // The line below is not needed if you don't have any dynamic segments

      sitemap.setDynamicSegmentResolver(_dynamicSegmentResolver.default);
      sitemap.setSitemapEntryFilter(_sitemapEntryFilter.default);
      return sitemap.getModel();
    }

  });

  _exports.default = _default;
});